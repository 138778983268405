import {Map, Record} from "immutable";
import {Reducer} from "redux";
import {
    SET_CODY_CONSOLE_HEIGHT,
    SET_RIGHT_SIDEBAR_WIDTH,
    SET_SIDEBAR_WIDTH,
    SET_SIDEBYSIDE_CELL,
    SET_SIDEBYSIDE_WIDTH,
    TOGGLE_SIDEBAR_COLLAPSED,
    TOGGLE_SIDEBAR_DIVIDER,
    TOGGLE_SIDEBAR_EDITOR
} from "../actions/constants";
import {InspectioLayoutAction} from "./index";


export interface SidebarStateProps {
    collapsed: boolean;
    width: number;
    rightSidebarWidth: number;
    sideBySideWidth: number;
    sideBySideCell: string | null;
    codyConsoleHeight: number;
    dividers: Map<string, boolean>;
    editors: Map<string, Record<{expanded: boolean, double: boolean}>>
}

export const defaultSidebarStateProps: SidebarStateProps = {
    collapsed: false,
    width: 250,
    rightSidebarWidth: 450,
    sideBySideWidth: 450,
    sideBySideCell: null,
    codyConsoleHeight: 200,
    dividers: Map(),
    editors: Map(),
};

export class SidebarState extends Record(defaultSidebarStateProps) implements SidebarStateProps {

}

export const initialState: SidebarState = new SidebarState();

const reducer: Reducer<SidebarState, InspectioLayoutAction> = (state: SidebarState = initialState, action: InspectioLayoutAction): SidebarState => {
    switch (action.type) {
        case TOGGLE_SIDEBAR_COLLAPSED:
            return state.set('collapsed', action.payload.collapsed);
        case SET_SIDEBAR_WIDTH:
            return state.set('width', action.payload.width);
        case SET_RIGHT_SIDEBAR_WIDTH:
            return state.set('rightSidebarWidth', action.payload.width);
        case SET_SIDEBYSIDE_WIDTH:
            return state.set('sideBySideWidth', action.payload.width);
        case SET_SIDEBYSIDE_CELL:
            return state.set('sideBySideCell', action.payload.cell);
        case SET_CODY_CONSOLE_HEIGHT:
            return state.set('codyConsoleHeight', action.payload.height);
        case TOGGLE_SIDEBAR_DIVIDER:
            return state.set(`dividers`, state.get('dividers').set(action.payload.divider, action.payload.collapsed));
        case TOGGLE_SIDEBAR_EDITOR:
            return state.set('editors', state.get('editors').set(action.payload.editor, (Record({expanded: action.payload.expanded, double: action.payload.double}))()));
        default:
            return state;
    }
};

export {reducer as sidebarReducer}
