import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";
import {Definitions} from "../rule-engine-definitions";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";

// Resolve filters are used in Resolver Config "where" rules
export const makeResolveFiltersDefinition = (possibleProperties?: string[], expressionSuggests?: string[]) => {
  return makeFiltersDefinition('resolve', possibleProperties, expressionSuggests);
}

// Find filters are used in "Find * Information" rules
export const makeFindFiltersDefinition = (possibleProperties?: string[], expressionSuggests?: string[]) => {
  return makeFiltersDefinition('find', possibleProperties, expressionSuggests);
}

export const withResolveFilterTypes = (definitions: Definitions): Definitions => {
  return {
    ...definitions,
    ...makeResolveFiltersDefinition()
  }
}

export const withFindFilterTypes = (definitions: Definitions): Definitions => {
  return {
    ...definitions,
    ...makeFindFiltersDefinition()
  }
}

type FilterPrefix = "resolve" | "find";

export const makeFiltersDefinition = (prefix: FilterPrefix, possibleProperties?: string[], expressionSuggests?: string[]) => {
  const propertySchema = possibleProperties
    ? {type: "string", enum: possibleProperties, markdownDescription: `Name of the property`,}
    : {type: "string", minLength: 1, markdownDescription: `Name of the property`,};

  const definitions: Record<string, JSONSchemaWithMarkdown> = {};

  definitions[`${prefix}_order_by`] = {
    markdownDescription: `Order the result set by property values.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#sort-order)`,
    oneOf: [
      {
        $ref: `#/definitions/${prefix}_order_by_item`
      },
      {
        type: "array",
        items: {
          $ref: `#/definitions/${prefix}_order_by_item`
        }
      }
    ]
  };

  definitions[`${prefix}_order_by_item`] = {
    type: "object",
    additionalProperties: false,
    required: ["prop", "sort"],
    properties: {
      prop: propertySchema,
      sort: {type: "string", enum: ["asc", "desc"]}
    }
  } as JSONSchemaWithMarkdown;

  definitions[`${prefix}_filter`] = {
    type: "object",
    additionalProperties: false,
    required: ["filter"],
   properties: {
      filter: {
        $ref: `#/definitions/${prefix}_filter_types`
      }
    }
  };

  definitions[`${prefix}_filter_types`] = {
    markdownDescription: `Filter the result set.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#filter)`,
    oneOf: [
      {$ref: `#/definitions/${prefix}_filter_and`},
      {$ref: `#/definitions/${prefix}_filter_or`},
      {$ref: `#/definitions/${prefix}_filter_not`},
      {$ref: `#/definitions/${prefix}_filter_any`},
      {$ref: `#/definitions/${prefix}_filter_any_of_doc_id`},
      {$ref: `#/definitions/${prefix}_filter_any_of`},
      {$ref: `#/definitions/${prefix}_filter_doc_id`},
      {$ref: `#/definitions/${prefix}_filter_eq`},
      {$ref: `#/definitions/${prefix}_filter_exists`},
      {$ref: `#/definitions/${prefix}_filter_gte`},
      {$ref: `#/definitions/${prefix}_filter_gt`},
      {$ref: `#/definitions/${prefix}_filter_in_array`},
      {$ref: `#/definitions/${prefix}_filter_like`},
      {$ref: `#/definitions/${prefix}_filter_lte`},
      {$ref: `#/definitions/${prefix}_filter_lt`},
    ]
  };



    definitions[`${prefix}_filter_expr`] = makeJexlExpression(`Provide filter value as a ${JEXL_WIKI_MARKDOWN}`, expressionSuggests),
    definitions[`${prefix}_filter_and`] = {
      type: "object",
      additionalProperties: false,
      required: ["and"],
      properties: {
        and: {
          markdownDescription: `**And Filter**\n\nFind information where two or more filters match. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#and-filter)`,
          type: "array",
          items: {$ref: `#/definitions/${prefix}_filter_types`},
          minItems: 2
        }
      }
    };

    definitions[`${prefix}_filter_or`] = {
      type: "object",
      additionalProperties: false,
      required: ["or"],
      properties: {
        or: {
          markdownDescription: `**Or Filter**\n\nFind information where any of the given filters match. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#or-filter)`,
          type: "array",
          items: {$ref: `#/definitions/${prefix}_filter_types`},
          minItems: 2
        }
      }
    };

    definitions[`${prefix}_filter_not`] = {
      type: "object",
      additionalProperties: false,
      required: ["not"],
      properties: {
        not: {
          markdownDescription: `**Not Filter**\n\nFind information where a given filter does not match. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#not-filter)`,
          $ref: `#/definitions/${prefix}_filter_types`
        }
      }
    };

    definitions[`${prefix}_filter_any`] = {
      type: "object",
      additionalProperties: false,
      required: ["any"],
      properties: {
        any: {
          markdownDescription: `**Any Filter**\n\nFind information without a specific filter. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#any-filter)`,
          type: "boolean",
          default: true
        }
      }
    };

    definitions[`${prefix}_filter_any_of_doc_id`] = {
      type: "object",
      additionalProperties: false,
      required: ["anyOfDocId"],
      properties: {
        anyOfDocId: {
          markdownDescription: `**Any Of DocId Filter**\n\nFilter information by a list of document ids. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#any-of-docid-filter)`,
          $ref: `#/definitions/${prefix}_filter_expr`
        }
      }
    };

    definitions[`${prefix}_filter_any_of`] = {
      type: "object",
      additionalProperties: false,
      required: ["anyOf"],
      properties: {
        anyOf: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Any Of Filter**\n\nFind information by comparing the value of a property against a list of possible values. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#any-of-filter)`,
          required: ["prop", "valueList"],
          properties: {
            prop: propertySchema,
            valueList: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_doc_id`] = {
      type: "object",
      additionalProperties: false,
      required: ["docId"],
      properties: {
        docId: {
          $ref: `#/definitions/${prefix}_filter_expr`,
          markdownDescription: `**DocId Filter**\n\nFind information by its document id. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#docid-filter)`
        }
      }
    };

    definitions[`${prefix}_filter_eq`] = {
      type: "object",
      additionalProperties: false,
      required: ["eq"],
      properties: {
        eq: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Equal Filter**\n\nCheck that a property equals a specific value. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#equal-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_exists`] = {
      type: "object",
      additionalProperties: false,
      required: ["exists"],
      properties: {
        exists: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Exists Filter**\n\nCheck that a specific property exists. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#exists-filter)`,
          required: ["prop"],
          properties: {
            prop: propertySchema,
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_gte`] = {
      type: "object",
      additionalProperties: false,
      required: ["gte"],
      properties: {
        gte: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Greater Than or Equal Filter**\n\nCheck that a property is greater than or equal to value. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#greater-than-or-equal-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_gt`] = {
      type: "object",
      additionalProperties: false,
      required: ["gt"],
      properties: {
        gt: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Greater Than Filter**\n\nCheck that a property is greater than value. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#greater-than-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_in_array`] = {
      type: "object",
      additionalProperties: false,
      required: ["inArray"],
      properties: {
        inArray: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**InArray Filter**\n\nIf property is of type array, check that given value is one of the items. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#inarray-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_like`] = {
      type: "object",
      additionalProperties: false,
      required: ["like"],
      properties: {
        like: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Like Filter**\n\nMatch a string property against a value string that contains a wildcard: % at the start and/or end. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#like-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_lte`] = {
      type: "object",
      additionalProperties: false,
      required: ["lte"],
      properties: {
        lte: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Lower Than or Equal Filter**\n\nCheck that a property is lower than or equal to a value. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#lower-than-or-equal-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    definitions[`${prefix}_filter_lt`] = {
      type: "object",
      additionalProperties: false,
      required: ["lt"],
      properties: {
        lt: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Lower Than Filter**\n\nCheck that a property is lower than a value. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#lower-than-filter)`,
          required: ["prop", "value"],
          properties: {
            prop: propertySchema,
            value: {$ref: `#/definitions/${prefix}_filter_expr`}
          }
        }
      }
    } as JSONSchemaWithMarkdown;

    return definitions;
}
