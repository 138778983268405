import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeCallService = (possibleServices: string[]): { rule_then_call_service: JSONSchemaWithMarkdown } => {
  return {
    rule_then_call_service: {
      type: "object",
      additionalProperties: false,
      required: ["call"],
      properties: {
        call: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Call Service**\n\nInvoke a custom function or method.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#call-service)`,
          required: ["service"],
          default: {
            "service": ""
          },
          properties: {
            service: {type: "string", enum: possibleServices, markdownDescription: `Name of the service dependency`,},
            arguments: {$ref: "#/definitions/rule_then_prop_mapping", markdownDescription: `Arguments passed to the service function.\n\n`, default: []},
            method: {type: "string", minLength: 1},
            async: {type: "boolean", default: true},
            result: {
              type: "object",
              additionalProperties: false,
              required: ["variable"],
              markdownDescription: `Save the result in a context variable`,
              default: {
                variable: ""
              },
              properties: {
                variable: {type: "string", minLength: 1, markdownDescription: `Name of the context variable`,},
                mapping: {$ref: "#/definitions/rule_then_prop_mapping"}
              }
            }
          }
        }
      }
    }
  }
}
