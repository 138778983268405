import {List} from 'immutable';
import {cloneDeep} from "lodash";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, ButtonGroup} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {ElementsTree} from "../../../model/ElementsTree";
import {Node, NodeType} from "../../../model/Graph";
import {ImmutableDDDWizardContext, isDDDAction} from "../../../service/cody-wizard/context/ddd-action";
import {ImmutableInformationContext} from "../../../service/cody-wizard/context/information-context";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {suggestDependenciesForNode} from "../../../service/cody-wizard/dependencies/suggest-dependencies-for-node";
import {getEventMetadata} from "../../../service/cody-wizard/event/get-event-metadata";
import {isAggregateEvent} from "../../../service/cody-wizard/event/is-aggregate-event";
import {getSourcesOfType, getTargetsOfType} from "../../../service/cody-wizard/node/get-single-node-of-type";
import {names} from "../../../service/cody-wizard/node/names";
import {Rule} from '../../../service/cody-wizard/rule-engine/configuration';
import {RulesDependencyMetadata} from '../../../service/cody-wizard/rule-engine/types';
import {getSchemaFromNodeDescription} from "../../../service/cody-wizard/schema/get-schema-from-node-description";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {isPropertyRef, splitPropertyRef} from "../../../service/cody-wizard/schema/shorthand";
import {getUiMetadata} from "../../../service/cody-wizard/ui/get-ui-metadata";
import {TableColumn, UiSchema} from "../../../service/cody-wizard/ui/ui-schema";
import {getConnectedEventIfPossible} from "../../../service/cody-wizard/vo/get-connected-event-if-possible";
import {
  getConnectedValueObjectSourceIfPossible
} from "../../../service/cody-wizard/vo/get-connected-value-object-source-if-possible";
import {getRefVo} from "../../../service/cody-wizard/vo/get-ref-vo";
import {getSchemaRefName} from "../../../service/cody-wizard/vo/get-schema-ref-name";
import {
  getVoMetadata,
  ProjectionConfig,
  ProjectionConfigCase,
  ResolveConfig,
  ValueObjectMetadata
} from "../../../service/cody-wizard/vo/get-vo-metadata";
import {isEntityCollection as entityCollectionCheck} from "../../../service/cody-wizard/vo/is-entity-collection";
import {isListVo} from "../../../service/cody-wizard/vo/is-list-vo";
import {isProjectionCandidate} from "../../../service/cody-wizard/vo/is-projection-candidate";
import {isVoStoredInDb} from '../../../service/cody-wizard/vo/is-vo-stored-in-db';
import DependenciesEditor from '../Editor/DependenciesEditor';
import EditorDivider from "../Editor/EditorDivider";
import InitializeRulesEditor from '../Editor/InitializeRulesEditor';
import ProjectionEditor from "../Editor/ProjectionEditor";
import QuerySchemaEditor from "../Editor/QuerySchemaEditor";
import ResolveEditor from "../Editor/ResolveEditor";
import SchemaEditor from "../Editor/SchemaEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import UiSchemaEditor from "../Editor/UiSchemaEditor";
import {WithWizardStepProps} from "../WizardModal";
import AdvancedButton from './AdvancedButton';
import CollectionInput from "./AnyVO/CollectionInput";
import EntityVoToggle from "./AnyVO/EntityVoToggle";
import StoredVoToggle from './AnyVO/StoredVoToggle';
import IdentifierSelect, {getIdentifierCandidatesFromSchema} from "./StateVO/IdentifierSelect";
import NamespaceInput from "./StateVO/NamespaceInput";
import {getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import ProjectionToggle from "./AnyVO/ProjectionToggle";
import QueryableToggle from "./AnyVO/QueryableToggle";
import SchemaEditorDivider from "../Editor/Divider/SchemaEditorDivider";
import UiSchemaEditorDivider from "../Editor/Divider/UiSchemaEditorDivider";
import DependenciesEditorDivider from "../Editor/Divider/DependenciesEditorDivider";
import ResolverEditorDivider from "../Editor/Divider/ResolverEditorDivider";
import ProjectionEditorDivider from "../Editor/Divider/ProjectionEditorDivider";
import {stringify} from "comment-json";

interface OwnProps {

}

type AnyVoProps = OwnProps & WithWizardStepProps<ImmutableInformationContext> & WithNamespaces;

const AnyVo = (props: AnyVoProps) => {
  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [metadata, setMetadata] = useState<ValueObjectMetadata>({});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);
  const [voSchema, setVoSchema] = useState(new Schema({}));
  const [querySchema, setQuerySchema] = useState(new Schema({}));
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [dependencies, setDependencies] = useState<Record<string, RulesDependencyMetadata>>({});
  const [showDependencies, setShowDependencies] = useState(false);
  const [projection, setProjection] = useState<ProjectionConfig>({cases: []});
  const [isEntityCollection, setIsEntityCollection] = useState(false);
  const [isQueryable, setIsQueryable] = useState(false);
  const [isProjection, setIsProjection] = useState(false);

  const updateMetadata = (meta: ValueObjectMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(stringify(meta, null, 2), true);
    }

    if(meta.schema && stringify(meta.schema) !== stringify(voSchema)) {
      setVoSchema(meta.schema);
      checkIsEntityCollection(meta.schema);
    }

    if(meta.querySchema && stringify(meta.querySchema) !== stringify(querySchema)) {
      setQuerySchema(meta.querySchema);
    }

    if(meta.projection && stringify(meta.projection) !== stringify(projection)) {
      setProjection(meta.projection);
    }

    setMetadata(meta);
  }

  const updateDependencies = (deps: Record<string, RulesDependencyMetadata>) => {
    setDependencies(deps);
  }

  const checkIsEntityCollection = (schema: Schema) => {
    if(!isEntityCollection && entityCollectionCheck(schema, elementsTree)) {
      setIsEntityCollection(true);
    }
  }

  const version = props.ctx.vo ? props.ctx.vo.getVersion() : 0;

  useEffect(() => {
    resetForm();

    if(props.ctx.vo) {
      const stateMeta = getVoMetadata(props.ctx.vo);
      let shouldUpdateMetadata = false;

      if(stateMeta.schema && !stateMeta.schema.isEmpty()) {
        setVoSchema(stateMeta.schema);
        checkIsEntityCollection(stateMeta.schema);
      } else {
        stateMeta.schema = suggestVoSchema(props.ctx.vo);
        shouldUpdateMetadata = true;

        // New VO, so check if queryable and projection
        const events = getSourcesOfType(props.ctx.vo, NodeType.event);

        if(events.length > 0) {
          setIsQueryable(true);

          if(!stateMeta.querySchema) {
            stateMeta.querySchema = suggestQuery(props.ctx.vo, stateMeta) || new Schema({});
          }

          if(!stateMeta.resolve) {
            stateMeta.resolve = getDefaultResolveConfigFromQuery(stateMeta.querySchema || new Schema({}));
          }
        }
      }

      if(Object.keys(props.ctx.queryDependencies).length) {
        setShowDependencies(true);

        if(!stateMeta.queryDependencies || stateMeta.queryDependencies !== props.ctx.queryDependencies) {
          stateMeta.queryDependencies = props.ctx.queryDependencies;
          shouldUpdateMetadata = true;
        }
      }

      setDependencies(props.ctx.queryDependencies);

      if(!stateMeta.uiSchema)  {
        const suggestedUiSchema = suggestUiSchema(stateMeta.schema || new Schema({}), stateMeta.uiSchema, elementsTree, props.ctx);

        if(suggestedUiSchema) {
          shouldUpdateMetadata = true;
          stateMeta.uiSchema = suggestedUiSchema;
        }
      }

      if(stateMeta.querySchema) {
        setQuerySchema(stateMeta.querySchema);
        setIsQueryable(true);
      }

      if(!stateMeta.ns || stateMeta.ns === "/") {
        stateMeta.ns = suggestVoNamespace(props.ctx.vo);
        shouldUpdateMetadata = true;
      }

      if(typeof stateMeta.collection === "undefined" || stateMeta.collection === "_collection") {
        stateMeta.collection = suggestCollection(props.ctx.vo, stateMeta, elementsTree);
        shouldUpdateMetadata = true;
      }

      if(stateMeta.projection) {
        setProjection(stateMeta.projection);
        setIsProjection(true);
      }

      if(shouldUpdateMetadata) {
        const sourceVo = getConnectedValueObjectSourceIfPossible(props.ctx.vo);

        if(sourceVo) {
          if(!stateMeta.querySchema) {
            stateMeta.querySchema = new Schema({});
          }

          if(!stateMeta.resolve) {
            stateMeta.resolve = getDefaultResolveConfigFromQuery(stateMeta.querySchema);
          }
        }

        updateMetadata(stateMeta);
        return;
      }

      setMetadata(stateMeta);
    }

  }, [props.ctx.vo, version])

  const resetForm = () => {
    setMetadata({});
    setAutoFocusSchema(false);
    setVoSchema(new Schema({}));
    setQuerySchema(new Schema({}));
    setDependencies({});
    setShowDependencies(false);
    setProjection({cases: []});
    setIsQueryable(false);
    setIsProjection(false);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleNsChanged = (newNs: string) => {
    updateMetadata({...metadata, ns: newNs});
  }

  const handleIdentifierChanged = (newIdentifier: string) => {
    const newMetadata = {...metadata};

    if (newIdentifier === "") {
      delete newMetadata.identifier;
    } else {
      newMetadata.identifier = newIdentifier;
    }
    updateMetadata(newMetadata);
  }

  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      newSchema = new Schema({});
    }

    let entity = metadata.entity;

    if(newSchema.isList()) {
      entity = false;
    }

    updateMetadata({...metadata, schema: newSchema, entity});
  }

  const handleIsQueryableChanged = (newIsQueryable: boolean) => {
    if(!newIsQueryable) {
      handleQuerySchemaRemoved();
    } else {
      handleQuerySchemaChanged(suggestQuery(props.ctx.vo, metadata) || new Schema({}));
    }

    setIsQueryable(newIsQueryable);
  }

  const handleQuerySchemaChanged = (newQuerySchema: Schema) => {
    const resolve = metadata.resolve || getDefaultResolveConfigFromQuery(newQuerySchema);
    updateMetadata({...metadata, querySchema: newQuerySchema, resolve});
  }

  const handleQuerySchemaRemoved = () => {
    updateMetadata({...metadata, querySchema: undefined, resolve: undefined});
  }

  const handleDependenciesConfigChanged = (changedDependencies: Record<string, RulesDependencyMetadata> | undefined) => {
    if(!changedDependencies) {
      changedDependencies = {};
    }

    updateMetadata({...metadata, queryDependencies: changedDependencies});
    window.setTimeout(() => {
      updateDependencies(changedDependencies!);
    },100);
  }

  const handleSuggestDependencies = () => {
    if(!props.ctx.vo) {
      return;
    }

    const newDeps = suggestDependenciesForNode(props.ctx.vo, dependencies, false);

    if(stringify(newDeps) !== stringify(dependencies)) {
      handleDependenciesConfigChanged(newDeps);
    }
  }

  const handleResolveChanged = (resolve?: ResolveConfig) => {
    updateMetadata({...metadata, resolve, querySchema});
  }

  const handleUiSchemaChanged = (newUiSchema: UiSchema | undefined) => {
    if(!newUiSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.uiSchema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, uiSchema: newUiSchema});
  }

  const handleIsProjectionChanged = (newIsProjection: boolean) => {
    handleProjectionChanged(newIsProjection ? suggestProjection(props.ctx.vo, metadata, props.ctx, elementsTree) : undefined);
    setIsProjection(newIsProjection);
  }

  const handleProjectionChanged = (newProjection: ProjectionConfig | undefined) => {
    if(!newProjection) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.projection;
      updateMetadata(clonedMeta);
      return;
    }
    updateMetadata({...metadata, projection: newProjection});
  }

  const handleStoredInDbChanged = (isStoredInDb: boolean) => {
    const newMeta = {...metadata};

    if(isStoredInDb) {
      newMeta.collection = suggestCollection(props.ctx.vo, metadata, elementsTree);
      updateMetadata(newMeta);
    } else {
      newMeta.collection = false;
      if(newMeta.projection) {
        delete newMeta.projection;
      }
      updateMetadata(newMeta);
    }
  }

  const handleIsEntityChanged = (isEntity: boolean) => {
    updateMetadata({...metadata, entity: isEntity});
  }

  const handleCollectionChanged = (newCollection: string) => {
    updateMetadata({...metadata, collection: newCollection})
  }

  const handleInitializeRulesChanged = (rules: Rule[] | undefined) => {
    if(!rules) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.initialize;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, initialize: rules});
  }

  const handleSuggestSchema = () => {
    const newSchema = suggestVoSchema(props.ctx.vo);

    if(!newSchema.isEmpty()) {
      handleSchemaChanged(newSchema);
    }
  }

  const handleSuggestQuerySchema = () => {
    const newQuerySchema = suggestQuery(props.ctx.vo, metadata);

    if(newQuerySchema) {
      handleQuerySchemaChanged(newQuerySchema);
    }
  }

  const handleSuggestResolve = () => {
    const existingQuerySchema = metadata.querySchema || new Schema({});
    const newResolve = getDefaultResolveConfigFromQuery(existingQuerySchema);

    updateMetadata({...metadata, resolve: newResolve, querySchema: existingQuerySchema});
  }

  const handleSuggestUiSchema = () => {
    const newUiSchema = suggestUiSchema(
      metadata.schema || new Schema({}),
      metadata.uiSchema,
      elementsTree,
      props.ctx,
      false
    )

    if(newUiSchema) {
      handleUiSchemaChanged(newUiSchema);
    }
  }

  const handleSuggestProjection = () => {
    const newProjection = suggestProjection(props.ctx.vo, metadata, props.ctx, elementsTree);

    if(stringify(metadata.projection) !== stringify(newProjection)) {
      handleProjectionChanged(newProjection);
    }
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    <EditorDivider label="Namespace" content={<>
      <NamespaceInput ns={metadata.ns || ''} onNsChanged={handleNsChanged} disabled={!props.ctx.vo.isEnabled()}/>
    </>}/>

    <EditorDivider label={metadata.schema && metadata.schema.isList() ? "Item Identifier" : "Identifier"} content={<>
      <IdentifierSelect identifier={metadata.identifier || ''}
                        schema={metadata.schema || new Schema({})}
                        disabled={!props.ctx.vo || !props.ctx.vo.isEnabled()}
                        onIdentifierChanged={handleIdentifierChanged}
                        identifierCandidates={getIdentifierCandidatesFromSchema(metadata.schema || new Schema({}), elementsTree)}
      />
    </>} />

    <SchemaEditorDivider content={<>
      <SchemaEditor boardId={props.ctx.board.uid}
                    sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                    schema={voSchema}
                    readonly={!props.ctx.vo.isEnabled()}
                    onSchemaChanged={handleSchemaChanged} autofocus={autoFocusSchema}
      />
      <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestSchema}/>
    </>} />

    <UiSchemaEditorDivider type={voSchema.isList() ? 'table' : 'state'} content={<>
      <UiSchemaEditor boardId={props.ctx.board.uid}
                      sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                      nodeSchema={voSchema}
                      schema={metadata.uiSchema || {}}
                      readonly={!props.ctx.vo.isEnabled()}
                      onSchemaChanged={handleUiSchemaChanged}
                      tableSchema={isListVo(props.ctx.vo)}
      />
      <ButtonGroup>
        <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestUiSchema}/>
        <Button basic={true} compact={true} icon="search" className="noborder"
                content={props.t('insp.cody_wizard.ui_schema_editor.choose_icon')} as="a"
                href="https://pictogrammers.com/library/mdi/" target="_blank"/>
      </ButtonGroup>
    </>}/>

    <EditorDivider label="Query Schema" content={<>
      <QueryableToggle isQueryable={isQueryable} onQueryableChanged={handleIsQueryableChanged} vo={props.ctx.vo} ctx={props.ctx} disabled={!props.ctx.vo.isEnabled()} />
      <div className={(isQueryable ? 'slidedown' : 'slideup') + ' smooth'}>
        <QuerySchemaEditor boardId={props.ctx.board.uid}
                           sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                           schema={querySchema}
                           readonly={!props.ctx.vo.isEnabled()}
                           onSchemaChanged={handleQuerySchemaChanged}
                           onSchemaRemoved={handleQuerySchemaRemoved}
        />
        <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestQuerySchema}/>
        <div className={'field'}>
          {<Button basic={true} icon={showDependencies ? 'minus' : 'add'} labelPosition="left" size="mini"
                   content={props.t('insp.cody_wizard.step_policy.manage_dependencies') + ` (${Object.keys(dependencies).length})`}
                   color="blue" className="text"
                   onClick={() => setShowDependencies(!showDependencies)}
                   disabled={!props.ctx.vo}/>}
        </div>
        <div className={(showDependencies ? 'slidedown' : 'slideup') + ' smooth'}>
          <DependenciesEditorDivider messageType="query" label={props.t('insp.cody_wizard.step_policy.dependencies_label') as string} content={<>
            <DependenciesEditor boardId={props.ctx.board.uid}
                                sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                                dependencies={dependencies}
                                messageSchema={querySchema}
                                autofocus={false}
                                readonly={!!(props.ctx.vo && !props.ctx.vo.isEnabled())}
                                onDependenciesChanged={handleDependenciesConfigChanged}
            />
            <CodySuggestButton
              hasSuggestions={!!(props.ctx.vo && props.ctx.vo.getSources().filter(s => [NodeType.document, NodeType.externalSystem].includes(s.getType())).count() > 0)}
              onSuggest={handleSuggestDependencies}
            />
          </>}/>
        </div>
        <ResolverEditorDivider label="Query Resolver" content={<>
          <ResolveEditor boardId={props.ctx.board.uid}
                         sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                         nodeSchema={voSchema}
                         querySchema={querySchema}
                         resolve={metadata.resolve}
                         readonly={!props.ctx.vo.isEnabled()}
                         onResolveChanged={handleResolveChanged}
          />
          <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestResolve}/>
        </>}/>
      </div>
    </>
    }/>
    <p>&nbsp;</p>
    <ProjectionEditorDivider label="Projection" content={<>
      <ProjectionToggle isProjection={isProjection} onProjectionChanged={handleIsProjectionChanged}
                        vo={props.ctx.vo} ctx={props.ctx} disabled={!props.ctx.vo.isEnabled()}/>
      <div className={(isProjection ? 'slidedown' : 'slideup') + ' smooth'}>
        <ProjectionEditor
          projection={projection}
          ctx={props.ctx}
          onProjectionChanged={handleProjectionChanged}
          possibleEvents={props.ctx.vo ? getPossibleEvents(props.ctx.vo, props.ctx, elementsTree) : List()}
          possibleServices={elementsTree.getFilteredElementsByType(NodeType.externalSystem)}
          sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
          readonly={!props.ctx.vo.isEnabled()}
        />
        <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestProjection}/>
      </div>
    </>}/>

    <AdvancedButton showAdvancedSettings={showAdvancedSettings} onShowChanged={show => setShowAdvancedSettings(show)}
                    disabled={!props.ctx.vo}/>
    <div className={(showAdvancedSettings ? 'slidedown' : 'slideup') + ' smooth'}>
      <EditorDivider label={'Classification'} content={<>
        {voSchema.isObject() &&
          <EntityVoToggle isEntity={!!metadata.entity} vo={props.ctx.vo} onIsEntityChanged={handleIsEntityChanged}
                          disabled={!props.ctx.vo.isEnabled()}/>}
        <StoredVoToggle isStored={isVoStoredInDb(metadata)} ctx={props.ctx} vo={props.ctx.vo}
                        onStoredStateChanged={handleStoredInDbChanged} disabled={!props.ctx.vo.isEnabled()}/>
        {props.ctx.vo && isVoStoredInDb(metadata) && <EditorDivider label={'Collection'} content={<>
          <CollectionInput collection={metadata.collection || '_collection'}
                           onCollectionChanged={handleCollectionChanged}
                           disabled={!props.ctx.vo.isEnabled()}/>
        </>} />}
      </>}/>

      <EditorDivider label="Initialize Rules" content={<>
        <InitializeRulesEditor elementId={props.ctx.vo ? props.ctx.vo.getId() : ''}
                               sessionId={props.ctx.vo ? props.ctx.vo.getId() : undefined}
                               initializeRules={metadata.initialize || []}
                               dataSchema={voSchema}
                               readonly={!!(props.ctx.vo && !props.ctx.vo.isEnabled())}
                               ctx={props.ctx}
                               onRulesChanged={handleInitializeRulesChanged}
        />
      </>}/>

    </div>
    <div style={{marginTop: '100px'}}/>
  </form>
};

export default withNamespaces()(AnyVo);

export const suggestCollection = (vo: Node, voMeta: ValueObjectMetadata, elementsTree: ElementsTree): string => {
  if(voMeta.schema && voMeta.schema.getListItemsSchema(new Schema({})).isRef()) {
    const refVo = getRefVo(voMeta.schema.getListItemsSchema(new Schema({})), elementsTree);
    if(refVo) {
      const refVoMeta = getVoMetadata(refVo);
      if(refVoMeta.collection) {
        return refVoMeta.collection;
      }
    }
  }

  const sources = [...getSourcesOfType(vo, NodeType.document), ...getTargetsOfType(vo, NodeType.document)];

  for (const source of sources) {
    const sourceMeta = getVoMetadata(source);

    if(sourceMeta.collection) {
      return sourceMeta.collection;
    }
  }

  return names(vo.getTechnicalName()).constantName.toLowerCase() + '_collection';
}

const suggestQuery = (vo: Node, voMeta: ValueObjectMetadata): Schema | undefined => {
  if(voMeta.querySchema && !voMeta.querySchema.isEmpty()) {
    return voMeta.querySchema;
  }

  if(voMeta.schema && (voMeta.schema.isObject() || voMeta.schema.isRef()) && voMeta.identifier) {
    const qSchema = new Schema({});
    qSchema.setObjectProperty(voMeta.identifier, voMeta.schema.getObjectPropertySchema(voMeta.identifier, new Schema('string|format:uuid')));
    return qSchema;
  }
}

const suggestVoSchema = (vo: Node): Schema => {
  const sourceVo = getConnectedValueObjectSourceIfPossible(vo);

  if(sourceVo) {
    const sourceMeta = getVoMetadata(sourceVo);

    if(sourceMeta.schema && sourceMeta.schema.isList()) {
      const itemSchema = sourceMeta.schema.getListItemsSchema(new Schema({}))
      return itemSchema.isRef() ? new Schema({"$ref": itemSchema.getRef('')}) : itemSchema;
    }

    const sourceRefName = getSchemaRefName(sourceVo, sourceMeta);

    return new Schema({$items: sourceRefName});
  }

  const event = getConnectedEventIfPossible(vo);

  if(event) {
    const evtMeta = getEventMetadata(event);

    if(evtMeta.schema) {


      return isAggregateEvent(event)? evtMeta.schema : new Schema({'$items': evtMeta.schema.toShorthand()});
    }
  }

  const descSchema = getSchemaFromNodeDescription(vo);

  if(descSchema) {
    return descSchema;
  }

  return new Schema({});
}

export const suggestUiSchema = (schema: Schema, uiSchema: UiSchema | undefined, elementsTree: ElementsTree, ctx: ImmutableDDDWizardContext | ImmutableInformationContext, forCommand = false): UiSchema | undefined => {
  const suggestions: Record<string, any> = {};
  const uiForm = (uiSchema? uiSchema['ui:form'] || {} : {}) as UiSchema;
  let uiFormData = uiForm.data || {} as Record<string, any>;

  if(!uiSchema && isDDDAction(ctx) && !forCommand && ctx.command) {
    const commandMeta = getCommandMetadata(ctx.command);

    if(commandMeta.uiSchema) {
      uiSchema = commandMeta.uiSchema;
      delete uiSchema['ui:form'];
    }
  }

  if(!uiSchema && isDDDAction(ctx) && forCommand && ctx.state) {
    const stateMeta = getVoMetadata(ctx.state);

    if(stateMeta.uiSchema) {
      uiSchema = stateMeta.uiSchema;
    }
  }

  if(isDDDAction(ctx) && forCommand) {
    ctx.uiViews.forEach(uiView => {
      const viewMeta = getVoMetadata(uiView);

      if(viewMeta.schema && viewMeta.schema.equals(schema)) {
        const viewDataRef = getSchemaRefName(uiView, viewMeta);
        uiFormData = `page|data('${viewDataRef}')`
      }
    })
  }

  if(schema.isObject()) {
    schema.getObjectProperties().forEach(prop => {
      const propSchema = schema.getObjectPropertySchema(prop, new Schema({}));

      if(propSchema.isString('uuid')) {
        suggestions[prop] = {
          "ui:widget": "hidden"
        }

        if(forCommand && isDDDAction(ctx) && ctx.ui) {
          const uiMeta = getUiMetadata(ctx.ui);

          if(!uiMeta.route || !uiMeta.route.split("/").includes(`:${prop}`)) {
            if(typeof uiFormData === "object") {
              uiFormData[prop] = "uuid()";
            }
          }
        }
      } else if(propSchema.isRef() && isPropertyRef(propSchema.getRef(''))) {
        const [ref, refProp] = splitPropertyRef(propSchema.getRef(''));

        const refVoSchema = propSchema.resolveRef(elementsTree);
        const refPropSchema = refVoSchema.getObjectPropertySchema(refProp, new Schema({}));
        let firstStringProp = refProp;

        refVoSchema.getObjectProperties().forEach(oProp => {
          const oPropSchema = refVoSchema.getObjectPropertySchema(oProp, new Schema({}));

          if(oPropSchema.isString() && !oPropSchema.isString('uuid')) {
            firstStringProp = oProp;
          }
        })

        if(refPropSchema.isString('uuid')) {
          let firstVoList: Node | undefined;
          let firstVoListMeta: ValueObjectMetadata | undefined;
          elementsTree.getFilteredElementsByType(NodeType.document).forEach(doc => {
            if(isListVo(doc)) {
              const listVoMeta = getVoMetadata(doc);
              const listVoSchema = listVoMeta.schema || new Schema({});

              if(listVoSchema.getListItemsSchema(new Schema({})).getRef() === ref) {
                firstVoList = doc;
                firstVoListMeta = listVoMeta;
              }
            }
          })

          if(firstVoList) {
            suggestions[prop] = {
              "ui:widget": "DataSelect",
              "ui:options": {
                "data": getSchemaRefName(firstVoList, firstVoListMeta!),
                "value": `data.${refProp}`,
                "text": `data.${firstStringProp}`
              }
            }
          }
        }
      }
    })

    if(forCommand && Object.keys(uiForm) && Object.keys(uiFormData).length > 0) {
      uiForm.data = uiFormData;
      suggestions['ui:form'] = uiForm;
    }

    if(!Object.keys(suggestions).length) {
      return uiSchema;
    }

    return {...suggestions, ...uiSchema};
  }

  if(schema.isList() && !schema.getListItemsSchema(new Schema({})).isEmpty()) {
    const listItemSchema = schema.getListItemsSchema(new Schema({})).isRef()
      ? schema.getListItemsSchema(new Schema({})).resolveRef(elementsTree)
      : schema.getListItemsSchema(new Schema({}));

    const columns: TableColumn[] = [];

    if(!listItemSchema.isObject()) {
      return uiSchema;
    }

    listItemSchema.getObjectProperties().forEach(prop => {
      const propSchema = listItemSchema.getObjectPropertySchema(prop, new Schema({}));

      if(propSchema.isPrimitive() && !propSchema.isString('uuid')) {
        columns.push(prop);
      }

      if(propSchema.isRef()) {
        const ref = propSchema.getRef('');
        const [refWithoutProp, refProp] = splitPropertyRef(ref);

        if(refProp) {
          const refVoSchema = propSchema.resolveRef(elementsTree);
          const refPropSchema = refVoSchema.getObjectPropertySchema(refProp, new Schema({}));
          let firstStringProp = refProp;


          refVoSchema.getObjectProperties().forEach(oProp => {
            const oPropSchema = refVoSchema.getObjectPropertySchema(oProp, new Schema({}));

            if(oPropSchema.isString() && !oPropSchema.isString('uuid')) {
              firstStringProp = oProp;
            }
          })

          if(refPropSchema.isString('uuid')) {
            let firstVoList: Node | undefined;
            let firstVoListMeta: ValueObjectMetadata | undefined;
            elementsTree.getFilteredElementsByType(NodeType.document).forEach(doc => {
              if (isListVo(doc)) {
                const listVoMeta = getVoMetadata(doc);
                const listVoSchema = listVoMeta.schema || new Schema({});

                if (listVoSchema.getListItemsSchema(new Schema({})).getRef() === refWithoutProp) {
                  firstVoList = doc;
                  firstVoListMeta = listVoMeta;
                }
              }
            })

            if (firstVoList) {
              columns.push({
                field: prop,
                ref: {
                  data: getSchemaRefName(firstVoList, firstVoListMeta!),
                  value: `data.${firstStringProp}`
                }
              })
            }
          }
        }
      }
    })

    if(!columns.length) {
      return uiSchema;
    }

    if(!uiSchema || !uiSchema['ui:table']) {
      return {'ui:table': {columns}, ...uiSchema};
    }

    if(uiSchema && uiSchema['ui:table']) {
      const uiColumns = (uiSchema['ui:table'] as {columns: TableColumn[]}).columns;

      columns.forEach(col => {
        const field = typeof col === 'string'? col : col.field;
        let fieldExists = false;

        uiColumns.forEach(uiCol => {
          const uiField = typeof uiCol === 'string' ? uiCol : uiCol.field;

          if(uiField === field) {
            fieldExists = true;
          }
        })

        if(!fieldExists) {
          uiColumns.push(col);
        }
      });

      (uiSchema['ui:table'] as {columns: TableColumn[]}).columns = uiColumns;
      return uiSchema;
    }
  }

  return uiSchema;
}

const suggestVoNamespace = (vo: Node): string => {
  const sourceVo = getConnectedValueObjectSourceIfPossible(vo);

  if(!sourceVo) {
    return ispConst.DEFAULT_DOC_NS;
  }

  const sourceMeta = getVoMetadata(sourceVo);

  return sourceMeta.ns || ispConst.DEFAULT_DOC_NS;
}

const getDefaultResolveConfigFromQuery = (query: Schema): ResolveConfig => {
  if(!query.isObject() || query.isEmpty()) {
    return {
      where: {
        rule: "always",
        then: {filter: {any: true}}
      }
    }
  }

  const firstProp = query.getObjectProperties()[0];

  if(query.isRequired(firstProp)) {
    return {
      where: {
        rule: "always",
        then: {filter: {eq: {prop: firstProp, value: `query.${firstProp}`}}}
      }
    }
  } else {
    return {
      where: {
        rule: "condition",
        if: `query.${firstProp}`,
        then: {filter: {eq: {prop: firstProp, value: `query.${firstProp}`}}},
        else: {filter: {any: true}}
      }
    }
  }
}

const suggestProjection = (vo: Node,voMeta: ValueObjectMetadata, ctx: WizardContext, elementsTree: ElementsTree): ProjectionConfig => {
  const schema = voMeta.schema || new Schema({});
  const identifier = getIdentifier(voMeta);
  const projection: ProjectionConfig = voMeta.projection || {
    name: names(vo.getTechnicalName()).className + 'Projection',
    live: true,
    cases: []
  };

  const cases: ProjectionConfigCase[] = cloneDeep(projection.cases);
  const caseEventList: string[] = cases.map(c => c.when)
    .map(eName => {const nameParts = eName.split("."); return nameParts[nameParts.length - 1]})
    .map(eName => names(eName).className);

  const events = getPossibleEvents(vo, ctx, elementsTree);

  events.forEach(event => {
    const eventName = names(event.getTechnicalName()).className;
    if(!caseEventList.includes(eventName)) {
      const idExpr = identifier ? `$> event.${identifier}` : '$> uuid()';
      cases.push({
        when: event.getTechnicalName(),
        then: {
          upsert: {
            id: idExpr,
            set: "$> event"
          }
        }
      })
    }
  })

  return {
    ...projection,
    cases: [...cases]
  }
}

const getIdentifier = (voMeta: ValueObjectMetadata): string | undefined => {
  if(voMeta.identifier) {
    return voMeta.identifier;
  }
}

const getPossibleEvents = (vo: Node, ctx: WizardContext, elementsTree: ElementsTree): List<Node> => {
  let similarNodes = elementsTree.getSimilarElements(vo)

  similarNodes = similarNodes.push(vo);

  const eventMap: Record<string, Node> = {};

  similarNodes.forEach(s => {
    const events = getSourcesOfType(s, NodeType.event);

    events.forEach(e => eventMap[e.getTechnicalName()] = e);
  })

  return List(Object.values(eventMap));
}
