import axios, {AxiosInstance} from "axios";
import {List} from "immutable";
import {UserId} from "../../User/model/UserInfo";
import {BoardId, BoardName} from "../model/Board";
import {convertNodeToJs, Node, NodeId} from "../model/Graph";
import {AxiosServer} from "./Cody/AxiosServer";
import {CodyServer} from "./Cody/CodyServer";
import {Command} from "./Cody/Command";
import {Event} from "./Cody/Event";
import {PlayServer} from "./Cody/PlayServer";

export const NO_CODY_CONNECTION = "No connection to Cody. Try to connect with him first!";
export const CODY_PLAY_SERVER_NAME = "Cody Play";

interface Session {
    nodes: Node[];
    currentNodeIndex: number;
    currentServerIndex: number;
    boardId: BoardId;
    boardName: BoardName;
    userId: UserId;
    syncPerformed: boolean;
}

const printCodyResponse = (res: CodyResponse, codyName: string) => {
    const cody = (typeof res.cody === 'string')? [res.cody] : res.cody as [];
    const details = (typeof res.details === 'string')? [res.details] : res.details as [];
    const type = res.type? res.type : CodyResponseType.Info;

    switch (type) {
        case CodyResponseType.Info:
            if(cody.length === 1) {
                cody[0] = '%c'+cody[0];
                cody.push('color: #32c0e7;font-weight: bold')
            }
            console.info('%c🐵 ['+codyName+']: '+cody[0], 'color: #414141', ...cody.slice(1));
            if(res.details) {
                console.log('%c🐵 ['+codyName+']: '+details[0], 'color: #414141', ...details.slice(1));
            }
            break;
        case CodyResponseType.Error:
            if(cody.length === 1) {
                cody[0] = '%c'+cody[0];
                cody.push('color: color: #f31c30;font-weight: bold')
            }
            console.error('%c🐵 ['+codyName+']: '+cody[0], 'color: #414141', ...cody.slice(1));
            if(res.details) {
                console.error('%c🐵 ['+codyName+']: '+details[0], 'color: #414141', ...details.slice(1));
            }
            break;
        case CodyResponseType.Warning:
        case CodyResponseType.Question:
            if(cody.length === 1) {
                cody[0] = '%c'+cody[0];
                cody.push('font-weight: bold')
            }
            console.warn('%c🐵 ['+codyName+']: '+cody[0], 'color: #414141', ...cody.slice(1));
            if(res.details) {
                console.warn('%c🐵 ['+codyName+']: '+details[0], 'color: #414141', ...details.slice(1));
            }
            break;
        case CodyResponseType.SyncRequired:
            console.warn("%c🐵 ["+codyName+"]: I need to sync all board content. Lean back for a moment. I'll tell you when I'm done.");
            break;
        default:
            console.log('%c🐵 ['+codyName+']: '+cody[0], 'color: #414141', ...cody.slice(1));
            if(res.details) {
                console.log('%c🐵 ['+codyName+']: '+details[0], 'color: #414141', ...details.slice(1));
            }
    }
}

const printError = (error: string) => {
    console.error(error);
}

const printWarning = (warn: string) => {
    console.warn(warn);
}

const sortNodes = (nodes: Node[]): Node[] => {
    const sortedNodes: Node[] = [];

    const nodesWithoutSourceInNodeList = nodes.filter(n => {
        if(n.getSources().count() === 0) {
            return true
        }

        let allSourcesNotInNodeList = true;

        n.getSources().forEach(source => {
            if(nodes.find(orgN => orgN.getId() === source.getId())) {
                allSourcesNotInNodeList = false;
            }
        })

        return allSourcesNotInNodeList;
    });

    sortedNodes.push(...nodesWithoutSourceInNodeList);

    nodesWithoutSourceInNodeList.forEach(nodeNoSource => {
        addTargetsToSortedNodesList(nodeNoSource.getTargets(), nodes, sortedNodes);
    })

    return sortedNodes;
}

const addTargetsToSortedNodesList = (targets: List<Node>, originalNodes: Node[], sortedNodes: Node[], nestingLevel = 0): void => {
    targets.forEach(target => {
        if(originalNodes.find(orgN => orgN.getId() === target.getId())) {
            const targetInSortedNodes = sortedNodes.find(sN => sN.getId() === target.getId());

            if(!targetInSortedNodes) {
                sortedNodes.push(target);
            } else {
                // Target should always come after all of its sources
                sortedNodes.splice(sortedNodes.indexOf(targetInSortedNodes), 1);
                sortedNodes.push(target);
            }

        }

        nestingLevel++;

        if(nestingLevel < 5) {
            addTargetsToSortedNodesList(target.getTargets(), originalNodes, sortedNodes, nestingLevel);
        }
    })
}

let currentSession: Session | undefined;
let codyResponsePrinter = printCodyResponse;
let errorPrinter = printError;
let warningPrinter = printWarning;
let syncRequiredCallback: SyncRequiredCallback | undefined;
let newlyConnectedCodySyncIndex: number | undefined;
let codyResponseListeners: CodyResponseListener[] = [];

const triggerCody: TriggerCody = (boardId: BoardId, boardName: BoardName, userId: UserId, nodes, currentNodeIndex?: number, currentServerIndex?: number) => {
    if(nodes.length === 0) {
        warningPrinter("Selection is empty. Nothing to do for Cody!");
        return false;
    }

    if(typeof currentNodeIndex === 'undefined' && currentSession) {
        if(IIO.codyServers) {
            IIO.codyServers.forEach(cody => {
                codyResponsePrinter({
                    cody: "Wait, wait, wait! I'm still working on last session.",
                    details: ["You can stop me by pressing %cESC", "background-color: rgba(251, 159, 75, 0.2)"],
                    type: CodyResponseType.Warning
                }, cody.name);
            })
        } else {
            codyResponsePrinter({
                cody: "Wait, wait, wait! I'm still working on last session.",
                details: ["You can stop me by pressing %cESC", "background-color: rgba(251, 159, 75, 0.2)"],
                type: CodyResponseType.Warning
            }, 'Cody');
        }

        return false;
    }

    if(IIO.codyServers) {
        if(typeof currentNodeIndex === 'undefined') {
            currentSession = {nodes, currentNodeIndex: 0, boardId, boardName, userId, currentServerIndex: 0, syncPerformed: false};

            nodes = sortNodes(nodes);

            triggerCody(boardId, boardName, userId, nodes, 0);
        } else {
            if(typeof currentServerIndex === 'undefined') {
                currentServerIndex = 0;
            }

            const cody = IIO.codyServers![currentServerIndex];

            const context = {boardId, boardName, userId};

            cody.sendMessage(Event.ElementEdited, {node: convertNodeToJs(nodes[currentNodeIndex], true), context}).then(
              codyRes => {
                    codyResponsePrinter(codyRes, cody.name);

                    if(codyRes.type !== CodyResponseType.Question
                        && codyRes.type !== CodyResponseType.StopSession
                        && codyRes.type !== CodyResponseType.SyncRequired
                        && codyRes.type !== CodyResponseType.Error) {
                        currentServerIndex!++;
                        if(currentServerIndex! < IIO.codyServers!.length) {
                            currentSession!.currentServerIndex = currentServerIndex!;
                            triggerCody(boardId, boardName, userId, nodes, currentNodeIndex, currentServerIndex);
                        } else {
                            currentNodeIndex = currentNodeIndex! + 1;
                            currentSession!.currentNodeIndex = currentNodeIndex;
                            currentSession!.currentServerIndex = 0;

                            if(currentNodeIndex < nodes.length) {
                                triggerCody(boardId, boardName, userId, nodes, currentNodeIndex, 0);
                            }
                        }
                    }

                    if(codyRes.type === CodyResponseType.SyncRequired) {
                        if(syncRequiredCallback) {
                            syncRequiredCallback();
                        }
                    }

                    if(codyRes.type === CodyResponseType.StopSession
                        || codyRes.type === CodyResponseType.Error
                        || (currentNodeIndex! === nodes.length && currentServerIndex === IIO.codyServers!.length && codyRes.type !== CodyResponseType.Question)) {
                        currentSession = undefined;
                    }
                },
                reason => {
                    if(typeof reason !== 'string') {
                        if(typeof reason.toString === 'function') {
                            reason = reason.toString();
                        } else {
                            reason = 'Unknown Error'
                        }
                    }

                    errorPrinter(reason)
                }
            );
        }
        return true;
    }

    errorPrinter(NO_CODY_CONNECTION);
    return false;
}

const runCommand = (boardId: BoardId, boardName: BoardName, userId: UserId, commandName: string, payload: any, codyName?: string): boolean => {
    if(!IIO.codyServers) {
        return false;
    }

    const servers = IIO.codyServers.filter(s => !codyName || s.name === codyName);
    const context = {boardId, boardName, userId};

    servers.forEach(s => s.sendMessage(commandName, {payload, context}).then(codyResponse => {
        codyResponsePrinter(codyResponse, s.name)
    }));

    return true;
}

export const IIO: IIO = {
    syncRequired: () => {
        if(!IIO.codyServers) {
            return false;
        }

        return IIO.codyServers.filter(server => server.isSyncEnabled()).length > 0;
    },
    isConnected: (codyName?: string): boolean => {
        return !!IIO.codyServers && IIO.codyServers.filter(s => !codyName || s.name === codyName).length > 0;
    },
    connect: {
        Cody: (uri: string, user: string, boardId, codyName?: string) => {
            if(!codyName) {
                codyName = 'Cody';
            }

            if(!IIO.codyServers) {
                IIO.codyServers = [];
            }

            const connectedServers = IIO.codyServers.filter(cody => cody.name !== codyName);

            const codyServer = uri === 'play'
              ? new PlayServer(CODY_PLAY_SERVER_NAME, process.env.REACT_APP_CODY_PLAY_URL || 'https://play.prooph-board.com', boardId)
              : new AxiosServer(codyName, axios.create({
                baseURL:  uri + '/messages/',
            }));

            connectedServers.push(codyServer);

            IIO.codyServers = connectedServers;

            codyServer.sendMessage(Event.IioSaidHello, {user}).then(
                res => {
                    codyResponsePrinter(res, codyServer.name)

                    if(res.type === CodyResponseType.SyncRequired) {

                        if(syncRequiredCallback) {
                            newlyConnectedCodySyncIndex = connectedServers.indexOf(codyServer);
                            syncRequiredCallback();
                        }
                    }
                },
                reason => codyResponsePrinter({
                    cody: 'Failed to reach out to server',
                    details: [reason],
                    type: CodyResponseType.Error
                }, codyServer.name)
            );
            return true;
        }
    },
    disconnect: (codyName?: string) => {
        if(IIO.codyServers) {
            if(codyName) {
                IIO.codyServers = IIO.codyServers.filter(cody => cody.name !== codyName);

                codyResponsePrinter({
                    cody: 'Bye Bye',
                    type: CodyResponseType.Warning
                }, codyName)
                return;
            }
            IIO.codyServers = undefined;
            currentSession = undefined;
            codyResponsePrinter = printCodyResponse;
            errorPrinter = printError;
            warningPrinter = printWarning;
        }
    },
    reply: (reply: any) => {
        if(currentSession && IIO.codyServers && IIO.codyServers[currentSession.currentServerIndex]) {
            const cody = IIO.codyServers[currentSession.currentServerIndex];

            cody.sendMessage(Event.UserReplied, {reply}).then(
              codyRes => {
                    codyResponsePrinter(codyRes, cody.name);

                    if (codyRes.type !== CodyResponseType.Question &&
                        codyRes.type !== CodyResponseType.StopSession &&
                        codyRes.type !== CodyResponseType.Error && currentSession) {

                        const {nodes} = currentSession;
                        let {currentNodeIndex} = currentSession;

                        const currentServerIndex = currentSession.currentServerIndex + 1;
                        if(currentServerIndex! < IIO.codyServers!.length) {
                            currentSession!.currentServerIndex = currentServerIndex!;
                            triggerCody(
                              currentSession.boardId,
                              currentSession.boardName,
                              currentSession.userId,
                              nodes,
                              currentNodeIndex,
                              currentServerIndex
                            );
                        } else {
                            currentNodeIndex = currentNodeIndex! + 1;
                            currentSession!.currentNodeIndex = currentNodeIndex;
                            currentSession!.currentServerIndex = 0;

                            if(currentNodeIndex < nodes.length) {
                                triggerCody(
                                  currentSession.boardId,
                                  currentSession.boardName,
                                  currentSession.userId,
                                  nodes,
                                  currentNodeIndex,
                                  0
                                );
                            } else {
                                // Set back so that check in next if can pass!
                                currentSession!.currentServerIndex = currentServerIndex;
                            }
                        }

                    }

                    if (codyRes.type === CodyResponseType.StopSession
                        || codyRes.type === CodyResponseType.Error
                        || (currentSession
                            && (currentSession.currentNodeIndex === currentSession.nodes.length || currentSession.nodes.length === 0)
                            && currentSession.currentServerIndex === IIO.codyServers!.length)
                    ) {
                        currentSession = undefined;
                    }
                },
                reason => {
                    if (typeof reason !== 'string') {
                        if (typeof reason.toString === 'function') {
                            reason = reason.toString();
                        } else {
                            reason = 'Unknown Error'
                        }
                    }

                    errorPrinter(reason)
                }
            );

            return true;
        }

        errorPrinter(NO_CODY_CONNECTION);
    },
    talk: {
        to: {
            Cody: () => {
                if(IIO.codyServers) {
                    // Only talk to last server
                    const lastServer = IIO.codyServers[IIO.codyServers.length - 1];
                    currentSession = {nodes: [], currentNodeIndex: 0, boardId: '', boardName: '', userId: '', currentServerIndex: IIO.codyServers.length - 1, syncPerformed: false};

                    lastServer.sendMessage(Event.ConfirmTest, {}).then(
                        res => {
                            codyResponsePrinter(res, lastServer.name)
                        },
                        reason => {
                            if(typeof reason !== 'string') {
                                if(typeof reason.toString === 'function') {
                                    reason = reason.toString();
                                } else {
                                    reason = 'Unknown Error'
                                }
                            }

                            errorPrinter(reason)
                        }
                    );

                    return true;
                }

                errorPrinter(NO_CODY_CONNECTION);
            }
        }
    },
    trigger: {
        Cody: triggerCody,
    },
    runCommand,
    onSyncRequired: (cb) => {
        syncRequiredCallback = cb;
    },
    syncNodes: async (boardId: BoardId, nodes: Node[]) => {
        if(IIO.codyServers && (currentSession || typeof newlyConnectedCodySyncIndex !== 'undefined')) {
            const cody = currentSession? IIO.codyServers[currentSession.currentServerIndex] : IIO.codyServers[newlyConnectedCodySyncIndex!];

            if(currentSession && currentSession.syncPerformed) {
                codyResponsePrinter({
                    cody: "Something went wrong. I already synced the entire board, but need to sync again.",
                    details: "Looks like an endless loop. The developer who created the hooks gave me wrong instructions. Please contact them!",
                    type: CodyResponseType.Error
                }, cody.name);

                return false;
            }

            const codyRes = await cody.fullSync(Command.Sync, {boardId, nodes: nodes.map(n => convertNodeToJs(n, true))});

            if(codyRes.type !== CodyResponseType.Empty) {
                codyResponsePrinter(codyRes, cody.name);
            }

            cody.enableSync();
        }

        return true;
    },
    syncChangedNodes: async (boardId: BoardId, nodes: Node[]) => {
        if(IIO.codyServers) {
            for(const cody of IIO.codyServers) {
                if(!cody.isSyncEnabled()) {
                    continue;
                }

                const performSync = async (currentChunk: Node[]) => {
                    const codyRes = await cody.syncNodes(Command.Sync, {boardId, nodes: currentChunk.map(n => convertNodeToJs(n, true))});

                    if(codyRes.type !== CodyResponseType.Empty) {
                        codyResponsePrinter(codyRes, cody.name);
                    }
                }

                let chunk: Node[] = [];

                for(const node of nodes) {
                    chunk.push(node);

                    if(chunk.length >= 10) {
                        await performSync(chunk);

                        chunk = [];
                    }
                }

                if(chunk.length > 0) {
                    await performSync(chunk);
                }
            }
        }

        return true;
    },
    syncDeletedNodes: async (boardId: BoardId, nodes: Node[]) => {
        if(IIO.codyServers) {
            for(const cody of IIO.codyServers) {
                if(!cody.isSyncEnabled()) {
                    continue;
                }

                const performSync = async (currentChunk: Node[]) => {
                    const codyRes = await cody.syncDeletedNodes(Command.SyncDeleted, {boardId, nodes: currentChunk.map(n => convertNodeToJs(n, true))});

                    if(codyRes.type !== CodyResponseType.Empty) {
                        codyResponsePrinter(codyRes, cody.name);
                    }
                }

                let chunk: Node[] = [];

                for(const node of nodes) {
                    chunk.push(node);

                    if(chunk.length >= 10) {
                        await performSync(chunk);

                        chunk = [];
                    }
                }

                if(chunk.length > 0) {
                    await performSync(chunk);
                }
            }
        }

        return true;
    },
    syncFinished: () => {
        if(typeof newlyConnectedCodySyncIndex !== 'undefined' && IIO.codyServers) {
            const cody = IIO.codyServers[newlyConnectedCodySyncIndex];

            codyResponsePrinter({
                cody: "Sync finished. We can continue!",
                type: CodyResponseType.Info
            }, cody.name);

            newlyConnectedCodySyncIndex = undefined;
            return;
        }

         if(currentSession && IIO.codyServers) {
             const cody = IIO.codyServers[currentSession.currentServerIndex];

             codyResponsePrinter({
                 cody: "Sync finished. We can continue!",
                 type: CodyResponseType.Info
             }, cody.name);

             currentSession.syncPerformed = true;

             triggerCody(
               currentSession.boardId,
               currentSession.boardName,
               currentSession.userId,
               currentSession.nodes,
               currentSession.currentNodeIndex,
               currentSession.currentServerIndex
             );
         }
    },
    abortSession: () => {
        currentSession = undefined;

        if(IIO.codyServers) {
            IIO.codyServers.forEach(cody => {
                codyResponsePrinter({
                    cody: "Yeah, no problem. I stopped my work."
                }, cody.name);
            })
        } else {
            codyResponsePrinter({
                cody: "Yeah, no problem. I stopped my work."
            }, 'Cody');
        }

        return true;
    },
    ask: {
        Cody: {
            forHelp: () => {
                codyResponsePrinter({
                    cody: "It's easy. You design the solution and I'll code it for you!",
                    details: [
                        "%cJust select one or more elements on the board and press %cCtrl+G (Cmd+G on Mac)%c or choose %cTrigger Cody%c from context menu of an element.\n\nPressing %cCtrl+Q (Cmd+Q on Mac)%c closes the console.\n\nTo enable Cockpit integration use %c/cockpit https://localhost:<cockpit_port>",
                        'color: #414141',
                        'background-color: rgba(251, 159, 75, 0.2)',
                        'color: #414141',
                        'background-color: rgba(251, 159, 75, 0.2)',
                        'color: #414141',
                        'background-color: rgba(251, 159, 75, 0.2)',
                        'color: #414141',
                        'background-color: rgba(251, 159, 75, 0.2)',
                    ]
                }, 'Cody')
            }
        }
    },
    ping: {
        Cody: () => {
            codyResponsePrinter({
                cody: "I'm ready. Let's develop some awesome software together!",
                type: CodyResponseType.Info
            }, 'Cody');
        }
    },
    codyServers: undefined,
    setCodyResponsePrinter: (printer: (res: CodyResponse, codyName: string) => void) => {
        codyResponsePrinter = (codyRes: CodyResponse, cName: string): void => {
            printer(codyRes, cName);
            codyResponseListeners.forEach(l => l(codyRes));
        };
    },
    setErrorPrinter: (printer: (error: string) => void) => {
        errorPrinter = printer;
    },
    setWarningPrinter: (printer: (warn: string) => void) => {
        warningPrinter = printer;
    },
    attachCodyResponseListener: (listener: CodyResponseListener): DisposeCodyResponseListener => {
        const dispose: DisposeCodyResponseListener = () => {
            codyResponseListeners = codyResponseListeners.filter(l => l !== listener);
        }

        codyResponseListeners.push(listener);

        return dispose;
    }
}

// @ts-ignore
window.IIO = IIO;

export const useIIO = (): IIO => {
    return IIO;
}

// tslint:disable-next-line:interface-name
export interface IIO {
    syncRequired: () => boolean;
    isConnected: (codyName?: string) => boolean;
    connect: Connect;
    disconnect: (codyName?: string) => void;
    reply: Reply;
    talk: {
        to: {
            Cody: () => void;
        }
    },
    trigger: {
        Cody: TriggerCody;
    },
    runCommand: (boardId: BoardId, boardName: BoardName, userId: UserId, commandName: string, payload: any, codyName?: string) => boolean;
    // Callback should use "syncNodes()" to pass all nodes to Cody
    onSyncRequired: (cb: undefined | SyncRequiredCallback) => void;
    // If user is on a large board, syncNodes() can be called multiple times in a row with a chunk of nodes
    syncNodes: SyncNodes;
    // If all nodes are synced, Callback should use syncFinished() to tell Cody to continue with current session
    syncFinished: () => void;
    // With a SyncRequired cody response the server automatically registers for a permanent sync of changes
    // Following two methods are used sync ongoing changes like adding, updating or deleting nodes on the board
    syncChangedNodes: SyncChangedNodes;
    syncDeletedNodes: SyncDeletedNodes;
    abortSession: () => void;
    ask: {
        Cody: {
            forHelp: () => void;
        }
    },
    ping: {
        Cody: () => void;
    }
    codyServers: CodyServer[] | undefined;
    setCodyResponsePrinter: (printer: (res: CodyResponse, codyName: string) => void) => void;
    setErrorPrinter: (printer: (error: string) => void) => void;
    setWarningPrinter: (printer: (warn: string) => void) => void;
    attachCodyResponseListener: (listner: CodyResponseListener) => DisposeCodyResponseListener;
}

interface Connect {
    Cody: (uri: string, user: string, boardId: BoardId, codyName?: string) => void;
}

type SyncRequiredCallback = () => void;

type Reply = (reply: any) => void;

export type TriggerCody = (
  boardId: BoardId,
  boardName: BoardName,
  userId: UserId,
  nodes: Node[],
  currentNodeIndex?: number,
  currentServerIndex?: number
) => boolean;

export type CodyResponseListener = (codyResponse: CodyResponse) => void;

export type DisposeCodyResponseListener = () => void;

export type SyncNodes = (boardId: BoardId, nodes: Node[]) => Promise<boolean>;

export type SyncChangedNodes = (boardId: BoardId, nodes: Node[]) => Promise<boolean>;

export type SyncDeletedNodes = (boardId: BoardId, nodes: Node[]) => Promise<boolean>;

export enum CodyResponseType {
    Info= 'Info',
    Error = 'Error',
    Warning = 'Warning',
    Empty = 'Empty',
    Question = 'Question',
    StopSession = 'StopSession',
    SyncRequired = 'SyncRequired',
}

export interface CodyResponse {
    cody: string | string[];
    details?: string | string[];
    type?: CodyResponseType;
}

