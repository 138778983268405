import {List} from "immutable";
import * as React from 'react';
import {ReactElement, useMemo, useRef, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Transition} from "semantic-ui-react";
import {setCodyConsoleHeight} from "../../Layout/actions/commands";
import {makeGetSidebarSelector} from "../../Layout/selectors/sidebar";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {toggleCodyConsole} from "../actions/commands";
import {useGraph} from "../hooks/useGraph";
import {BoardId} from "../model/Board";
import {makeBoardByIdSelector} from "../selectors/board";
import {CodyResponse, CodyResponseType, IIO, NO_CODY_CONNECTION} from "../service/Cody";
import CodyMessage from "./CodyConsole/CodyMessage";
import InspectIoMessage from "./CodyConsole/InspectIoMessage";
import UserInput from "./CodyConsole/UserInput";
import UserMessage from "./CodyConsole/UserMessage";

interface OwnProps {

}

interface CodyConsoleRouteMatchParams {
    uid: BoardId;
}

const RESERVED_NAMES = [
    'connect',
    'disconnect',
    'cockpit',
    'help',
    'talk'
]

type CodyConsoleProps = OwnProps & RouteComponentProps<CodyConsoleRouteMatchParams>;

const CodyConsole = (props: CodyConsoleProps) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState<List<ReactElement>>(List([]));
    const [lastCodyMsg, setLastCodyMsg] = useState<CodyResponse|undefined>();
    const sidebar = useSelector(makeGetSidebarSelector());
    const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
    const board = useSelector(getBoardByIdSelector);
    const visible = board && board.shouldShowCodyConsole;
    const [isDragging, setIsDragging] = useState(false);
    const [collabsableBottom, setCollabsableBottom] = useState(sidebar.codyConsoleHeight);
    const user = useSelector(makeGetCurrentUser());
    const consoleEl = useRef<HTMLDivElement>(null);
    const [graph] = useGraph();

    const sidebarWidth = sidebar.width + 10 /* collapse handle */;
    const bothSidebarsWidth = sidebarWidth + ((board && board.shouldShowElementDetails)
      ? sidebar.rightSidebarWidth + 5 + (sidebar.sideBySideCell ? sidebar.sideBySideWidth + 5 : 0)
      : 0);

    const addMessage = (msg: ReactElement) => {
        setMessages(messages.push(msg));
        window.setTimeout(() => {
            if(consoleEl.current) {
                consoleEl.current.scrollBy({top: 200, behavior: "smooth"});
            }
        }, 10);
    }

    IIO.setCodyResponsePrinter((res: CodyResponse, codyName: string) => {
        setLastCodyMsg(res);
        addMessage(<CodyMessage key={messages.count() + 1} codyResponse={res} codyName={codyName} />);
    });

    IIO.setErrorPrinter((error: string) => {
        if(messages.count() === 0 && error === NO_CODY_CONNECTION) {
            return;
        }

        addMessage(<InspectIoMessage key={messages.count() + 1} message={error} type="error" />);
    });

    IIO.setWarningPrinter((warn: string) => {
        addMessage(<InspectIoMessage key={messages.count() + 1} message={warn} type="warning" />);
    });

    const handleCloseClick = () => {
        dispatch(toggleCodyConsole(props.match.params.uid, false));
    }

    const handleDragging = (e: DraggableEvent, data: DraggableData) => {
        if(!isDragging) {
            setIsDragging(true);
        }

        const newHeight = sidebar.codyConsoleHeight - data.deltaY;

        dispatch(setCodyConsoleHeight(newHeight));
    }

    const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
        setIsDragging(false);
        setCollabsableBottom(sidebar.codyConsoleHeight);
    }

    const handleUserInput = (val: string) => {
        addMessage(<UserMessage key={messages.count() + 1} msg={val} />);

        if(val === '/help') {
            window.setTimeout(() => {
                IIO.ask.Cody.forHelp();
            })
            return;
        }

        if(val === '/talk') {
            IIO.talk.to.Cody();
            return;
        }

        const foundConnect = val.match(/^\/connect\s+(?<uri>http(s)?:\/\/[^\s]+|play)\s*(?<name>[A-Za-z0-9]+)?$/);

        if(foundConnect) {
            if(foundConnect.groups!.name) {
                if(RESERVED_NAMES.includes(foundConnect.groups!.name)) {
                    addMessage(<CodyMessage codyResponse={{
                        cody: `Oh sorry, you cannot use "${foundConnect.groups!.name}" as a name. It's a reserved word!`,
                        type: CodyResponseType.Error
                    }} codyName="cody" />)
                    return;
                }
            }

            IIO.connect.Cody(foundConnect.groups!.uri, user.displayName, board.uid, foundConnect.groups!.name);
            return;
        }

        const foundDisconnect = val.match(/^\/disconnect\s+(?<name>[A-Za-z0-9]+)$/);

        if(foundDisconnect && foundDisconnect.groups!.name) {
            IIO.disconnect(foundDisconnect.groups!.name);
            return;
        }

        if(val.match(/^\/cockpit\s+http(s)?:\/\/.+$/)) {
            graph.setCockpitBaseUrl(val.replace('/cockpit ', '').trim());
            addMessage(<CodyMessage codyName="Cody" codyResponse={{
                cody: 'Cockpit is activated now.',
                details: 'Right click on commands, events, queries or aggregates and choose "Open In Cockpit" from context menu.'}}
            />);
            return;
        }

        if(IIO.codyServers) {
            IIO.reply(val);
        } else {
            IIO.connect.Cody(val.replace('/connect ', ''), user.displayName, board.uid);
        }
    }

    const handleUserEscape = () => {
        IIO.abortSession();
    }

    return <Transition.Group animation="slide up">{visible && <div className="cody console" ref={consoleEl} style={{
        height: `${sidebar.codyConsoleHeight}px`,
        left: `${sidebarWidth}px`,
        width: `calc(100% - ${bothSidebarsWidth}px)`,
    }}>
        {!IIO.codyServers && <InspectIoMessage message="No connection to Cody. Try to connect with him first!" type="warning"/>}
        {messages.toArray()}
        <UserInput showConnectDefault={!IIO.codyServers}
                   boardId={props.match.params.uid}
                   messageCount={messages.count()}
                   lastCodyMsg={lastCodyMsg}
                   visible={visible}
                   onEnter={handleUserInput}
                   onEscape={handleUserEscape}
                   onClose={handleCloseClick} />
        <Draggable
            axis="y"
            onDrag={handleDragging}
            onStop={handleDragStop}
            position={{x: 0, y: 0}}
        >
            <div className="collapsible" style={{bottom: collabsableBottom + 'px', width: `calc(100% - ${bothSidebarsWidth}px)`,  cursor: 'grabbing'}}>
                <span className="collaps-handle" onClick={handleCloseClick}><i className="close icon" /></span>
            </div>
        </Draggable>
    </div>}</Transition.Group>;
};

export default withRouter(CodyConsole);
