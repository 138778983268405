import {Node, NodeType} from "../../../model/Graph";
import {getCommandMetadata} from "../command/get-command-metadata";
import {getTargetsOfType} from "../node/get-single-node-of-type";
import {isStateVo} from "../vo/is-state-vo";

export const isDddActionEvent = (node: Node): boolean => {
  if(node.getType() !== NodeType.event) {
    return false;
  }

  if(isAggregateEvent(node)) {
    return true;
  }

  const sources = node.getSources();

  if(sources.count() > 0) {
    const firstSource = sources.filter(s => s.getType() === NodeType.command).first(undefined);

    if(firstSource && firstSource.getType() === NodeType.command) {
      return true;
    }
  }

  return false;
}

export const isAggregateEvent = (node: Node): boolean => {
  if(node.getType() !== NodeType.event) {
    return false;
  }

  const sources = node.getSources();

  if(sources.count() === 1) {
    const type = sources.toArray()[0].getType();

    if(type === NodeType.aggregate) {
      return true
    }

    if(type === NodeType.command) {
      const cmdMeta = getCommandMetadata(sources.first());

      return cmdMeta.aggregateCommand;
    }
  }

  return false;
}

export const isEntityEvent = (node: Node): boolean => {
  if(node.getType() !== NodeType.event) {
    return false;
  }

  const vos = getTargetsOfType(node, NodeType.document);

  for (const vo of vos) {
    if(isStateVo(vo)) {
      return true;
    }
  }

  return false;
}

export const isAggregateEventCandidate = (node: Node): boolean => {
  if(isAggregateEvent(node)) {
    return true;
  }

  if(node.getType() !== NodeType.event) {
    return false;
  }

  return node.getSources().count() === 0;
}
