import {List} from "immutable";
import * as React from 'react';
import {SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Card, Icon, Message} from 'semantic-ui-react';
import {setSideBySideCell, setSideBySideWidth} from "../../Layout/actions/commands";
import {SidebarState} from "../../Layout/reducers/applySidebarActions";
import {makeGetSidebarSelector} from "../../Layout/selectors/sidebar";
import {useGraph} from "../hooks/useGraph";
import {BoardId} from "../model/Board";
import {Node, NodeType} from "../model/Graph";
import {ActiveGraphElement, makeNodeFromActiveGraphElement} from "../reducers/applyActiveGraphElementActions";
import {makeBoardByIdSelector} from "../selectors/board";
import {makeElementsTreeByBoardIdSelector} from "../selectors/elementsTree";
import {makeBoardStickyTemplateMapSelector} from "../selectors/StickyTemplate";
import {isConnected, markAsConnected, markAsDisconnected} from "./BoardElementDetails";
import BoardElementMetadata, {BoardElementMetadataRef} from "./BoardElementDetails/BoardElementMetadata";

interface RouteMatchParams {
  uid: BoardId;
}

type SideBySideDetailsProps = WithNamespaces & RouteComponentProps<RouteMatchParams>;

let syncTimer: number;

const SideBySideDetails = (props: SideBySideDetailsProps) => {
  const dispatch = useDispatch();
  const sidebar = useSelector(makeGetSidebarSelector());
  const [collabsableRight, setCollabsableRight] = useState(calculateCollapsableRight(sidebar));
  const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
  const board = useSelector(getBoardByIdSelector);
  const boardStickyTemplates = useSelector(makeBoardStickyTemplateMapSelector(props.match.params.uid));
  const [isDragging, setIsDragging] = useState(false);
  const elementsTree = useSelector(makeElementsTreeByBoardIdSelector(props.match.params.uid));
  const visible =  ((board && board.shouldShowElementDetails) || false) && sidebar.sideBySideCell;
  const boardElementMetadataRef = useRef<BoardElementMetadataRef|null>(null);
  const [graph] = useGraph();

  const activeGraphElement: ActiveGraphElement | null = useMemo(() => {
    if(sidebar.sideBySideCell) {
      const node = graph.getNode(sidebar.sideBySideCell);

      if(!node) {
        return null;
      }

      return graph.makeActiveGraphElementIfPossible(node);
    }

    return null;
  }, [
    sidebar.sideBySideCell
  ])


  useEffect(() => {
    if(!isDragging) {
      setCollabsableRight(calculateCollapsableRight(sidebar));
    }
  }, [sidebar.sideBySideWidth, sidebar.rightSidebarWidth]);

  const handleCloseSideBySide = () => {
    dispatch(setSideBySideCell(null));
  }

  const handleDragging = (e: DraggableEvent, data: DraggableData) => {
    if(!isDragging) {
      setIsDragging(true);
    }

    const newWidth = sidebar.sideBySideWidth - data.deltaX;

    dispatch(setSideBySideWidth(newWidth));
  }

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    setIsDragging(false);
    setCollabsableRight(calculateCollapsableRight(sidebar));
  }

  let similarElements = List<string>();
  let activeNodeSources: List<Node> = List();
  let activeNodeTargets: List<Node> = List();

  if(activeGraphElement && activeGraphElement.type !== NodeType.edge) {
    const node = elementsTree.getElement(activeGraphElement.id) || makeNodeFromActiveGraphElement(activeGraphElement);
    const similarElementsNodes = elementsTree.getSimilarElements(node)
      .filter(ele => ele.getTags().contains(ispConst.TAG_CONNECTED));

    similarElements = similarElementsNodes.map(ele => ele.getId());

    const activeNode = elementsTree.getElement(activeGraphElement.id);

    if(activeNode) {
      activeNodeSources = activeNode.getSources();
      activeNodeTargets = activeNode.getTargets();

      let activeNodeSourcesIds = activeNodeSources.map(n => n.getId());
      let activeNodeTargetsIds = activeNodeTargets.map(n => n.getId());

      similarElementsNodes.forEach(se => {
        const deduplicatedSources = se.getSources().filter(s => !activeNodeSourcesIds.contains(s.getId()));
        const deduplicatedTargets = se.getTargets().filter(t => !activeNodeTargetsIds.contains(t.getId()));

        activeNodeSources = activeNodeSources.push(...deduplicatedSources.toArray());
        activeNodeSourcesIds = activeNodeSourcesIds.push(...deduplicatedSources.toArray().map(s => s.getId()));
        activeNodeTargets = activeNodeTargets.push(...deduplicatedTargets.toArray());
        activeNodeTargetsIds = activeNodeTargetsIds.push(...deduplicatedTargets.toArray().map(t => t.getId()));
      })
    }
  }

  if(activeGraphElement && activeGraphElement.type === NodeType.edge && activeGraphElement.edgeData) {
    const activeSource = elementsTree.getElement(activeGraphElement.edgeData.source);
    const activeTarget = elementsTree.getElement(activeGraphElement.edgeData.target);
    if(activeSource) {
      activeNodeSources = List([activeSource]);
    }
    if(activeTarget) {
      activeNodeTargets = List([activeTarget]);
    }
  }

  const handleUpdateMetadata = (editorValue: string, force?: boolean) => {
    activeGraphElement!.metadata = editorValue;
    activeGraphElement!.updateMetadata(editorValue, force);

    if(isConnected(activeGraphElement!) && similarElements.count()) {

      if(syncTimer) {
        window.clearTimeout(syncTimer);
      }

      syncTimer = window.setTimeout(() => {
        activeGraphElement!.updateSimilarElements(similarElements.toArray(), editorValue);
      }, 300);
    }
  };

  const handleDisconnectElement = (e: SyntheticEvent) => {
    e.preventDefault();

    if(activeGraphElement) {
      if(activeGraphElement.locked) {
        return;
      }

      activeGraphElement.replaceTags(markAsDisconnected(activeGraphElement));
    }
  }

  const handleConnectElement = (e: SyntheticEvent) => {
    e.preventDefault();

    if(activeGraphElement) {
      if(activeGraphElement.locked) {
        return;
      }

      activeGraphElement.replaceTags(markAsConnected(activeGraphElement));
    }
  }

  return <>{visible && <div id="side-by-side-sidebar" style={{position: "absolute", paddingTop: "50px", top: "0px", right: sidebar.rightSidebarWidth + 5 + "px", height: "100%", overflow: "hidden", borderLeft:"5px solid #eee", zIndex: 99}}>
    <Card style={{height: "100%", borderRadius:'0', width: sidebar.sideBySideWidth + 'px'}}>
      <Card.Content>
        <Card.Header><Icon name="close" className="right floated" onClick={handleCloseSideBySide} style={{cursor: "pointer"}} />
          {activeGraphElement && <>{activeGraphElement!.label}</>}
          {!activeGraphElement && <>Metadata</>}
        </Card.Header>
      </Card.Content>
      <Card.Content style={{position: "relative", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column"}}>
        {!activeGraphElement && <Message info={true}>{props.t('insp.sidebar.element_details.no_element_selected')}</Message>}
        {activeGraphElement && (
          <BoardElementMetadata
            boardId={props.match.params.uid}
            board={board}
            activeNodeSources={activeNodeSources}
            activeNodeTargets={activeNodeTargets}
            activeGraphElement={activeGraphElement}
            similarElements={similarElements}
            boardStickyTemplate={boardStickyTemplates.get(activeGraphElement.type as NodeType)!}
            onTriggerCody={() => graph.triggerCody()}
            onUpdateMetadata={handleUpdateMetadata}
            onShowRunCody={() => { /* ignore */ }}
            onClose={handleCloseSideBySide}
            t={props.t}
            ref={boardElementMetadataRef}
            focusEditor={!!board.shouldFocusMetadataEditor}
          />
        )}
      </Card.Content>
    </Card>
    <Draggable
                axis="x"
                onDrag={handleDragging}
                onStop={handleDragStop}
                position={{x: 0, y: 0}}
    >
        <div className="thin collapsebar collapsible" style={{right: collabsableRight + 'px', cursor: 'grabbing'}} />
    </Draggable>
  </div>}
  </>
};

export default withNamespaces()(withRouter(SideBySideDetails));

const calculateCollapsableRight = (sidebar: SidebarState): number => {
  return sidebar.sideBySideWidth + 5 + sidebar.rightSidebarWidth;
}


