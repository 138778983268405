import {action} from "typesafe-actions";
import {
    SET_CODY_CONSOLE_HEIGHT,
    SET_RIGHT_SIDEBAR_WIDTH,
    SET_SIDEBAR_WIDTH, SET_SIDEBYSIDE_CELL, SET_SIDEBYSIDE_WIDTH,
    TOGGLE_SIDEBAR_COLLAPSED, TOGGLE_SIDEBAR_DIVIDER, TOGGLE_SIDEBAR_EDITOR,

} from "./constants";

export const toggleSidebarCollapsed = (collapsed: boolean) => action(TOGGLE_SIDEBAR_COLLAPSED, {collapsed});
export const setSidebarWidth = (width: number) => action(SET_SIDEBAR_WIDTH, {width});
export const setRightSidebarWidth = (width: number) => action(SET_RIGHT_SIDEBAR_WIDTH, {width});
export const setSideBySideWidth = (width: number) => action(SET_SIDEBYSIDE_WIDTH, {width});
export const setSideBySideCell = (cell: string | null) => action(SET_SIDEBYSIDE_CELL, {cell});
export const setCodyConsoleHeight = (height: number) => action(SET_CODY_CONSOLE_HEIGHT, {height});
export const toggleSidebarDivider = (divider: string, collapsed: boolean) => action(TOGGLE_SIDEBAR_DIVIDER, {divider, collapsed});
export const toggleSidebarEditor = (editor: string, expanded: boolean, double: boolean) => action(TOGGLE_SIDEBAR_EDITOR, {editor, expanded, double});
