import * as React from 'react';
import {Icon} from "semantic-ui-react";
import {useDispatch} from "react-redux";

interface OwnProps {
  expanded: boolean;
  double?: boolean;
  onExpandChanged: (expand: boolean, double?: boolean) => void;
}

type ExpandButtonProps = OwnProps;

const ExpandButton = (props: ExpandButtonProps) => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent) => {
    if(e.ctrlKey) {
      props.onExpandChanged(true, !props.double);
    } else {
      props.onExpandChanged(!props.expanded, false);
    }
  }

  return <div style={{display: "flex", marginBottom: "10px"}}>
    <button className="ui basic compact button noborder" style={{marginLeft: "auto", padding: 0}} onClick={handleClick} title="Expand the editor. Ctrl+Click doubles the expanded height.">
      <Icon name="expand" style={{margin: 0, color: props.expanded? '#2185d0' : 'inherit', opacity: 1}} />
    </button>
  </div>
};

export default ExpandButton;
