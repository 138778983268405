import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, ButtonGroup} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {ElementsTree} from "../../../model/ElementsTree";
import {
  Graph,
  makeNodeFromJs,
  Node,
  NodeType
} from "../../../model/Graph";
import {getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {ImmutableDDDWizardContext} from "../../../service/cody-wizard/context/ddd-action";
import {getEventMetadata} from "../../../service/cody-wizard/event/get-event-metadata";
import {names} from "../../../service/cody-wizard/node/names";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {getSchemaFromNodeDescription} from "../../../service/cody-wizard/schema/get-schema-from-node-description";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {ensureSingularNoun, isAdjective, isNoun, tagSentence} from "../../../service/cody-wizard/tagger";
import {UiSchema} from "../../../service/cody-wizard/ui/ui-schema";
import {getConnectedEventIfPossible} from "../../../service/cody-wizard/vo/get-connected-event-if-possible";
import {getDefaultUiTableSchema} from "../../../service/cody-wizard/vo/get-default-ui-table-schema";
import {getSchemaRefName} from "../../../service/cody-wizard/vo/get-schema-ref-name";
import {getVoMetadata, ValueObjectMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import {isStateVo} from "../../../service/cody-wizard/vo/is-state-vo";
import { isVoStoredInDb } from "../../../service/cody-wizard/vo/is-vo-stored-in-db";
import EditorDivider from "../Editor/EditorDivider";
import InitializeRulesEditor from "../Editor/InitializeRulesEditor";
import QuerySchemaEditor from "../Editor/QuerySchemaEditor";
import SchemaEditor from "../Editor/SchemaEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import UiSchemaEditor from "../Editor/UiSchemaEditor";
import {WithWizardStepProps} from "../WizardModal";
import AdvancedButton from "./AdvancedButton";
import {suggestCollection, suggestUiSchema} from "./AnyVo";
import CollectionInput from "./AnyVO/CollectionInput";
import EntityVoToggle from "./AnyVO/EntityVoToggle";
import StoredVoToggle from "./AnyVO/StoredVoToggle";
import IdentifierSelect, {getIdentifierCandidatesFromSchema} from "./StateVO/IdentifierSelect";
import NamespaceInput from "./StateVO/NamespaceInput";
import {cloneDeep} from "lodash";
import SchemaEditorDivider from "../Editor/Divider/SchemaEditorDivider";
import UiSchemaEditorDivider from "../Editor/Divider/UiSchemaEditorDivider";
import {stringify} from "comment-json";

interface OwnProps {}

type VOProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext> & WithNamespaces;

const StateVO = (props: VOProps) => {
  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedState, setSelectedState] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<ValueObjectMetadata>({});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [dataSchema, setDataSchema] = useState(new Schema({}));
  const version = props.ctx.state ? props.ctx.state.getVersion() : 0;

  const updateMetadata = (meta: ValueObjectMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(stringify(meta, null, 2), true);
    }

    if(meta.schema && stringify(meta.schema) !== stringify(dataSchema)) {
      setDataSchema(meta.schema);
    }

    setMetadata(meta);
  }

  useEffect(() => {
    resetForm();

    if(props.ctx.state) {
      setSelectedState(props.ctx.state);
      const stateMeta = getVoMetadata(props.ctx.state);
      let shouldUpdateMetadata = false;

      if(!stateMeta.schema || stateMeta.schema.isEmpty()) {
        stateMeta.schema = suggestStateSchema(props.ctx);
        shouldUpdateMetadata = true;
      }

      if(!stateMeta.uiSchema) {
        const suggestedUiSchema = suggestUiSchema(stateMeta.schema || new Schema({}), stateMeta.uiSchema, elementsTree, props.ctx);

        if(suggestedUiSchema) {
          shouldUpdateMetadata = true;
          stateMeta.uiSchema = suggestedUiSchema;
        }
      }

      if(typeof stateMeta.collection === "undefined" || stateMeta.collection === "_collection") {
        stateMeta.collection = suggestCollection(props.ctx.state, stateMeta, elementsTree);
        shouldUpdateMetadata = true;
      }

      if(shouldUpdateMetadata) {
        updateMetadata(stateMeta);
      }

      setMetadata(stateMeta);
      setDataSchema(stateMeta.schema);
    }

  }, [props.ctx.state, version])

  const resetForm = () => {
    setSelectedState(null);
    setMetadata({});
    setAutoFocusSchema(false);
    setDataSchema(new Schema({}));
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleNsChanged = (newNs: string) => {
    updateMetadata({...metadata, ns: newNs});
  }

  const handleIdentifierChanged = (newIdentifier: string) => {
    const newMetadata = {...metadata};

    if(newIdentifier === "") {
      delete newMetadata.identifier;
    } else {
      newMetadata.identifier = newIdentifier;
    }
    updateMetadata(newMetadata);
  }

  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.schema;
      updateMetadata(clonedMeta);
      return;
    }

    const suggestedUiSchema = suggestUiSchema(newSchema, metadata.uiSchema, elementsTree, props.ctx);

    updateMetadata({...metadata, schema: newSchema, uiSchema: suggestedUiSchema});
  }

  const handleQuerySchemaChanged = (newQuerySchema: Schema) => {
    updateMetadata({...metadata, querySchema: newQuerySchema});
  }

  const handleUiSchemaChanged = (newUiSchema: UiSchema | undefined) => {
    if(!newUiSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.uiSchema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, uiSchema: newUiSchema});
  }

  const handleSuggestSchema = () => {
    if(selectedState) {
      const event = getConnectedEventIfPossible(selectedState);

      if(event) {
        const evtMeta = getEventMetadata(event);

        if(evtMeta.schema) {
          handleSchemaChanged(evtMeta.schema);
          return;
        }
      }
    }

    if(props.ctx.command) {
      const commandMeta = getCommandMetadata(props.ctx.command);

      if(commandMeta.schema) {
        handleSchemaChanged(commandMeta.schema);
        return;
      }
    }

    if(selectedState) {
      const descSchema = getSchemaFromNodeDescription(selectedState);

      if(descSchema) {
        handleSchemaChanged(descSchema)
      }
    }
  }

  const handleSuggestUiSchema = () => {
    const newUiSchema = suggestUiSchema(
      metadata.schema || new Schema({}),
      metadata.uiSchema,
      elementsTree,
      props.ctx,
      false
    )

    if(newUiSchema) {
      handleUiSchemaChanged(newUiSchema);
    }
  }

  const handleStoredInDbChanged = (isStoredInDb: boolean) => {
    if(isStoredInDb) {
      const newMeta = {...metadata};
      newMeta.collection = suggestCollection(selectedState!, metadata, elementsTree);
      updateMetadata(newMeta);
    } else {
      updateMetadata({...metadata, collection: false});
    }
  }

  const handleIsEntityChanged = (isEntity: boolean) => {
    updateMetadata({...metadata, entity: isEntity});
  }

  const handleCollectionChanged = (newCollection: string) => {
    updateMetadata({...metadata, collection: newCollection})
  }

  const handleInitializeRulesChanged = (rules: Rule[] | undefined) => {
    if(!rules) {
      rules = [];
    }

    updateMetadata({...metadata, initialize: rules});
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    <EditorDivider label="Namespace" content={<>
      <NamespaceInput ns={metadata.ns || ''} onNsChanged={handleNsChanged} disabled={!!(selectedState && !selectedState.isEnabled())} />
    </>} />
    <EditorDivider label="Identifier" content={<>
      <IdentifierSelect
        identifier={metadata.identifier || ''}
        schema={dataSchema}
        disabled={!!(selectedState && !selectedState.isEnabled())}
        onIdentifierChanged={handleIdentifierChanged}
        identifierCandidates={getIdentifierCandidatesFromSchema(dataSchema, elementsTree)}
      />
    </>} />

    <SchemaEditorDivider content={<>
      <SchemaEditor boardId={props.ctx.board.uid}
                    sessionId={selectedState ? selectedState.getId() : undefined }
                    schema={dataSchema}
                    readonly={!!(selectedState && !selectedState.isEnabled())}
                    onSchemaChanged={handleSchemaChanged}
                    autofocus={autoFocusSchema}
      />
      <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestSchema} />
    </>} />
    <EditorDivider label="Query Schema" content={<>
      <QuerySchemaEditor boardId={props.ctx.board.uid}
                         sessionId={selectedState? selectedState.getId() : undefined }
                         schema={metadata.querySchema || Schema.fromString('{}')}
                         readonly={!!(selectedState && !selectedState.isEnabled())}
                         onSchemaChanged={handleQuerySchemaChanged}
      />
    </>} />

    <UiSchemaEditorDivider type="state" content={<>
      <UiSchemaEditor boardId={props.ctx.board.uid}
                      sessionId={selectedState? selectedState.getId() : undefined }
                      nodeSchema={dataSchema}
                      schema={metadata.uiSchema || {}}
                      readonly={!!(selectedState && !selectedState.isEnabled())}
                      onSchemaChanged={handleUiSchemaChanged}
      />
      <ButtonGroup>
        <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestUiSchema} />
        <Button basic={true} compact={true} icon="search" className="noborder" content={props.t('insp.cody_wizard.ui_schema_editor.choose_icon')} as="a" href="https://pictogrammers.com/library/mdi/" target="_blank" />
      </ButtonGroup>
    </>} />

    <AdvancedButton showAdvancedSettings={showAdvancedSettings} onShowChanged={show => setShowAdvancedSettings(show)}  disabled={!selectedState} />
    <div className={(showAdvancedSettings? 'slidedown' : 'slideup') + ' smooth'}>
      <EditorDivider label={'Classification'} content={<>
        <EntityVoToggle isEntity={!!metadata.entity} vo={selectedState} onIsEntityChanged={handleIsEntityChanged} disabled={!!(selectedState && !selectedState.isEnabled())}/>
        <StoredVoToggle isStored={isVoStoredInDb(metadata)} ctx={props.ctx} vo={selectedState} onStoredStateChanged={handleStoredInDbChanged} disabled={!!(selectedState && !selectedState.isEnabled())} />
        {selectedState && isVoStoredInDb(metadata) && <EditorDivider label={'Collection'} content={<>
          <CollectionInput collection={metadata.collection || '_collection'} onCollectionChanged={handleCollectionChanged} disabled={!selectedState.isEnabled()} />
        </>} />}
      </>} />
      <EditorDivider label="Initialize Rules" content={<>
        <InitializeRulesEditor elementId={selectedState ? selectedState.getId() : ''}
                               sessionId={selectedState ? selectedState.getId() : undefined }
                               initializeRules={metadata.initialize || []}
                               dataSchema={dataSchema}
                               readonly={!!(selectedState && !selectedState.isEnabled())}
                               ctx={props.ctx}
                               onRulesChanged={handleInitializeRulesChanged}
        />
      </>} />

    </div>
    <div style={{marginTop: '100px'}} />
  </form>
};

export default withNamespaces()(StateVO);

export const createListFromState = (state: Node, meta: ValueObjectMetadata, graph: Graph, elementsTree: ElementsTree): Node | null => {
  if(!isStateVo(state)) {
    return null;
  }

  const ref = getSchemaRefName(state, meta);

  const similarElements = elementsTree.getSimilarElements(makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.document,
    name: state.getTechnicalName() + ' List',
    metadata: stringify({ns: meta.ns || ispConst.DEFAULT_DOC_NS}, null, 2)
  }));


  const listMeta: ValueObjectMetadata = (similarElements.count() > 0)
    ? getVoMetadata(similarElements.first())
    : {
    ns: meta.ns || ispConst.DEFAULT_DOC_NS,
    schema: new Schema({
      $items: ref
    }),
    querySchema: new Schema({}),
    uiSchema: getDefaultUiTableSchema(state, meta)
  }

  return makeNodeFromJs({
    id: graph.createCellId(),
    name: state.getTechnicalName() + ' List',
    type: NodeType.document,
    metadata: stringify(listMeta, null, 2),
  })
}

export const suggestStateSchema = (ctx: ImmutableDDDWizardContext): Schema => {
  const firstEvent = ctx.events.first(undefined);
  const command = ctx.command;

  if(command) {
    const cmdMeta = getCommandMetadata(command);

    if(cmdMeta.schema) {
      return cmdMeta.schema;
    }
  }

  if(firstEvent) {
    const evtMeta = getEventMetadata(firstEvent);

    if(evtMeta.schema) {
      return evtMeta.schema;
    }
  }

  return new Schema({});
}

export const suggestNewEntityName = (ctx: ImmutableDDDWizardContext): string | undefined => {
  let name = '';

  if(ctx.aggregate) {
    name = ctx.aggregate.getTechnicalName();
  } else if(ctx.command) {
    name = ctx.command.getTechnicalName();
  } else if(ctx.events.count()) {
    name = ctx.events.first(makeNodeFromJs({})).getTechnicalName();
  }

  if(name === '') {
    return;
  }

  const nodeNames = names(name);

  const words = tagSentence(nodeNames.title);

  console.log("[CodyWizard] tagged words: ", words);

  return words.filter(word => isNoun(word) || isAdjective(word)).map(word =>  isNoun(word) ? ensureSingularNoun(word) : word.token).join(" ");
}

