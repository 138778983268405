import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import EditorDivider from "../EditorDivider";

interface OwnProps {
  content: React.ReactNode;
  label: string;
}

type ProcessorEditorDividerProps = OwnProps & WithNamespaces;

const ProcessorEditorDivider = (props: ProcessorEditorDividerProps) => {
  return <EditorDivider label={props.label} content={props.content} popupContent={<>
    <p>{props.t('insp.cody_wizard.editor.processor.help1')}</p>
    <p>{props.t('insp.cody_wizard.editor.processor.help2')}</p>
    <p>{props.t('insp.cody_wizard.editor.contextVariables')}</p>
    <ul>
      <li>event: event data</li>
      <li>meta: event metadata</li>
      <li>Processor Dependencies</li>
    </ul>
    <p>{props.t('insp.cody_wizard.editor.autocomplete')}</p>
    <p><a href="https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#processor-rules"
          onClick={e => e.stopPropagation()}
          target="_blank"
          rel="nofollow">{props.t('app.learnMore')}</a></p>
  </>}/>
};

export default withNamespaces()(ProcessorEditorDivider);
