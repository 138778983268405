import {JSONSchema7} from "json-schema";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";
import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeThenThrowError = (): { rule_then_throw_error: JSONSchemaWithMarkdown } => {
  return {
    rule_then_throw_error: {
      type: "object",
      additionalProperties: false,
      required: ["throw"],
      properties: {
        throw: {
          type: "object",
          markdownDescription: `**Throw Error**\n\nStop the entire execution flow and throw an error. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#throw-error)`,
          additionalProperties: false,
          required: ["error"],
          properties: {
            "error": makeJexlExpression(`Provide the error message via ${JEXL_WIKI_MARKDOWN}`),
          }
        }
      }
    }
  }
}
