import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import EditorDivider from "../EditorDivider";

interface OwnProps {
  content: React.ReactNode;
  label: string;
}

type ResolverEditorDividerProps = OwnProps & WithNamespaces;

const ResolverEditorDivider = (props: ResolverEditorDividerProps) => {
  return <EditorDivider label={props.label} content={props.content} popupContent={<>
    <p>{props.t('insp.cody_wizard.editor.resolver.help1')}</p>
    <p>{props.t('insp.cody_wizard.editor.resolver.help2')}</p>
    <p>{props.t('insp.cody_wizard.editor.contextVariables')}</p>
    <ul>
      <li>query: query data</li>
      <li>meta: query metadata</li>
      <li>Query Dependencies</li>
    </ul>
    <p>{props.t('insp.cody_wizard.editor.autocomplete')}</p>
    <p><a href="https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules"
          onClick={e => e.stopPropagation()}
          target="_blank"
          rel="nofollow">{props.t('app.learnMore')}</a></p>
  </>}/>
};

export default withNamespaces()(ResolverEditorDivider);
