import * as React from 'react';
import {useEffect, useState} from "react";
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import * as Routes from "../routes";
import StandaloneOverviewSidebar from "../standalone/components/Layout/Sidebar/OverviewSidebar"
import StandaloneWorkspaceSidebar from "../standalone/components/Layout/Sidebar/WorkspaceSidebar"
import {isStandalone} from "../standalone/util";
import {setSidebarWidth, toggleSidebarCollapsed} from "./actions/commands";
import {makeGetSidebarSelector} from "./selectors/sidebar";
import CollapseToggle from "./Sidebar/CollapseToggle";
import OverviewSidebar from "./Sidebar/OverviewSidebar";
import WorkspaceSidebar from "./Sidebar/WorkspaceSidebar";
import Copyright from "./Sidebar/Copyright";

type SidebarProps =  WithNamespaces & RouteComponentProps;

const Sidebar = (props: SidebarProps) => {
    const isBoardWorkspace = Routes.isInspectioBoardWorkspace(props.location.pathname);
    const state = useSelector(makeGetSidebarSelector());
    const dispatch = useDispatch();
    const [collabsableLeft, setCollabsableLeft] = useState(state.width);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if(!isDragging) {
            setCollabsableLeft(state.width);
        }
    }, [state.width]);

    const collapsible = isBoardWorkspace ? " collapsible" : "";
    const collapsed = (isBoardWorkspace && state.collapsed)? " collapsed" : "";
    const touchOnlyPan = (isBoardWorkspace)? " touch-only-pan" : "";

    const handleDragging = (e: DraggableEvent, data: DraggableData) => {
        if(!isDragging) {
            setIsDragging(true);
        }

        const newWidth = state.width + data.deltaX;

        dispatch(setSidebarWidth(newWidth));

        if(state.width <= 230 && !state.collapsed) {
            dispatch(toggleSidebarCollapsed(true));
        }

        if(state.width > 230 && state.collapsed) {
            dispatch(toggleSidebarCollapsed(false));
        }
    }

    const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
        setIsDragging(false);
        setCollabsableLeft(state.width);
    }

    return (
        <>
            <div className={"ui left fixed large vertical menu leftbar"+collapsed+touchOnlyPan} style={{overflowY:"scroll", width: (isBoardWorkspace? state.width : 250) + "px"}}>
                {!isBoardWorkspace && !isStandalone() && <OverviewSidebar />}
                {!isBoardWorkspace && isStandalone() && <StandaloneOverviewSidebar />}
                {isBoardWorkspace && !isStandalone() && <WorkspaceSidebar collapsed={state.collapsed} />}
                {isBoardWorkspace && isStandalone() && <StandaloneWorkspaceSidebar collapsed={state.collapsed} />}
                <Copyright />
            </div>
            <Draggable
                axis={isBoardWorkspace? "x" : "none"}
                onDrag={handleDragging}
                onStop={handleDragStop}
                position={{x: 0, y: 0}}
            >
                <div className={"collapsebar"+collapsible+collapsed}
                     style={{
                         left: (isBoardWorkspace? collabsableLeft : 250) + 'px',
                         cursor: (isBoardWorkspace? 'grabbing' : 'inherit')
                     }}
                >{isBoardWorkspace && <CollapseToggle collapsed={state.collapsed} />}</div>
            </Draggable>
        </>
    );
};

export default withNamespaces()(withRouter(Sidebar));
