import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import EditorDivider from "../EditorDivider";

interface OwnProps {
  content: React.ReactNode;
  label: string;
  messageType: "command" | "event" | "query";
}

type DependenciesEditorDividerProps = OwnProps & WithNamespaces;

const DependenciesEditorDivider = (props: DependenciesEditorDividerProps) => {
  return <EditorDivider label={props.label} content={props.content} popupContent={<>
    <p>{props.t('insp.cody_wizard.editor.dependencies.help1')}</p>
    <p>{props.t('insp.cody_wizard.editor.dependencies.help2')}</p>
    <p>{props.t('insp.cody_wizard.editor.contextVariables')}</p>
    <ul>
      <li>{props.messageType}: {props.messageType} data</li>
      <li>meta: {props.messageType} metadata</li>
      <li>Loaded Query Dependencies</li>
    </ul>
    <p><a href="https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#dependencies"
          onClick={e => e.stopPropagation()}
          target="_blank"
          rel="nofollow">{props.t('app.learnMore')}</a></p>
  </>}/>
};

export default withNamespaces()(DependenciesEditorDivider);
