import {List} from "immutable";
import {SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import * as React from "react";
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Card, Icon, Message} from "semantic-ui-react";
import {setRightSidebarWidth} from "../../Layout/actions/commands";
import {makeGetSidebarSelector} from "../../Layout/selectors/sidebar";
import * as Action from "../actions";
import {toggleElementDetails} from "../actions/commands";
import {activeGraphElementChanged} from "../actions/events";
import {useGraph} from "../hooks/useGraph";
import {BoardId} from "../model/Board";
import {Node, NodeType} from "../model/Graph";
import {ActiveGraphElement, makeNodeFromActiveGraphElement} from "../reducers/applyActiveGraphElementActions";
import {makeActiveGraphElementSelector} from "../selectors/activeGraphElement";
import {makeBoardByIdSelector} from "../selectors/board";
import {makeElementsTreeByBoardIdSelector} from "../selectors/elementsTree";
import {makeBoardStickyTemplateMapSelector} from "../selectors/StickyTemplate";
import BoardElementMetadata, {BoardElementMetadataRef} from "./BoardElementDetails/BoardElementMetadata";
import RunCodyOnSelect from "./CodyEngineWizard/CodyMonitor/RunCodyOnSelect";

interface ElementDetailsRouteMatchParams {
    uid: BoardId;
}

type BoardElementDetailsProps =  WithNamespaces & RouteComponentProps<ElementDetailsRouteMatchParams>;

export const isConnected = (ele: ActiveGraphElement): boolean => {
    const tags = ele.tags || [];

    return tags.includes(ispConst.TAG_CONNECTED);
}

export const markAsDisconnected = (ele: ActiveGraphElement): string[] => {
    const tags = ele.tags || [];

    tags.push(ispConst.TAG_DISCONNECTED);
    return tags.filter(tag => tag !== ispConst.TAG_CONNECTED);
}

export const markAsConnected = (ele: ActiveGraphElement): string[] => {
    const tags = ele.tags || [];

    tags.push(ispConst.TAG_CONNECTED);
    return tags.filter(tag => tag !== ispConst.TAG_DISCONNECTED);
}

let syncTimer: number;

const BoardElementDetails = (props: BoardElementDetailsProps) => {

    const dispatch = useDispatch();
    const sidebar = useSelector(makeGetSidebarSelector());
    const [showSimilarElements, setShowSimilarElements] = useState<boolean>(false);
    const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
    const board = useSelector(getBoardByIdSelector);
    const activeGraphElement = useSelector(makeActiveGraphElementSelector(props.match.params.uid));
    const visible =  (board && board.shouldShowElementDetails) || false;
    const boardStickyTemplates = useSelector(makeBoardStickyTemplateMapSelector(props.match.params.uid));
    const elementsTree = useSelector(makeElementsTreeByBoardIdSelector(props.match.params.uid));
    const [isDragging, setIsDragging] = useState(false);
    const [collabsableRight, setCollabsableRight] = useState(sidebar.rightSidebarWidth);
    const [graph] = useGraph();
    const boardElementMetadataRef = useRef<BoardElementMetadataRef|null>(null);
    const [showRunCody, setShowRunCody] = useState(true);

    useEffect(() => {
        if(!isDragging) {
            setCollabsableRight(sidebar.rightSidebarWidth);
        }
    }, [sidebar.rightSidebarWidth]);

    useEffect(() => {
        dispatch(Action.Query.fetchBoardStickyTemplates(props.match.params.uid))
        setShowRunCody(true);
    }, [props.match.params.uid])

    useEffect(() => {
        if(board && board.shouldFocusMetadataEditor) {
            dispatch(Action.Command.toggleElementDetails(props.match.params.uid, visible, false));
            setShowRunCody(false);
        }
    }, [board && board.shouldFocusMetadataEditor])

    const activeElementId = activeGraphElement ? activeGraphElement.id : null;

    useEffect(() => {
        setShowRunCody(true);
    }, [activeElementId]);


    let similarElements = List<string>();
    let activeNodeSources: List<Node> = List();
    let activeNodeTargets: List<Node> = List();

    const handleDragging = (e: DraggableEvent, data: DraggableData) => {
        if(!isDragging) {
            setIsDragging(true);
        }

        const newWidth = sidebar.rightSidebarWidth - data.deltaX;

        dispatch(setRightSidebarWidth(newWidth));
    }

    const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
        setIsDragging(false);
        setCollabsableRight(sidebar.rightSidebarWidth);
    }


    if(activeGraphElement && activeGraphElement.type !== NodeType.edge) {
        const node = elementsTree.getElement(activeGraphElement.id) || makeNodeFromActiveGraphElement(activeGraphElement);
        const similarElementsNodes = elementsTree.getSimilarElements(node)
          .filter(ele => ele.getTags().contains(ispConst.TAG_CONNECTED));

        similarElements = similarElementsNodes.map(ele => ele.getId());

        const activeNode = elementsTree.getElement(activeGraphElement.id);

        if(activeNode) {
            activeNodeSources = activeNode.getSources();
            activeNodeTargets = activeNode.getTargets();

            let activeNodeSourcesIds = activeNodeSources.map(n => n.getId());
            let activeNodeTargetsIds = activeNodeTargets.map(n => n.getId());

            similarElementsNodes.forEach(se => {
                const deduplicatedSources = se.getSources().filter(s => !activeNodeSourcesIds.contains(s.getId()));
                const deduplicatedTargets = se.getTargets().filter(t => !activeNodeTargetsIds.contains(t.getId()));

                activeNodeSources = activeNodeSources.push(...deduplicatedSources.toArray());
                activeNodeSourcesIds = activeNodeSourcesIds.push(...deduplicatedSources.toArray().map(s => s.getId()));
                activeNodeTargets = activeNodeTargets.push(...deduplicatedTargets.toArray());
                activeNodeTargetsIds = activeNodeTargetsIds.push(...deduplicatedTargets.toArray().map(t => t.getId()));
            })
        }
    }

    if(activeGraphElement && activeGraphElement.type === NodeType.edge && activeGraphElement.edgeData) {
        const activeSource = elementsTree.getElement(activeGraphElement.edgeData.source);
        const activeTarget = elementsTree.getElement(activeGraphElement.edgeData.target);
        if(activeSource) {
            activeNodeSources = List([activeSource]);
        }
        if(activeTarget) {
            activeNodeTargets = List([activeTarget]);
        }
    }


    const handleCloseElementDetails = () => {
        dispatch(activeGraphElementChanged(props.match.params.uid, activeGraphElement || undefined));
        dispatch(toggleElementDetails(props.match.params.uid, false, false));
        graph.focus();
    };

    const handleUpdateMetadata = (editorValue: string, force?: boolean) => {
        activeGraphElement!.metadata = editorValue;
        activeGraphElement!.updateMetadata(editorValue, force);

        if(isConnected(activeGraphElement!) && similarElements.count()) {

            if(syncTimer) {
                window.clearTimeout(syncTimer);
            }

            syncTimer = window.setTimeout(() => {
                activeGraphElement!.updateSimilarElements(similarElements.toArray(), editorValue);
            }, 300);
        }
    };

    const handleDisconnectElement = (e: SyntheticEvent) => {
        e.preventDefault();

        if(activeGraphElement) {
            if(activeGraphElement.locked) {
                return;
            }

            activeGraphElement.replaceTags(markAsDisconnected(activeGraphElement));
        }
    }

    const handleConnectElement = (e: SyntheticEvent) => {
        e.preventDefault();

        if(activeGraphElement) {
            if(activeGraphElement.locked) {
                return;
            }

            activeGraphElement.replaceTags(markAsConnected(activeGraphElement));
        }
    }

    const runCody = (e: SyntheticEvent) => {
        e.preventDefault();

        graph.openCodyWizard(true);
    }

    return (
        // Transition.Group in combination with monaco editor causes very expensive painting when closing the sidebar: main thread is blocked for > 10 sec
        // Hence, it is deactivated for now, but we should investigate further
        /*<Transition.Group animation="slide down">*/<>
            {visible && <div id="metadata-sidebar" className="touch-only-pan" style={{position: "absolute", paddingTop: "50px", top: "0px", right: "0px", height: "100%", overflow: "hidden", borderLeft:"5px solid #eee", zIndex: 99}}>
            <Card style={{height: "100%", borderRadius:'0', width: sidebar.rightSidebarWidth + 'px'}}>
                <Card.Content>
                    <Card.Header><Icon name="close" className="right floated" onClick={handleCloseElementDetails} style={{cursor: "pointer"}} />
                    {activeGraphElement && <>{activeGraphElement!.label}</>}
                    {!activeGraphElement && <>Metadata</>}
                    </Card.Header>
                </Card.Content>
                <Card.Content style={{position: "relative", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column"}}>
                    {!activeGraphElement && <Message info={true}>{props.t('insp.sidebar.element_details.no_element_selected')}</Message>}
                    {activeGraphElement && (
                        <BoardElementMetadata
                            boardId={props.match.params.uid}
                            board={board}
                            activeNodeSources={activeNodeSources}
                            activeNodeTargets={activeNodeTargets}
                            activeGraphElement={activeGraphElement}
                            similarElements={similarElements}
                            boardStickyTemplate={boardStickyTemplates.get(activeGraphElement.type as NodeType)!}
                            onTriggerCody={() => graph.triggerCody()}
                            onUpdateMetadata={handleUpdateMetadata}
                            onShowRunCody={setShowRunCody}
                            onClose={handleCloseElementDetails}
                            t={props.t}
                            ref={boardElementMetadataRef}
                            focusEditor={!!board.shouldFocusMetadataEditor}
                        />
                    )}
                </Card.Content>
                {showRunCody && activeGraphElement && <Card.Content style={{textAlign: 'center'}}>
                    <p><RunCodyOnSelect /></p>
                    <Button primary={true} icon="play circle" content={props.t('insp.cody_wizard.run_cody') as string} onClick={runCody}  />
                </Card.Content>}
                {!showRunCody && activeGraphElement && isConnected(activeGraphElement) && similarElements.size > 0 && <Card.Content>
                    <Message info={true} size="small" style={{textAlign: 'center'}}>
                        {props.t('insp.sidebar.element_details.disconnect_from_similar_elements_text', {number: similarElements.size})}
                        <p style={{textAlign: 'center', paddingTop: '10px'}}>
                            <a href='#' onClick={handleDisconnectElement} className={'button' + (activeGraphElement.locked? ' disabled' : '')}>{props.t('insp.sidebar.element_details.disconnect_from_similar_elements_link')}</a>
                        </p>
                    </Message>
                </Card.Content>}
                {!showRunCody && activeGraphElement && !isConnected(activeGraphElement) && similarElements.size > 0 && <Card.Content>
                    <Message warning={true} size="small" style={{textAlign: 'center'}}>
                        {props.t('insp.sidebar.element_details.connect_with_similar_elements_text', {number: similarElements.size})}
                        <p style={{textAlign: 'center', paddingTop: '10px'}}>
                            <a href='#' onClick={handleConnectElement} className={'button' + (activeGraphElement.locked? ' disabled' : '' )}>{props.t('insp.sidebar.element_details.connect_with_similar_elements_link')}</a>
                        </p>
                    </Message>
                </Card.Content>}
            </Card>
            <Draggable
                axis="x"
                onDrag={handleDragging}
                onStop={handleDragStop}
                position={{x: 0, y: 0}}
            >
                <div className="thin collapsebar collapsible" style={{right: collabsableRight + 'px', cursor: 'grabbing'}} />
            </Draggable>
        </div>}
        </>
        /*</Transition.Group> */
    );
};

export default withNamespaces()(withRouter(BoardElementDetails));
