import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeExecuteRules = (): { rule_then_execute_rules: JSONSchemaWithMarkdown } => {
  return {
    rule_then_execute_rules: {
      type: "object",
      additionalProperties: false,
      required: ["execute"],
      properties: {
        execute: {
          type: "object",
          additionalProperties: false,
          required: ["rules"],
          markdownDescription: `**Execute Rules**\n\nExecute a list of additional rules. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#execute-rules)`,
          default: {
            rules: []
          },
          properties: {
            rules: {type: "array", items: {$ref: "#/definitions/rule_definition"}, minItems: 1, default: []}
          }
        }
      }
    }
  }
}
