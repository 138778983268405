import {JSONSchema7} from "json-schema";
import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeThenRecordEvent = (availableEvents: string[]): { rule_then_record_event: JSONSchemaWithMarkdown } => {
  return {
    rule_then_record_event: {
      type: "object",
      properties: {
        record: {
          type: "object",
          markdownDescription: `**Record Event**\n\nRecord an event in the event store. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#record-event)`,
          properties: {
            event: {
              type: "string",
              enum: availableEvents
            },
            mapping: {
              $ref: "#/definitions/rule_then_prop_mapping",
              markdownDescription: `Data of the event`,
            },
            meta: {
              $ref: "#/definitions/rule_then_prop_mapping",
              markdownDescription: `Metadata of the event`,
            }
          },
          additionalProperties: false,
          required: ["event", "mapping"]
        }
      },
      additionalProperties: false,
      required: ["record"]
    }
  }
}
