import * as React from "react";
import {jsonrepair} from "../../../InspectioBoards/service/jsonrepair/jsonrepair";
import {JexlFlavouredJSON} from "./JexlFlavouredJSON";

export const monaco = (window as any).monaco;

export interface ModelData {
    fileId: string;
    language: 'json'|'xml'|'yaml';
    value: string;
    schema?: string|undefined;
    onChange?: (e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => void;
}

interface OwnState {
    internalData: Record<string, { onChangeDisposable: any }>;
}

interface OwnProps {
    options?: object;
    style?: React.CSSProperties;
    className?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    containerId?: string;
    onOpenInformationReference?: (ref: string) => void;
    repairJson?: boolean;
}

type CodeEditorProps = OwnProps;

const globalSchemaRegistry: {[fileId: string]: {uri: string, fileMatch: string[], schema: any}} = {};
const globalRefSupport: {[fileId: string]: {supportRefLinks: boolean}} = {};
let globalLinkProviderRegistered = false;

((globalMonaco: any) => {
    globalMonaco.languages.setMonarchTokensProvider('json', JexlFlavouredJSON);
})(monaco);

let isRefOpening = false;


class CodeEditor extends React.Component<CodeEditorProps, OwnState> {

    private editor: any;
    private containerId: string;

    constructor(props: CodeEditorProps) {
        super(props);

        this.disposeOnChangeHandler = this.disposeOnChangeHandler.bind(this);

        this.containerId = props.containerId || 'monaco-container';

        this.state = {
            internalData: {}
        };
    }

    public componentDidMount() {
        this.editor = monaco.editor.create(document.getElementById(this.containerId), this.props.options || {});
        const props = this.props;
        // @TODO: OpenService works across editors, so this is a global change. Find a local variant!!!
        if(props.onOpenInformationReference) {
            this.editor.getContribution('editor.linkDetector').openerService.open = async (url: string) => {
                if(url.includes('node://#')) {
                    // This is needed to prevent wrong schemaChanged events due to onBlur firing when ref is loaded
                    isRefOpening = true;
                    if(props.onOpenInformationReference) {
                        props.onOpenInformationReference(url.replace('node://#', '').replace('[]', ''))
                    }

                    window.setTimeout(() => {
                        // This is needed to prevent wrong schemaChanged events due to onBlur firing when ref is loaded
                        isRefOpening = false;
                    }, 500);

                    return true;
                }

                window.open(url, '_blank');

                return true;
            }
        }


        if(this.props.repairJson) {
            this.addKeyDownHandler(e => {
                if(this.editor && e.altKey && e.code === 'KeyF') {
                    const rawValue = this.editor.getValue();
                    try {
                        const repaired = jsonrepair(rawValue, true);

                        if(repaired !== rawValue) {
                            const currentPosition = this.editor.getPosition();
                            const currentLineLength = this.editor.getModel().getLineLength(currentPosition.lineNumber);
                            const editor = this.editor;
                            this.editor.setValue(repaired);
                            const newLineLength = this.editor.getModel().getLineLength(currentPosition.lineNumber);
                            const newPosition = {
                                ...currentPosition,
                                column: currentLineLength !== newLineLength ? newLineLength + 1 : currentPosition.column
                            }
                            this.editor.setPosition(newPosition);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    } catch (e) {
                        // ignore error
                        console.error(e);
                    }
                }
            })
        }

    }

    public componentWillUnmount() {
        Object.keys(this.state.internalData).forEach(this.disposeOnChangeHandler);
        this.editor = undefined;
    }

    public render() {
        return (
            <div
                id={this.containerId}
                style={this.props.style}
                className={this.props.className}
                onFocus={() => {
                    if(this.editor) {
                        this.editor.updateOptions({scrollBeyondLastLine: true});
                    }

                    if(this.props.onFocus) {
                        this.props.onFocus();
                    }
                }}
                onBlur={() => {
                    if(this.editor) {
                        this.editor.updateOptions({scrollBeyondLastLine: false});
                    }

                    if(isRefOpening) {
                        window.setTimeout(() => {
                            isRefOpening = false;
                        }, 500);
                        return true;
                    }

                    if(this.props.onBlur) {
                        this.props.onBlur();
                    }
                }}
            />
        );
    }

    public retrievePayload(): string {
        const value = this.editor.getValue();

        if(!this.props.repairJson) {
            return value;
        }

        try {
            const repaired = jsonrepair(value, true);
            if(repaired !== value) {
                this.editor.setValue(repaired);
            }

            return repaired;
        } catch (e) {
            console.log(e);
            return value;
        }

    }

    public initializeModel(modelData: ModelData): void {
        const modelUri = monaco.Uri.parse(modelData.fileId);
        let model = monaco.editor.getModel(modelUri);

        this.disposeOnChangeHandler(modelData.fileId);

        if(this.props.onOpenInformationReference) {
            globalRefSupport['/'+modelData.fileId] = {supportRefLinks: true};
        }

        if (null === model) {
            model = monaco.editor.createModel(modelData.value, modelData.language, modelUri);
            model.updateOptions({ tabSize: 2 });
        } else {
            monaco.editor.setModelLanguage(model, modelData.language);

            if (modelData.value !== model.getValue()) {
                model.setValue(modelData.value);
            }
        }

        if (modelData.onChange) {
            this.initializeOnChangeHandler(modelData.fileId, model, modelData.onChange);
        }

        if (modelData.language === 'json' && modelData.schema) {
            this.initializeJsonDiagnostics(modelData.fileId, modelUri, modelData.schema);
        }

        this.editor.setModel(model);
    }

    public addCommand(keybinding: number, handler: () => void): void {
        this.editor.addCommand(keybinding, handler);
    }

    public addKeyDownHandler(handler: (e: KeyboardEvent) => void): {dispose: () => void} {
        return this.editor.onKeyDown(handler);
    }

    public focus(): void {
        this.editor.focus();
    }

    private disposeOnChangeHandler(fileId: string) {
        if (this.state.internalData[fileId] && this.state.internalData[fileId].onChangeDisposable) {
            this.state.internalData[fileId].onChangeDisposable.dispose();
            this.updateInternalData(fileId, { onChangeDisposable: undefined });
        }
    }

    private initializeOnChangeHandler(fileId: string, model: any, onChange: (e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => void): void {
        // Make sure that any existing handler is disposed upon setting a new one
        this.disposeOnChangeHandler(fileId);

        const newDisposable = model.onDidChangeContent((e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => {
            onChange(e);
        });

        this.updateInternalData(fileId, { onChangeDisposable: newDisposable });
    }

    private initializeJsonDiagnostics(fileId: string, modelUri: any, schema: string): void {
        globalSchemaRegistry[fileId] = {
            uri: modelUri.toString(),
            fileMatch: [modelUri.toString()],
            schema: JSON.parse(schema)
        };

        monaco.languages.json.jsonDefaults.setModeConfiguration({
            tokens: false,
            completionItems: true,
            diagnostics: true,
            documentFormattingEdits: true,
            documentRangeFormattingEdits: true,
            documentSymbols: true,
            foldingRanges: true,
            hovers: true,
            selectionRanges: true
        });

        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            enableSchemaRequest: false,
            allowComments: true,
            trailingCommas: 'ignore',
            schemas: Object.values(globalSchemaRegistry),
        });

        if(!globalLinkProviderRegistered) {
            const linkProvider: any = {
                provideLinks:
                  (model: any, token: any): any => {
                    if(!globalRefSupport[model.uri.path]) {
                        return;
                    }

                    const matches = model.findMatches('"/[^"]{1,}', true, true, false, null, true);

                    console.log("link matches", matches);

                      return {
                          links: matches.map((match: any) => {
                              return {
                                  range: {...match.range, startColumn: match.range.startColumn + 1},
                                  url: "node://#"+match.matches[0].replace('"', '')
                              }
                          })
                      };
                },
                resolveLink:
                  (link: any, token: any): any => {
                      return {range: link.range}
                  }
            };

            monaco.languages.registerLinkProvider('json', linkProvider);
            globalLinkProviderRegistered = true;
        }
    }

    private updateInternalData(fileId: string, data: { onChangeDisposable: any }): void {
        this.setState({
            internalData: {
                ...this.state.internalData,
                [fileId]: data
            }
        });
    }
}

export default CodeEditor;
