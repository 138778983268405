import {parse, stringify} from "comment-json";
import {List} from "immutable";
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {setSideBySideCell, toggleSidebarEditor} from "../../../../Layout/actions/commands";
import {makeEditorStateByNameSelector} from "../../../../Layout/selectors/sidebar-editor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {Node} from "../../../model/Graph";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {getRefVo} from "../../../service/cody-wizard/vo/get-ref-vo";
import ExpandButton from "./ExpandButton";
import {getPossibleRefs} from "./SchemaEditor";
import {makeBusinessRulesSchema} from "./Validation/business-rules-schema";


interface OwnProps {
  boardId: string;
  elementId: string;
  commandSchema: Schema;
  rules: Rule[];
  onRulesChanged: (rules: Rule[] | undefined) => void;
  autofocus?: boolean;
  possibleEvents: List<Node>;
  dependencies: Record<string, RulesDependencyMetadata>;
  readonly?: boolean;
  sessionId?: string;
}

type BusinessRulesEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};
let isRefOpening = false;

const BusinessRulesEditor = (props: BusinessRulesEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const editorState = useSelector(makeEditorStateByNameSelector('business-rules'));
  const dispatch = useDispatch();

  const possibleServices: string[] = [];

  for (const dependenciesKey in props.dependencies) {
    if(props.dependencies[dependenciesKey].type === "service") {
      possibleServices.push(props.dependencies[dependenciesKey].alias || dependenciesKey);
    }
  }

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let value = stringify(props.rules, null, 2);
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `${props.sessionId}-business-rules.json`,
      language: "json",
      value,
      schema: makeBusinessRulesSchema(
        props.possibleEvents.map(e => e.getTechnicalName()).toArray(),
        possibleServices,
        getPossibleRefs(elementsTree, true),
        getExprSuggests(props.commandSchema)
      ),
    });

    setInvalidJson(isInvalidJson);
  }, [props.elementId, props.dependencies, props.commandSchema.toString()]);

  useEffect(() => {
    // This is needed to prevent wrong schemaChanged events due to onBlur firing when ref is loaded
    if(isRefOpening) {
      window.setTimeout(() => {
        isRefOpening = false;
      }, 500);
    }
  }, [props.sessionId]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {

    if(editorStr === '') {
      props.onRulesChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedRules = parse(editorStr);
      props.onRulesChanged(changedRules);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true
    } catch (e) {
      console.error("[CodyWizard] Business Rules Editor invalid JSON: ", e);

      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  const handleOpenInformationReference = (ref: string) => {
    const refVo = getRefVo(Schema.fromString(ref), elementsTree);

    if(refVo) {
      isRefOpening = true;
      dispatch(setSideBySideCell(refVo.getId()));
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorState.expanded} double={editorState.double} onExpandChanged={(expand, double) => dispatch(toggleSidebarEditor('business-rules', expand, !!double))}/>
    <CodeEditor
      containerId={'cody-business-rules-editor'}
      ref={codeEditorRef}
      onOpenInformationReference={handleOpenInformationReference}
      repairJson={true}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        mouseWheelZoom: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorState.expanded ? ' expanded' : '')  + (editorState.double ? ' double' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedRulesStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedRulesStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedRulesStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(BusinessRulesEditor);

const getExprSuggests = (commandSchema: Schema): string[] => {
  const suggests: string[] = commandSchema.getObjectProperties().map(prop => `command.${prop}`);

  suggests.push('meta.user.userId');

  return suggests;
}
