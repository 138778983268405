import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeThenTriggerCommand = (availableCommands: string[]): { rule_then_trigger_command: JSONSchemaWithMarkdown } => {
  return {
    rule_then_trigger_command: {
      type: "object",
      properties: {
        trigger: {
          type: "object",
          markdownDescription: `**Trigger Command**\n\nAutomatically trigger a new command without user intervention. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#trigger-command)`,
          properties: {
            command: {
              type: "string",
              enum: availableCommands,
            },
            mapping: {
              $ref: "#/definitions/rule_then_prop_mapping",
              markdownDescription: `Set command data`,
            },
            meta: {
              $ref: "#/definitions/rule_then_prop_mapping",
              markdownDescription: `Set command metadata`,
            }
          },
          additionalProperties: false,
          required: ["command", "mapping"]
        }
      },
      additionalProperties: false,
      required: ["trigger"]
    }
  }
}
