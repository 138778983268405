import {parse, stringify} from "comment-json";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {setSideBySideCell, toggleSidebarEditor} from "../../../../Layout/actions/commands";
import {makeEditorStateByNameSelector} from "../../../../Layout/selectors/sidebar-editor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {ElementsTree} from "../../../model/ElementsTree";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {getRefVo} from "../../../service/cody-wizard/vo/get-ref-vo";
import {ResolveConfig} from "../../../service/cody-wizard/vo/get-vo-metadata";
import ExpandButton from "./ExpandButton";
import {getPossibleRefs} from "./SchemaEditor";
import {makeResolveSchema} from "./Validation/resolve-schema";

interface OwnProps {
  boardId: BoardId;
  nodeSchema: Schema;
  querySchema: Schema;
  resolve?: ResolveConfig;
  onResolveChanged: (resolve?: ResolveConfig) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type ResolveEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};
let isRefOpening = false;

const ResolveEditor = (props: ResolveEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const editorState = useSelector(makeEditorStateByNameSelector('resolve'));
  const dispatch = useDispatch();

  let value = props.resolve ? stringify(props.resolve, null, 2) : '';

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `${props.sessionId}-resolve-config.json`,
      language: "json",
      value,
      schema: makeResolveSchema(getDocProperties(props.nodeSchema, elementsTree), getExprSuggests(props.querySchema), getPossibleRefs(elementsTree, true)),
    });

    setInvalidJson(isInvalidJson);
  }, [props.sessionId, value, props.nodeSchema.toString(), props.querySchema.toString()]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus]);

  useEffect(() => {
    // This is needed to prevent wrong schemaChanged events due to onBlur firing when ref is loaded
    if(isRefOpening) {
      window.setTimeout(() => {
        isRefOpening = false;
      }, 500);
    }
  }, [props.sessionId]);

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {

    if(editorStr === '') {
      props.onResolveChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedConfig = parse(editorStr);
      props.onResolveChanged(changedConfig);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] Resolve Config Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  const handleOpenInformationReference = (ref: string) => {
    const refVo = getRefVo(Schema.fromString(ref), elementsTree);

    if(refVo) {
      isRefOpening = true;
      dispatch(setSideBySideCell(refVo.getId()));
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorState.expanded} double={editorState.double} onExpandChanged={(expand, double) => dispatch(toggleSidebarEditor('resolve', expand, !!double))}/>
    <CodeEditor
      containerId={'cody-resolve-config-editor'}
      ref={codeEditorRef}
      onOpenInformationReference={handleOpenInformationReference}
      repairJson={true}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        mouseWheelZoom: true,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorState.expanded ? ' expanded' : '')  + (editorState.double ? ' double' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedResolveConfigsStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedResolveConfigsStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedResolveConfigsStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(ResolveEditor);

const getDocProperties = (nodeSchema: Schema, elementsTree: ElementsTree): string[] => {
  if(nodeSchema.isRef()) {
    return getDocProperties(nodeSchema.resolveRef(elementsTree), elementsTree);
  }

  if(nodeSchema.isObject()) {
    return nodeSchema.getObjectProperties();
  }

  if(!nodeSchema.isList()) {
    return [];
  }

  let listItemSchema = nodeSchema.getListItemsSchema(new Schema({}));

  if(listItemSchema.isRef()) {
    listItemSchema =  listItemSchema.resolveRef(elementsTree);
  }

  return listItemSchema.getObjectProperties();
}

const getExprSuggests = (querySchema: Schema): string[] => {
  const suggests: string[] = querySchema.getObjectProperties().map(prop => `query.${prop}`);

  suggests.push('meta.user.userId');

  return suggests;
}
