import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {setSideBySideCell} from "../../Layout/actions/commands";
import { Action as Notification } from "../../NotificationSystem";
import * as Action from "../actions";
import {default as MxGraphBoard} from "../components/MxGraphBoard";
import {BoardSelector} from "../selectors";

const mapStateToProps = BoardSelector.makeMapStateToPropsMxGraphBoard();

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        fetchUserBoard: Action.Query.fetchUserBoard,
        onError: Notification.Command.error,
        onInfo: Notification.Command.info,
        saveBoard: Action.Command.saveBoard,
        clearBoardHistory: Action.Command.clearBoardHistory,
        onBoardImportStarted: Action.Event.boardImportStarted,
        onBoardImported: Action.Event.boardImported,
        onBoardExported: Action.Event.boardExported,
        onBoardInitialized: Action.Event.boardInitialized,
        onLocalBoardChanged: Action.Event.localBoardChanged,
        onRemoteBoardChanged: Action.Event.remoteBoardChanged,
        onLocalBoardUpdatedWithRemoteChange: Action.Event.localBoardUpdatedWithRemoteChange,
        filterElementsTree: Action.Command.filterElementsTree,
        onActiveGraphElementChanged: Action.Event.activeGraphElementChanged,
        onActiveGraphElementLabelChanged: Action.Event.activeGraphElementLabelChanged,
        onActiveGraphElementMetadataChanged: Action.Event.activeGraphElementMetadataChanged,
        toggleElementDetails: Action.Command.toggleElementDetails,
        toggleCodyConsole: Action.Command.toggleCodyConsole,
        togglePlayshotModal: Action.Command.togglePlayshotModal,
        setSideBySideCell,
    },
    dispatch,
);

const MxGraphBoardContainer = connect(mapStateToProps, mapDispatchToProps, undefined, {pure: false})(MxGraphBoard);

export default MxGraphBoardContainer;
