import axios from "axios";
import {JSONSchema7} from "json-schema";
import {makeRuleThenPropMapping} from "./rule-engine/prop-mapping";
import {makeAssignVariable} from "./rule-engine/assign-variable";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./rule-engine/jexl-expressions";
import {JSONSchemaWithMarkdown} from "../Suggestion/markdown-description";

const iconNameCache: string[] = [];
let iconsFetched: boolean = false;
const installedMdiVersion = '7.7.0';

if(!iconsFetched) {
  const fetch = axios.create();

  fetch.get('https://raw.githubusercontent.com/Templarian/MaterialDesign/master/meta.json').then(res => {
    if(res.status === 200) {
      res.data.forEach((icon: {name: string, version: string}) => {
        if (icon.version <= installedMdiVersion) {
          iconNameCache.push(icon.name);
        }
      })
    }
  }).catch(e => {
    console.error("[CodyWizard] Could not fetch MDI icon meta.json", e);
  })

  iconsFetched = true;
}

export const makeUiSchema = (additions: Record<string, JSONSchema7>, possibleRefs: string[], possibleCommands: string[]): string => {
  const assignValue = makeAssignVariable('value');

  const uiSchema: JSONSchema7 = {
    type: "object",
    properties: {
      "ui:title": {"oneOf": [{type: "string"}, {type: "boolean"}]},
      "ui:hidden": {type: ["string", "boolean"]},
      "ui:description": {type: "string"},
      "ui:disabled": {type: "boolean"},
      ...additions
    },
    definitions: {
      "ui_schema": {
        type: "object",
        properties: {
          "ui:title": {"oneOf": [{type: "string"}, {type: "boolean"}]},
          "ui:hidden": {type: "string"},
          "ui:description": {type: "string"},
          "ui:disabled": {type: "boolean"},
          "ui:widget": {
            anyOf: [
              {type: "string"},
              {const: "DataSelect"},
              {const: "color"},
              {const: "hidden"},
              {const: "password"},
              {const: "radio"},
              {const: "range"},
              {const: "select"},
              {const: "textarea"}
            ]
          },
          "ui:options": {
            type: "object",
            additionalProperties: true,
            properties: {
              data: {type: "string", enum: possibleRefs},
              text: {type: "string", minLength: 1},
              value: {type: "string", minLength: 1},
              addItemCommand: {type: "string", enum: possibleCommands}
            }
          }
        }
      },
      ...assignValue,
      ...makeRuleThenPropMapping()
    }
  }

  return JSON.stringify(uiSchema);
}

export const makeCommandButtonUiSchema = (): { "ui:button": JSONSchema7 } => {
  return {
    "ui:button": {
      type: "object",
      additionalProperties: false,
      properties: {
        variant: {type: "string", enum: ["text", "outlined", "contained"]},
        color: {type: "string", enum: ["primary", "secondary", "success", "error", "info", "warning"]},
        style: {type: "object"},
        disabled: {
          oneOf: [
            {type: "boolean"},
            {type: "string"}
          ]
        },
        hidden: {
          oneOf: [
            {type: "boolean"},
            {type: "string"}
          ]
        },
        icon: iconNameEnum()
      }
    }
  }
}

export const makeCommandFormUiSchema = (): {"ui:form": JSONSchemaWithMarkdown} => {
  return {
    "ui:form": {
      type: "object",
      additionalProperties: false,
      markdownDescription: "Configure behavior of the command form",
      properties: {
        data: {
          $ref: "#/definitions/rule_then_prop_mapping",
          markdownDescription: "Set the initial data of the form"
        },
        successRedirect: {
          type: "string",
          markdownDescription: "Redirect the user to another page when the command was successful"
        },
        "successRedirect:expr": makeJexlExpression(`Define success redirect as a ${JEXL_WIKI_MARKDOWN}`)
      }
    }
  }
}

export const makeTableUiSchema = (itemProperties: string[], possiblePages: string[], possibleListRefs: string[]): {"ui:table": JSONSchema7} => {
  return {
    "ui:table": {
      type: "object",
      additionalProperties: false,
      properties: {
        columns: {
          type: "array",
          items: {
            oneOf: [
              {type: "string", enum: itemProperties},
              {
                type: "object",
                additionalProperties: false,
                properties: {
                  "field": {type: "string", enum: itemProperties},
                  "headerName": {type: "string"},
                  "value": {
                    oneOf: [
                      {type: "string"},
                      {
                        type: "array",
                        items: {$ref: "#/definitions/rule_then_assign_variable"},
                        minItems: 1
                      }
                    ]
                  },
                  "flex": {type: "number"},
                  "width": {type: "string"},
                  "ref": {
                    type: "object",
                    additionalProperties: false,
                    required: ["data", "value"],
                    properties: {
                      data: {type: "string", enum: possibleListRefs},
                      value: {
                        oneOf: [
                          {type: "string"},
                          {
                            type: "array",
                            items: {$ref: "#/definitions/rule_then_assign_variable"},
                            minItems: 1
                          }
                        ]
                      },
                      "itemIdentifier": {
                        type: "string"
                      }
                    }
                  },
                  "pageLink": {
                    oneOf: [
                      {type: "string", enum: possiblePages},
                      {
                        type: "object",
                        additionalProperties: false,
                        required: ["page", "mapping"],
                        properties: {
                          page: {type: "string", enum: possiblePages},
                          mapping: {type: "object"}
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
        },
        density: {type: "string", enum: ["compact", "standard", "comfortable"]},
        hideToolbar: {type: "boolean"},
        checkboxSelection: {type: "boolean"},
        pageSize: {type: "number", minimum: 1},
        pageSizeOptions: {type: "array", items: {type: "number"}}
      }
    }
  }
}


export const iconNameEnum = (): JSONSchema7 => {
  return {
    type: "string",
    enum: iconNameCache,
  }
}

export const makeSidebarSchema = (possibleQueryableRefs: string[]): JSONSchema7 => {
  return {
    type: "object",
    additionalProperties: false,
    properties: {
      label: {type: "string", minLength: 1},
      icon: iconNameEnum(),
      hidden: {
        oneOf: [
          {type: "boolean"},
          {type: "string", minLength: 1}
        ]
      },
      'hidden:expr': {type: "string", minLength: 1},
      position: {type: "integer"},
      group: {
        oneOf: [
          {type: "string", minLength: 1},
          {
            type: "object",
            additionalProperties: false,
            required: ["label"],
            properties: {
              "label": {type: "string", minLength: 1},
              "icon": iconNameEnum()
            }
          }
        ]
      },
      dynamic: {
        type: "object",
        additionalProperties: false,
        required: ["data"],
        properties: {
          data: {type: "string", enum: possibleQueryableRefs},
          label: {type: "string", default: "''"},
          icon: {type: "string", default: "''"},
          hidden: {type: "string", default: "''"}
        }
      }
    }
  }
}

export const makeTabSchema = (): JSONSchema7 => {
  return {
    type: "object",
    additionalProperties: false,
    required: ["group", "label"],
    properties: {
      group: {type: "string", minLength: 1},
      label: {type: "string", minLength: 1},
      icon: iconNameEnum(),
      style: {type: "object", additionalProperties: true},
      'style:expr': {type: "string", minLength: 1},
      hidden: {
        oneOf: [
          {type: "boolean"},
          {type: "string", minLength: 1}
        ]
      },
      'hidden:expr': {type: "string", minLength: 1},
      disabled: {
        oneOf: [
          {type: "boolean"},
          {type: "string", minLength: 1}
        ]
      },
      'disabled:expr': {type: "string", minLength: 1}
    }
  }
}
