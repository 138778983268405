import {JSONSchema7} from "json-schema";
import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeForEach = (): { rule_then_for_each: JSONSchemaWithMarkdown } => {
  return {
    rule_then_for_each: {
      type: "object",
      additionalProperties: false,
      required: ["forEach"],
      properties: {
        forEach: {
          type: "object",
          additionalProperties: false,
          required: ["variable", "then"],
          markdownDescription: `**For Each**\n\nExecute a rule for each *item* of an array. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#foreach)`,
          properties: {
            variable: {type: "string", minLength: 1},
            then: {$ref: "#/definitions/rule_then"}
          }
        }
      }
    }
  }
}
