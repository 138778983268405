import {parse, stringify} from "comment-json";
import {cloneDeep} from "lodash";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {makeEditorStateByNameSelector} from "../../../../Layout/selectors/sidebar-editor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {UiMetadata} from "../../../service/cody-wizard/ui/get-ui-metadata";
import ExpandButton from "./ExpandButton";
import {
  getPossibleQueryableItemRefs,
  getPossibleQueryableVORefsWithoutQueryParams,
} from "./Typeahead/elements-tree-lookup";
import {makeUiConfigSchema} from "./Validation/ui-config";
import {toggleSidebarEditor} from "../../../../Layout/actions/commands";


interface OwnProps {
  boardId: BoardId;
  uiConfig: UiMetadata;
  onUiConfigChanged: (config: UiMetadata) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
  ctx: WizardContext;
}

type UiConfigEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const UiConfigEditor = (props: UiConfigEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const editorState = useSelector(makeEditorStateByNameSelector('ui-config'));
  const dispatch = useDispatch();

  const config = cloneDeep(props.uiConfig);
  delete config.commands;
  delete config.views;
  delete config.parent;
  delete config.$nodeName;
  delete config.$nodeType;

  let value = stringify(config, null, 2);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `${props.sessionId}-ui-config.json`,
      language: "json",
      value,
      schema: makeUiConfigSchema(getPossibleQueryableItemRefs(elementsTree), getPossibleQueryableVORefsWithoutQueryParams(elementsTree)),
    });

    setInvalidJson(isInvalidJson);
  }, [value]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onUiConfigChanged({
        commands: props.uiConfig.commands,
        views: props.uiConfig.views,
        $nodeType: props.uiConfig.$nodeType,
        $nodeName: props.uiConfig.$nodeName,
      });

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedConfig = parse(editorStr);
      props.onUiConfigChanged({...props.uiConfig, ...changedConfig});

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] UI Config Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorState.expanded} double={editorState.double} onExpandChanged={(expand, double) => dispatch(toggleSidebarEditor('ui-config', expand, !!double))}/>
    <CodeEditor
      containerId={'cody-ui-config-editor'}
      ref={codeEditorRef}
      repairJson={true}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        mouseWheelZoom: true,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorState.expanded ? ' expanded' : '')  + (editorState.double ? ' double' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedUiConfigsStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedUiConfigsStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedUiConfigsStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(UiConfigEditor);


