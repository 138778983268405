import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {
  Graph,
  Node,
} from "../../../model/Graph";
import {getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {ImmutableDDDWizardContext} from "../../../service/cody-wizard/context/ddd-action";
import {
  ApplyEventRule, EventMetadata,
  getEventMetadata
} from "../../../service/cody-wizard/event/get-event-metadata";
import {isEntityEvent} from "../../../service/cody-wizard/event/is-aggregate-event";
import {names} from "../../../service/cody-wizard/node/names";
import {getSchemaFromNodeDescription} from "../../../service/cody-wizard/schema/get-schema-from-node-description";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {isVerb, tagSentence, toPastTense} from "../../../service/cody-wizard/tagger";
import {getVoMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import EditorDivider from "../Editor/EditorDivider";
import EventApplyRulesEditor from "../Editor/EventApplyRulesEditor";
import SchemaEditor from "../Editor/SchemaEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import {WithWizardStepProps} from "../WizardModal";
import {cloneDeep} from "lodash";
import SchemaEditorDivider from "../Editor/Divider/SchemaEditorDivider";
import {stringify} from "comment-json";

interface OwnProps {

}

type DddEventProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext> & WithNamespaces;

const DddEvent = (props: DddEventProps) => {
  const event = props.ctx.events.first(null);
  const version = event ? event.getVersion() : 0;

  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedEvent, setSelectedEvent] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<EventMetadata>({});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);
  const [eventSchema, setEventSchema] = useState(new Schema({}));
  const [stateSchema, setStateSchema] = useState(new Schema({}));

  const updateMetadata = (meta: EventMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(stringify(meta, null, 2), true);
    }

    if(meta.schema && stringify(meta.schema) !== stringify(eventSchema)) {
      setEventSchema(meta.schema);
    }

    setMetadata(meta);
  }

  useEffect(() => {
    resetForm();

    if(event) {
      setSelectedEvent(event);
      const meta = getEventMetadata(event);

      let updateMeta = false;

      if(!meta.schema || meta.schema.isEmpty()) {
        meta.schema = suggestEventSchema(props.ctx);
        updateMeta = true;
      }

      if(isEntityEvent(event) && !meta.applyRules) {
        meta.applyRules = [defaultApplyRule()];
        updateMeta = true;
      }

      if(updateMeta) {
        updateMetadata(meta);
      } else {
        setMetadata(meta);
        setEventSchema(meta.schema);
      }
    }

  }, [event, version])

  useEffect(() => {
    setStateSchema(getStateSchema(props.ctx));
  }, [props.ctx.state]);

  const resetForm = () => {
    setSelectedEvent(null);
    setMetadata({});
    setAutoFocusSchema(false);
    setEventSchema(new Schema({}));
    setStateSchema(new Schema({}));
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }


  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.schema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, schema: newSchema});
  }

  const handleSuggestSchema = () => {
    if(props.ctx.command) {
      const cmdMeta = getCommandMetadata(props.ctx.command);

      if(cmdMeta.schema && !cmdMeta.schema.isEmpty()) {
        handleSchemaChanged(cmdMeta.schema);
        return;
      }
    }

    if(props.ctx.state) {
      const stateMeta = getVoMetadata(props.ctx.state);

      if(stateMeta.schema && !stateMeta.schema.isEmpty()) {
        handleSchemaChanged(stateMeta.schema);
        return;
      }
    }

    const descSchema = event? getSchemaFromNodeDescription(event) : new Schema({});

    if(!descSchema.isEmpty()) {
      handleSchemaChanged(descSchema);
      return;
    }

    handleSchemaChanged({} as Schema);
  }

  const handleEventApplyRulesChanged = (changedRules: ApplyEventRule[] | undefined) => {
    if(!changedRules) {
      changedRules = [];
    }

    updateMetadata({...metadata, applyRules: changedRules});
  }

  const showApplyRules = event? isEntityEvent(event) : false;

  return <form className='ui form' onSubmit={handleSubmit}>
    <SchemaEditorDivider content={<>
      <SchemaEditor boardId={props.ctx.board.uid}
                    sessionId={selectedEvent ? selectedEvent.getId() : undefined }
                    schema={eventSchema}
                    readonly={!!(selectedEvent && !selectedEvent.isEnabled())}
                    onSchemaChanged={handleSchemaChanged} autofocus={autoFocusSchema}
      />
      <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestSchema} />
    </>} />


    {showApplyRules && <EditorDivider label="Information Update" content={<>
      <EventApplyRulesEditor elementId={selectedEvent ? selectedEvent.getId() : ''}
                             sessionId={selectedEvent ? selectedEvent.getId() : undefined}
                             ctx={props.ctx}
                             applyRules={metadata.applyRules || [defaultApplyRule()]}
                             readonly={!!(selectedEvent && !selectedEvent.isEnabled())}
                             eventSchema={eventSchema}
                             informationSchema={stateSchema}
                             onRulesChanged={handleEventApplyRulesChanged}
      />
    </>} />}
  </form>
};

export default withNamespaces()(DddEvent);

export const suggestEventSchema = (ctx: ImmutableDDDWizardContext): Schema => {
  if(ctx.command) {
    const cmdMetadata = getCommandMetadata(ctx.command);

    if(cmdMetadata.schema) {
      return cmdMetadata.schema;
    }
  }

  if(ctx.state) {
    const stateMetadata = getVoMetadata(ctx.state);

    if(stateMetadata.schema) {
      return stateMetadata.schema;
    }
  }

  return new Schema({});
}

export const suggestAggregateEventName = (ctx: ImmutableDDDWizardContext, graph: Graph): string => {
  // @TODO: suggest from command name or state/aggregate name + Added/Updated
  if(ctx.state) {
    return ctx.state.getTechnicalName() + ' Changed';
  }

  if(ctx.aggregate) {
    return ctx.aggregate.getTechnicalName() + ' Changed';
  }

  if(ctx.command) {
    const words = tagSentence(names(ctx.command.getTechnicalName()).title);
    const rest = words.filter(w => !isVerb(w)).map(w => w.token);
    const verbs = words.filter(w => isVerb(w)).map(w => toPastTense(w));

    return rest.join(" ") + " " + verbs.join(" ");
  }

  return '';
}

export const defaultApplyRule = (): ApplyEventRule => {
  return {
    rule: "always",
    then: {
      assign: {
        variable: "information",
        value: {
          $merge: ["information", "event"]
        }
      }
    }
  }
}

const getStateSchema = (ctx: ImmutableDDDWizardContext): Schema => {
  if(!ctx.state) {
    return new Schema({});
  }

  const meta = getVoMetadata(ctx.state);

  return meta.schema || new Schema({});
}
