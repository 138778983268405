import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import EditorDivider from "../EditorDivider";

interface OwnProps {
  content: React.ReactNode;
  type: "command" | "state" | "table";
}

type UiSchemaEditorDividerProps = OwnProps & WithNamespaces;

const UiSchemaEditorDivider = (props: UiSchemaEditorDividerProps) => {
  return <EditorDivider label="UI Schema" content={props.content} popupContent={<>
    <p>{props.t('insp.cody_wizard.editor.uiSchema.help1' + props.type)}</p>
    <p>{props.t('insp.cody_wizard.editor.autocomplete')}</p>
    <p><a href="https://wiki.prooph-board.com/board_workspace/UI-Schema.html" target="_blank"
          rel="nofollow" onClick={e => e.stopPropagation()}>{props.t('app.learnMore')}</a></p>
  </>}/>
};

export default withNamespaces()(UiSchemaEditorDivider);
