import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const JEXL_WIKI_MARKDOWN = "[Jexl Expression](https://wiki.prooph-board.com/board_workspace/Expressions.html)";
export const makeJexlExpression = (markdownDescription?: string, suggestions?: string[]): JSONSchemaWithMarkdown => {
  const jexlExpr = {
    type: "string",
    default: "$> ",
    pattern: "^\\$> .+$",
    markdownDescription,
  };

  if(suggestions) {
    return {
      anyOf: [
        {
          enum: suggestions.map(s => `$> ${s}`),
        },
        jexlExpr,
      ]
    } as JSONSchemaWithMarkdown
  }

  return {
    type: "string",
    default: "$> ",
    pattern: "^\\$> .+$",
    markdownDescription,
  }
}
