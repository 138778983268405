import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import EditorDivider from "../EditorDivider";

interface OwnProps {
  content: React.ReactNode;
}

type SchemaEditorDividerProps = OwnProps & WithNamespaces;

const SchemaEditorDivider = (props: SchemaEditorDividerProps) => {
  return <EditorDivider label="Schema" content={props.content} popupContent={<>
    <p>{props.t('insp.cody_wizard.editor.schema.help1')}</p>
    <p>{props.t('insp.cody_wizard.editor.schema.help2')}</p>
    <p>{props.t('insp.cody_wizard.editor.autocomplete')}</p>
    <p>{props.t('insp.cody_wizard.editor.schema.help3')}</p>
    <p><a href="https://wiki.prooph-board.com/board_workspace/Schema.html"
          onClick={e => e.stopPropagation()}
          target="_blank"
          rel="nofollow">{props.t('app.learnMore')}</a></p>
  </>} />
};

export default withNamespaces()(SchemaEditorDivider);
