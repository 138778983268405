import {JSONSchema7} from "json-schema";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";
import {Schema} from "../../../../service/cody-wizard/schema/schema";
import {makeDefinitions} from "./rule-engine-definitions";
import {withSuggestionPropMapping} from "./rule-engine/prop-mapping";
import {makeAssignVariable} from "./rule-engine/assign-variable";

export const makeInitializeRulesSchema = (dataSchema: Schema, ctx: WizardContext): string => {
  const schema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: withSuggestionPropMapping(
      makeDefinitions(
        makeAssignVariable('data')
      ),
      dataSchema.getObjectProperties(),
      ['data'],
      mappingSuggestions(dataSchema)
    )
  }

  return JSON.stringify(schema);
}

const mappingSuggestions = (dataSchema: Schema): string[] => {
  const suggestions: string[] = ['meta.user.userId'];

  dataSchema.getObjectProperties().forEach(prop => {
    suggestions.push(`data.${prop}`);
  })

  return suggestions;
}
