import {List} from "immutable";
import * as React from 'react';
import {useState} from "react";
import {Button, Header, Table} from "semantic-ui-react";
import {printIsoDateString} from "../../../core/dateTime";
import UserAvatar from "../../../User/components/UserAvatar";
import {IioUserInfo} from "../../../User/model/IioUserInfo";
import {PlayshotId, PlayshotSummary} from "../../model/PlayshotSummary";
import {loadPlayshot} from "../../service/playshot-storage";
import {stringify} from "comment-json";

interface OwnProps {
  playshot: PlayshotSummary,
  users: List<IioUserInfo>,
  onInitPlayshot: (playshotId: PlayshotId) => void,
  loading: boolean,
}

type PlayshotTableRowProps = OwnProps;

const PlayshotTableRow = (props: PlayshotTableRowProps) => {
  const user = props.users.find(u => u.userId === props.playshot.savedBy);
  const [exporting, setExporting] = useState(false);

  const handleExport = () => {
    setExporting(true);
    loadPlayshot(props.playshot.boardId, props.playshot.uid).then(playshot => {
      setExporting(false);

      const playshotData = playshot.toJS();

      delete playshotData.uid;
      delete playshotData.aggregateType;

      const json = stringify(playshotData, null, 2);


      const blob = new Blob([json], {type: 'application/json'});
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.download = props.playshot.name + '.json';
      a.href = url;
      a.click();
    })
  }

  return <Table.Row>
    <Table.Cell>
      <Header as="h4" image={true}>
        {user && <UserAvatar user={user} namePopup={true}/>}
        <Header.Content>
          {props.playshot.name}
          <Header.Subheader>{printIsoDateString(props.playshot.savedAt)}</Header.Subheader>
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell textAlign="right">
      <Button icon="play" loading={props.loading} primary={true} circular={true} onClick={() => props.onInitPlayshot(props.playshot.uid)} title="Start Playshot" />
    </Table.Cell>
    <Table.Cell textAlign="right" width={"1"}>
      <Button icon="download" loading={exporting} basic={true} circular={true} className="noborder" onClick={handleExport} title="Download Playshot" />
    </Table.Cell>
  </Table.Row>
};

export default PlayshotTableRow;
