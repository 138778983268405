import {Node} from "../../../model/Graph";
import {Rule, ThenAssignVariable} from "../rule-engine/configuration";
import {Schema} from "../schema/schema";

export interface ApplyEventRule extends Rule {
  then: ThenAssignVariable
}

export interface EventMetadata {
  public?: boolean;
  shorthand?: boolean;
  schema?: Schema;
  applyRules?: ApplyEventRule[];
}

export const getEventMetadata = (node: Node): EventMetadata => {
  const metadata = node.getParsedMetadata().toJS() as Record<string, any>;

  if(typeof metadata.public === "undefined") {
    metadata.public = false;
  }

  if(metadata.schema) {
    metadata.schema = new Schema(metadata.schema);
  }

  // @TODO: validate rules

  return metadata;
}
