import {
    CodyCallback,
    EmptyLayer,
    Graph,
    GraphPoint,
    MoveVideoToPositionListener,
    NextToPosition,
    Node, NodeDescription, NodeLabel, NodeMetadata, NodeTag,
    PanningListener, Size,
    ZoomListener
} from "../model/Graph";
import shortUUID from "short-uuid";
import {List} from "immutable";
import {CodyResponse} from "../service/Cody";
import {ActiveGraphElement} from "../reducers/applyActiveGraphElementActions";

type SelectionListener = (hasSelection: boolean) => void;

class GraphRef implements Graph {
    private graphRef: Graph | undefined;
    private selectionListeners: SelectionListener[] = [];

    public setGraph(graph: Graph | undefined): void {
        this.graphRef = graph;

        if(graph) {
            this.selectionListeners.forEach(listener => graph.onSelectionChanged(listener));
        }

        this.selectionListeners = [];
    }

    public createCellId(): string {
        if(this.graphRef) {
            return this.graphRef.createCellId();
        }

        return shortUUID().new();
    }

    public getCurrentScale(): number {
        if(this.graphRef) {
            return this.graphRef.getCurrentScale();
        }

        return 1.0;
    }

    public getCurrentTranslate(): GraphPoint {
        if(this.graphRef) {
            return this.graphRef.getCurrentTranslate();
        }

        return {x: 0, y: 0};
    }

    public onPanning(listener: PanningListener): void {
        if(this.graphRef) {
            this.graphRef.onPanning(listener);
        }
    }

    public offPanning(listener: PanningListener): void {
        if(this.graphRef) {
            this.graphRef.offPanning(listener);
        }
    }

    public translateMousePoint(x: number, y: number): GraphPoint {
        if(this.graphRef) {
            return this.graphRef.translateMousePoint(x, y);
        }

        return {x, y};
    }

    public onZoom(listener: ZoomListener): void {
        if(this.graphRef) {
            this.graphRef.onZoom(listener);
        }
    }

    public offZoom(listener: ZoomListener): void {
        if(this.graphRef) {
            this.graphRef.offZoom(listener);
        }
    }

    public getRoot(): Node {
        return this.graphRef? this.graphRef.getRoot() : new EmptyLayer();
    }

    public openLayersDialog(): void {
        if(this.graphRef) {
            this.graphRef.openLayersDialog();
        }
    }

    public triggerCody(): void {
        if(this.graphRef) {
            this.graphRef.triggerCody();
        }
    }

    public selectNode(node: Node): boolean {
        if(this.graphRef) {
            return this.graphRef.selectNode(node);
        }

        return false;
    }

    public hasUserLevelVisibilityChanges(): boolean {
        return this.graphRef? this.graphRef.hasUserLevelVisibilityChanges() : false;
    }

    public isVisible(node: Node): boolean {
        return this.graphRef? this.graphRef.isVisible(node) : false;
    }

    public setUserVisible(node: Node, visible: boolean): void {
        if(this.graphRef) {
            this.graphRef.setUserVisible(node, visible);
        }
    }

    public applyVisibilityGlobally(): void {
        if(this.graphRef) {
            this.graphRef.applyVisibilityGlobally();
        }
    }

    public isLocked(node: Node): boolean {
        return this.graphRef? this.graphRef.isLocked(node) : false;
    }

    public setLocked(node: Node, locked: boolean): void {
        if(this.graphRef) {
            this.graphRef.setLocked(node, locked);
        }
    }

    public deleteNode(node: Node): void {
        if(this.graphRef) {
            this.graphRef.deleteNode(node);
        }
    }

    public addLayer(name: string): void {
        if(this.graphRef) {
            this.graphRef.addLayer(name);
        }
    }

    public deleteLayer(node: Node): void {
        if(this.graphRef) {
            this.graphRef.deleteLayer(node);
        }
    }

    public isActiveLayer(node: Node): boolean {
        return this.graphRef? this.graphRef.isActiveLayer(node) : false;
    }

    public setAsActiveLayer(node: Node): void {
        if(this.graphRef) {
            return this.graphRef.setAsActiveLayer(node);
        }
    }

    public setDefaultLayerAsActive(): void {
        if(this.graphRef) {
            this.graphRef.setDefaultLayerAsActive();
        }
    }

    public highlightLayer(node: Node): void {
        if(this.graphRef) {
            this.graphRef.highlightLayer(node);
        }
    }

    public addNode(node: Node): void {
        if(this.graphRef) {
            this.graphRef.addNode(node);
        }
    }

    public getNode(nodeId: string): Node | null {
        if(this.graphRef) {
            return this.graphRef.getNode(nodeId);
        }
        return null;
    }

    public addNodeNextToAnother(newNode: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        if(this.graphRef) {
            this.graphRef.addNodeNextToAnother(newNode, existingNode, position, margin, center);
        }
    }

    public moveNodeNextToAnother(node: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        if(this.graphRef) {
            this.graphRef.moveNodeNextToAnother(node, existingNode, position, margin, center);
        }
    }

    public changeNodeName(node: Node, newName: string): void {
        if(this.graphRef) {
            this.graphRef.changeNodeName(node, newName);
        }
    }

    public changeNodeDescription(node: Node, description: NodeDescription): void {
        if(this.graphRef) {
            this.graphRef.changeNodeDescription(node, description);
        }
    }

    public changeNodeLabel(node: Node, label: NodeLabel): void {
        if(this.graphRef) {
            this.graphRef.changeNodeLabel(node, label);
        }
    }

    public changeNodeMetadata(node: Node, metadata: NodeMetadata): void {
        if(this.graphRef) {
            this.graphRef.changeNodeMetadata(node, metadata);
        }
    }

    public changeNodeTags(node: Node, tags: List<NodeTag>): void {
        if(this.graphRef) {
            this.graphRef.changeNodeTags(node, tags);
        }
    }

    public resizeNode(node: Node, size: Size): void {
        if(this.graphRef) {
            this.graphRef.resizeNode(node, size);
        }
    }

    public moveNode(node: Node, toPosition: GraphPoint): void {
        if(this.graphRef) {
            this.graphRef.moveNode(node, toPosition);
        }
    }

    public switchParent(node: Node, parent: Node | null): void {
        if(this.graphRef) {
            this.graphRef.switchParent(node, parent);
        }
    }

    public connectNodes(source: Node, target: Node, connectionLabel?: string, edgePoints?: GraphPoint[], invertCurve?: boolean): void {
        if(this.graphRef) {
            this.graphRef.connectNodes(source, target, connectionLabel, edgePoints, invertCurve);
        }
    }

    public highlightNodes(nodes: Node[]): void {
        if(this.graphRef) {
            this.graphRef.highlightNodes(nodes);
        }
    }

    public copySelectionToActiveLayer(): boolean {
        if(this.graphRef) {
            return this.graphRef.copySelectionToActiveLayer();
        }

        return false;
    }

    public changeFeatureStatus(feature: Node, status: "important" | "planned" | "ready" | "deployed"): void {
        if(this.graphRef) {
            this.graphRef.changeFeatureStatus(feature, status);
        }
    }

    public getFeatureTaskLink(feature: Node): string | null {
        if(this.graphRef) {
            return this.graphRef.getFeatureTaskLink(feature);
        }

        return null;
    }

    public setFeatureTaskLink(feature: Node, link: string | null): void {
        if(this.graphRef) {
            this.graphRef.setFeatureTaskLink(feature, link);
        }
    }

    public exportXmlSnapshot(): string {
        if(this.graphRef) {
            return this.graphRef.exportXmlSnapshot();
        }

        return '';
    }

    public onSelectionChanged(listener: (hasSelection: boolean) => void): void {
        this.selectionListeners.push(listener);
        if(this.graphRef) {
            this.graphRef.onSelectionChanged(listener);
        }
    }

    public setCockpitBaseUrl(baseUrl: string): void {
        if(this.graphRef) {
            this.graphRef.setCockpitBaseUrl(baseUrl);
        }
    }

    public focus(): void {
        if(this.graphRef) {
            this.graphRef.focus();
        }
    }

    public clearMoveVideoAvatarToPositionListener(): void {
        if(this.graphRef) {
            this.graphRef.clearMoveVideoAvatarToPositionListener();
        }
    }

    public onMoveVideoAvatarToPosition(listener: MoveVideoToPositionListener): void {
        if(this.graphRef) {
            this.graphRef.onMoveVideoAvatarToPosition(listener);
        }
    }

    public setVideoAvatarEnabled(enabled: boolean): void {
        if(this.graphRef) {
            this.graphRef.setVideoAvatarEnabled(enabled);
        }
    }

    public trackVideoAvatarPosition(x: number, y: number): void {
        if(this.graphRef) {
            this.graphRef.trackVideoAvatarPosition(x,y);
        }
    }

    public setCodyDownCb(cb: CodyCallback | null): void {
        if(this.graphRef) {
            this.graphRef.setCodyDownCb(cb);
        }
    }

    public setCodyLeftCb(cb: CodyCallback | null): void {
        if(this.graphRef) {
            this.graphRef.setCodyLeftCb(cb);
        }
    }

    public setCodyRightCb(cb: CodyCallback | null): void {
        if(this.graphRef) {
            this.graphRef.setCodyRightCb(cb);
        }
    }

    public setCodyUpCb(cb: CodyCallback | null): void {
        if(this.graphRef) {
            this.graphRef.setCodyUpCb(cb);
        }
    }

    public openCodyWizard(runCody?: boolean): void {
        if(this.graphRef) {
            this.graphRef.openCodyWizard(runCody);
        }
    }

    public mergeIntoSchema(source: Node, target: Node): void {
        if(this.graphRef) {
            this.graphRef.mergeIntoSchema(source, target);
        }
    }

    public isEventModelingEnabled(): boolean {
        if(this.graphRef) {
            return this.graphRef.isEventModelingEnabled();
        }

        return true;
    }

    public makeDraggable(ele: HTMLElement, nodeToDrop: Node): void {
        if(this.graphRef) {
            return this.graphRef.makeDraggable(ele, nodeToDrop);
        }
    }

    public makeActiveGraphElementIfPossible(node: Node): ActiveGraphElement | null {
        if(this.graphRef) {
            return this.graphRef.makeActiveGraphElementIfPossible(node);
        }

        return null;
    }
}

let currentGraph: GraphRef|undefined;

export function useGraph(): [Graph, (graph: Graph | undefined) => void] {
    if(!currentGraph) {
        currentGraph = new GraphRef();
    }

    return [currentGraph, (graph: Graph | undefined) => { currentGraph!.setGraph(graph) }];
}
