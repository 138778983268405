import {JSONSchema7, JSONSchema7Definition} from "json-schema";
import {JSONSchemaWithMarkdown} from "../Suggestion/markdown-description";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./rule-engine/jexl-expressions";
import {makeRuleThenPropMapping} from "./rule-engine/prop-mapping";

export interface Definitions {[key: string]: JSONSchema7Definition}

export const makeDefinitions = (...rest: Array<Record<string, JSONSchema7>>): Definitions => {
  const schema: JSONSchemaWithMarkdown = {
    definitions: {
      rule_definition: {
        markdownDescription: `**Cody Engine Rule**\n\nCan be always executed or if a condition is (not) met \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html)`,
        oneOf: [
          {
            type: "object",
            default: {
              rule: "always",
              then: {}
            },
            properties: {
              rule: {
                type: "string",
                enum: ["always"],
                markdownDescription: `This rule is always executed.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#always-vs-condition)`,
              },
              then: {
                $ref: "#/definitions/rule_then"
              }
            },
            required: ["rule", "then"],
            additionalProperties: false
          },
          {
            type: "object",
            default: {
              rule: "condition",
              if: "$> ",
              then: {}
            },
            properties: {
              rule: {
                type: "string",
                enum: ["condition"],
                markdownDescription: `This rule is executed **if** condition is met.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#always-vs-condition)`,
              },
              then: {
                $ref: "#/definitions/rule_then"
              },
              if: makeJexlExpression(`Define condition as a ${JEXL_WIKI_MARKDOWN}`),
              else: {
                $ref: "#/definitions/rule_then",
                markdownDescription: `Alternative [rule](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#then), that is executed if condition is not met.`,
              },
              stop: {
                type: "boolean",
                markdownDescription: `Stop rule execution if condition is not met.`,
              }
            },
            required: ["rule", "then", "if"],
            additionalProperties: false
          },
          {
            type: "object",
            default: {
              rule: "condition",
              if_not: "$> ",
              then: {}
            },
            properties: {
              rule: {
                type: "string",
                enum: ["condition"],
                markdownDescription: `This rule is executed **if** condition is **not** met.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#always-vs-condition)`,
              },
              then: {
                $ref: "#/definitions/rule_then"
              },
              if_not: makeJexlExpression(`Define condition as a ${JEXL_WIKI_MARKDOWN}`),
              else: {
                $ref: "#/definitions/rule_then",
                markdownDescription: `Alternative [rule](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#then), that is executed if condition is met.`,
              },
              stop: {
                type: "boolean",
                markdownDescription: `Stop rule execution if condition is met.`,
              }
            },
            required: ["rule", "then", "if_not"],
            additionalProperties: false
          }
        ]
      },
      rule_then: {
        markdownDescription: `Execute a [rule](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#then)`,
        oneOf: []
      },
      ...makeRuleThenPropMapping()
    }
  }

  rest.forEach(rec => {
    // tslint:disable-next-line:forin
    for (const recKey in rec) {
      schema.definitions![recKey] = rec[recKey];
      (schema.definitions!.rule_then as JSONSchema7).oneOf!.push({$ref: `#/definitions/${recKey}`})
    }
  })

  return schema.definitions!;
}

