import {JSONSchema7} from "json-schema";
import {
  makeDefinitions
} from "./rule-engine-definitions";
import {makeAssignVariable} from "./rule-engine/assign-variable";
import {makeCallService} from "./rule-engine/call-service";
import {makeThenChangeInformation} from "./rule-engine/change-information-rules";
import {makeExecuteRules} from "./rule-engine/execute-rules";
import {makeFindFiltersDefinition, withResolveFilterTypes} from "./rule-engine/filters";
import {makeFindInformation, makePartialSelectDefinition} from "./rule-engine/find-information-rules";
import {makeForEach} from "./rule-engine/foreach";
import {makeLogMessage} from "./rule-engine/log-message";
import {makeThenThrowError} from "./rule-engine/throw-error";
import {makeThenTriggerCommand} from "./rule-engine/trigger-command";

export const makePolicyRulesSchema = (
  possibleCommands: string[],
  possibleExternalSystems: string[],
  possibleStateRefs: string[],
  possibleInformationRefs: string[],
  expSuggests: string[]
): string => {
  const PolicyRulesSchema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: {
      ...makeFindFiltersDefinition(undefined, expSuggests),
      ...makePartialSelectDefinition(possibleInformationRefs),
    }
  }

  PolicyRulesSchema.definitions = {
    ...PolicyRulesSchema.definitions,
    ...withResolveFilterTypes(makeDefinitions(
      makeThenTriggerCommand(possibleCommands),
      makeCallService(possibleExternalSystems),
      makeThenThrowError(),
      makeAssignVariable(),
      makeForEach(),
      makeExecuteRules(),
      makeThenChangeInformation(possibleStateRefs),
      makeLogMessage(),
      makeFindInformation(possibleInformationRefs, expSuggests)
    ))
  }

  return JSON.stringify(PolicyRulesSchema);
}
