import {JSONSchema7} from "json-schema";
import {
  makeDefinitions
} from "./rule-engine-definitions";
import {makeAssignVariable} from "./rule-engine/assign-variable";
import {makeCallService} from "./rule-engine/call-service";
import {makeExecuteRules} from "./rule-engine/execute-rules";
import {makeFindFiltersDefinition, withFindFilterTypes} from "./rule-engine/filters";
import {makeFindInformation, makePartialSelectDefinition} from "./rule-engine/find-information-rules";
import {makeForEach} from "./rule-engine/foreach";
import {makeLogMessage} from "./rule-engine/log-message";
import {makeThenRecordEvent} from "./rule-engine/record-event";
import {makeThenThrowError} from "./rule-engine/throw-error";

export const makeBusinessRulesSchema = (possibleEvents: string[], possibleServices: string[], possibleRefs: string[], expSuggests: string[]): string => {
  const BusinessRulesSchema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: {
      ...makeFindFiltersDefinition(undefined, expSuggests),
      ...makePartialSelectDefinition(possibleRefs),
    }
  }

  BusinessRulesSchema.definitions = {
    ...BusinessRulesSchema.definitions,
    ...makeDefinitions(
      makeThenRecordEvent(possibleEvents),
      makeThenThrowError(),
      makeAssignVariable(),
      makeCallService(possibleServices),
      makeForEach(),
      makeExecuteRules(),
      makeLogMessage(),
      makeFindInformation(possibleRefs, expSuggests)
    )
  }


  return JSON.stringify(BusinessRulesSchema);
}
