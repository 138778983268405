import {JSONSchema7} from "json-schema";
import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";
import {Definitions} from "../rule-engine-definitions";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";

export const makeThenChangeInformation = (possibleStateRefs: string[]): {
  rule_then_insert_information: JSONSchema7,
  rule_then_upsert_information: JSONSchema7,
  rule_then_update_information: JSONSchema7,
  rule_then_replace_information: JSONSchema7,
  rule_then_delete_information: JSONSchema7,
} => {
  return {
    rule_then_insert_information: {
      type: "object",
      additionalProperties: false,
      required: ["insert"],
      properties: {
        insert: {
          type: "object",
          additionalProperties: false,
          required: ["information", "id", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_upsert_information: {
      type: "object",
      additionalProperties: false,
      required: ["upsert"],
      properties: {
        upsert: {
          type: "object",
          additionalProperties: false,
          required: ["information", "id", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_update_information: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_replace_information: {
      type: "object",
      additionalProperties: false,
      required: ["replace"],
      properties: {
        replace: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_delete_information: {
      type: "object",
      additionalProperties: false,
      required: ["delete"],
      properties: {
        delete: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"}
          }
        }
      }
    }
  }
}
export const makeThenProjectionOperations = (): {
  rule_then_insert_projection: JSONSchemaWithMarkdown,
  rule_then_upsert_projection: JSONSchemaWithMarkdown,
  rule_then_update_projection: JSONSchemaWithMarkdown,
  rule_then_replace_projection: JSONSchemaWithMarkdown,
  rule_then_delete_projection: JSONSchemaWithMarkdown,
} => {
  return {
    rule_then_insert_projection: {
      type: "object",
      additionalProperties: false,
      required: ["insert"],
      properties: {
        insert: {
          type: "object",
          additionalProperties: false,
          required: ["id", "set"],
          markdownDescription: "Add new information to the collection.\n\n[Learn More](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#insert-information)",
          properties: {
            id: makeJexlExpression(`Set the id of new information.\n\nType: ${JEXL_WIKI_MARKDOWN}`),
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_upsert_projection: {
      type: "object",
      additionalProperties: false,
      required: ["upsert"],
      properties: {
        upsert: {
          type: "object",
          additionalProperties: false,
          required: ["id", "set"],
          properties: {
            id: makeJexlExpression(`Set the id of information.\n\nType: ${JEXL_WIKI_MARKDOWN}`),
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_update_projection: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["filter", "set"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"},
            loadDocIntoVariable: {type: "string"}
          }
        }
      }
    },
    rule_then_replace_projection: {
      type: "object",
      additionalProperties: false,
      required: ["replace"],
      properties: {
        replace: {
          type: "object",
          additionalProperties: false,
          required: ["filter", "set"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"},
            loadDocIntoVariable: {type: "string"}
          }
        }
      }
    },
    rule_then_delete_projection: {
      type: "object",
      additionalProperties: false,
      required: ["delete"],
      properties: {
        delete: {
          type: "object",
          additionalProperties: false,
          required: ["filter"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"}
          }
        }
      }
    }
  }
}

export const withProjectionOperations = (definitions: Definitions): Definitions => {
  return {
    ...definitions,
    ...makeThenProjectionOperations()
  }
}
