import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Divider, Icon, Popup} from "semantic-ui-react";
import {toggleSidebarDivider} from "../../../../Layout/actions/commands";
import {makeDividerCollapsedByNameSelector} from "../../../../Layout/selectors/sidebar-divider";

interface OwnProps {
  label: string;
  content: React.ReactNode;
  popupContent?: React.ReactNode;
}

type EditorDividerProps = OwnProps;

const EditorDivider = (props: EditorDividerProps) => {
  const selector = makeDividerCollapsedByNameSelector(props.label);
  const collapsed = useSelector(selector);
  const showContent = !collapsed;
  const dispatch = useDispatch();

  const style = showContent ? {cursor: 'pointer'} : {cursor: 'pointer', textDecoration: 'line-through'};

  const handleClick = () => dispatch(toggleSidebarDivider(props.label, !collapsed));

  return <>{
    props.popupContent
      ? <Divider horizontal={true} section={true} style={style} onClick={handleClick}>{props.label}&nbsp;&nbsp;<Popup
        trigger={<Icon name="question circle outline"/>} hoverable={true}>{props.popupContent}</Popup></Divider>
      : <Divider horizontal={true} content={props.label} section={true} style={style} onClick={handleClick}/>
  }
    {showContent && props.content}
  </>
};

export default EditorDivider;
