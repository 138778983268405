import {parse} from "comment-json";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {makeEditorStateByNameSelector} from "../../../../Layout/selectors/sidebar-editor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import ExpandButton from "./ExpandButton";
import {getPossibleRefs} from "./SchemaEditor";
import {makeShorthandSchemaValidationSchema} from "./Validation/shorthand-schema";
import {toggleSidebarEditor} from "../../../../Layout/actions/commands";


interface OwnProps {
  boardId: BoardId;
  schema?: Schema;
  onSchemaChanged: (schema: Schema) => void;
  autofocus?: boolean;
  onSchemaRemoved?: () => void;
  readonly?: boolean;
  sessionId?: string;
}

type QuerySchemaEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const QuerySchemaEditor = (props: QuerySchemaEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const editorState = useSelector(makeEditorStateByNameSelector('query-schema'));
  const dispatch = useDispatch();

  let value = props.schema ? props.schema.toString() : '';

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `${props.sessionId}-query-schema.json`,
      language: "json",
      value,
      schema: (props.schema && props.schema.isShorthand())? makeShorthandSchemaValidationSchema(getPossibleRefs(elementsTree)) : undefined,
    });

    setInvalidJson(isInvalidJson);
  }, [props.sessionId, value]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '' && props.onSchemaRemoved) {
      props.onSchemaRemoved();

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    }

    try {
      const changedSchema = parse(editorStr);
      props.onSchemaChanged(new Schema(changedSchema));

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] Query Schema Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.query_schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorState.expanded} double={editorState.double} onExpandChanged={(expand, double) => dispatch(toggleSidebarEditor('query-schema', expand, !!double))}/>
    <CodeEditor
      containerId={'cody-query-schema-editor'}
      ref={codeEditorRef}
      repairJson={true}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        mouseWheelZoom: true,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorState.expanded ? ' expanded' : '')  + (editorState.double ? ' double' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedSchemaStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedSchemaStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedSchemaStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(QuerySchemaEditor);
