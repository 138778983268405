import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";

export const makeAssignVariable = (fixedVariableName?: string): { rule_then_assign_variable: JSONSchemaWithMarkdown } => {
  const variableDesc = `Name of the variable`;

  return {
    rule_then_assign_variable: {
      type: "object",
      additionalProperties: false,
      required: ["assign"],
      properties: {
        assign: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Assign Variable**\n\nSet a new or override an existing variable in the rule execution context.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#assign-variable)`,
          required: ["variable", "value"],
          properties: {
            "variable": (fixedVariableName ? {const: fixedVariableName, markdownDescription: variableDesc} : {type: "string", minLength: 1, markdownDescription: variableDesc} as JSONSchemaWithMarkdown),
            "value": {$ref: "#/definitions/rule_then_prop_mapping"}
          }
        }
      }
    } as JSONSchemaWithMarkdown
  }
}
