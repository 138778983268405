import {JSONSchema7} from "json-schema";
import {ImmutableDDDWizardContext} from "../../../../service/cody-wizard/context/ddd-action";
import {ImmutableInformationContext} from "../../../../service/cody-wizard/context/information-context";
import {
  makeDefinitions
} from "./rule-engine-definitions";
import {makeThenThrowError} from "./rule-engine/throw-error";
import {makeAssignVariable} from "./rule-engine/assign-variable";
import {makeCallService} from "./rule-engine/call-service";
import {makeForEach} from "./rule-engine/foreach";
import {makeExecuteRules} from "./rule-engine/execute-rules";
import {makeLogMessage} from "./rule-engine/log-message";
import {withResolveFilterTypes} from "./rule-engine/filters";
import {withProjectionOperations} from "./rule-engine/change-information-rules";

export const makeProjectionSchema = (possibleEvents: string[], possibleServices: string[], ctx: ImmutableDDDWizardContext | ImmutableInformationContext): string => {
  const schema: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    required: ["cases"],
    properties: {
      name: {
        type: "string"
      },
      live: {
        type: "boolean",
        default: true
      },
      cases: {
        type: "array",
        items: {
          type: "object",
          additionalProperties: false,
          required: ["when", "then"],
          properties: {
            given: {
              type: "array",
              items: {
                $ref: "#/definitions/rule_definition"
              }
            },
            when: {
              type: "string",
              enum: possibleEvents
            },
            then: {
              oneOf: [
                {
                  $ref: "#/definitions/rule_then_insert_projection"
                },
                {
                  $ref: "#/definitions/rule_then_upsert_projection"
                },
                {
                  $ref: "#/definitions/rule_then_update_projection"
                },
                {
                  $ref: "#/definitions/rule_then_replace_projection"
                },
                {
                  $ref: "#/definitions/rule_then_delete_projection"
                }
              ]
            }
          }
        }
      }
    },
    definitions: withProjectionOperations(withResolveFilterTypes(makeDefinitions(
      makeThenThrowError(),
      makeAssignVariable(),
      makeCallService(possibleServices),
      makeForEach(),
      makeExecuteRules(),
      makeLogMessage(),
    )))
  }

  return JSON.stringify(schema);
}
