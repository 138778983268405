import {parse, stringify} from "comment-json";
import {JSONSchema7} from "json-schema";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {makeEditorStateByNameSelector} from "../../../../Layout/selectors/sidebar-editor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {UiMetadata} from "../../../service/cody-wizard/ui/get-ui-metadata";
import ExpandButton from "./ExpandButton";
import {JSONSchemaWithMarkdown, MarkdownDescription} from "./Suggestion/markdown-description";
import {getPossibleCommands, getPossibleViews} from "./Typeahead/elements-tree-lookup";
import {toggleSidebarEditor} from "../../../../Layout/actions/commands";

interface OwnProps {
  boardId: BoardId;
  uiConfig: UiMetadata;
  onUiConfigChanged: (config: UiMetadata) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
  ctx: WizardContext;
}

type PageContentEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const PageContentEditor = (props: PageContentEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const editorState = useSelector(makeEditorStateByNameSelector('page-content'));
  const dispatch = useDispatch();

  const {commands, views} = props.uiConfig;

  let value = stringify({commands, views}, null, 2);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `${props.sessionId}-ui-page-content.json`,
      language: "json",
      value,
      schema: makePageContentSchema(getPossibleCommands(elementsTree), getPossibleViews(elementsTree)),
    });

    setInvalidJson(isInvalidJson);
  }, [props.sessionId, value]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onUiConfigChanged({...props.uiConfig, commands: [], views: []});

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedConfig = parse(editorStr);
      props.onUiConfigChanged({...props.uiConfig, ...changedConfig});

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] UI Config Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorState.expanded} double={editorState.double} onExpandChanged={(expand, double) => dispatch(toggleSidebarEditor('page-content', expand, !!double))}/>
    <CodeEditor
      containerId={'cody-ui-page-content-editor'}
      ref={codeEditorRef}
      repairJson={true}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        mouseWheelZoom: true,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorState.expanded ? ' expanded' : '')  + (editorState.double ? ' double' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedUiConfigsStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedUiConfigsStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedUiConfigsStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(PageContentEditor);


const makePageContentSchema = (possibleCommands: string[], possibleViews: string[]): string => {
  const commandsSchema: JSONSchemaWithMarkdown = {type: "array", items: {type: "string", enum: possibleCommands}, markdownDescription: "List of Command Buttons.\n\nAvailable in the **Action Bar** of the page."};
  const viewsSchema: JSONSchemaWithMarkdown = {
    type: "array",
    markdownDescription: "List of View Components on the page.\n\nEach queryable Information becomes one component on the page. If you want to load the Information but not show it (e.g. to access it in a Jexl Expression), you can use:\n\n```{ \"view\": \"MyInformation\", \"hidden\": true }```",
    items: {
      oneOf: [
        {type: "string", enum: possibleViews},
        {
          type: "object",
          additionalProperties: false,
          required: ["view", "hidden"],
          properties: {
            view: {type: "string", enum: possibleViews},
            hidden: {type: "boolean", default: true}
          }
        }
      ]
    }
  };

  const schema: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    required: ["commands", "views"],
    properties: {
      commands: commandsSchema,
      views: viewsSchema
    }
  }

  return stringify(schema);
}
