import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { delay, put, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import configuredAxios from '../../api/ConfiguredAxios';
import verifyJwt from '../../api/ConfiguredJwtVerify';
import configuredSocket from '../../api/ConfiguredSocket';
import {distributeNewToken} from "../../api/util";
import { Action as NotifyAction } from '../../NotificationSystem';
import {Action as UserAction, UserModel, UserSelector} from '../../User';
import { AccountModel, RoleMapModel } from '../../User/model';
import * as Action from '../actions';

type AuthFlowAction = ActionType<typeof Action.Event.userAuthenticated>;

interface KcUserAttributes {
    attributes: {
        display_name: string[],
        avatar_url: string[],
        company_name: string[],
        job_title: string[]
    }
};

type KcProfile = KeycloakProfile & KcUserAttributes;

export const keycloakPromise = <TSuccess>(promise: Keycloak.KeycloakPromise<TSuccess, any>) => new Promise<TSuccess>((resolve, reject) =>
    promise
        .success((result: TSuccess) => resolve(result))
        .error((e: any) => reject(e)),
);

export function* tokenRefreshFlow() {

    const action: AuthFlowAction = yield take([
        Action.Type.USER_AUTHENTICATED,
    ]);

    let authErrors = 0;
    const kc: Keycloak.KeycloakInstance = action.payload.kc;

    try {
        yield verifyJwt(kc.token!);
    } catch (e) {
        yield keycloakPromise(kc.login());
    }

    const profile: KcProfile = yield keycloakPromise<KeycloakProfile>(kc.loadUserProfile());

    // Sync currentUser -> either default or quota request was faster and has set quota already
    let user = yield select(UserSelector.currentUserSelector, undefined);

    user = user.set('uid', kc.tokenParsed!.sub!);
    user = user.set('name', profile.username!);

    const attr = profile.attributes;

    if(attr.display_name) {
        user = user.set('displayName', attr.display_name[0]!);
    } else {
        user = user.set('displayName', profile.username!);
    }

    if(attr.avatar_url && attr.avatar_url[0] !== '') {
        user = user.set('avatarUrl', attr.avatar_url[0]!);
    } else {
        user = user.set('avatarUrl', user.generateDefaultAvatarUrl());
    }

    if(attr.company_name) {
        user = user.set('companyName', attr.company_name[0]);
    }

    if(attr.job_title) {
        user = user.set('jobTitle', attr.job_title[0]);
    }

    user = user.set('email', profile.email!);
    user = user.set('givenName', profile.firstName!);
    user = user.set('familyName', profile.lastName!);
    user = user.set('roles', RoleMapModel.fromToken(kc.resourceAccess!.inspectio.roles));
    user = user.set('account', AccountModel.fromUserToken(kc.resourceAccess!.inspectio.roles));
    user = user.set('signedIn', true);

    yield put(UserAction.Command.signIn(user));

    yield put(Action.Command.authenticate({token: kc.token}));

    while (true) {
        yield delay(10000);
        try {
            const refreshed = yield keycloakPromise<boolean>(kc.updateToken(31));

            if (refreshed === false) {
                continue;
            }

            try {
                yield verifyJwt(kc.token!);

                distributeNewToken(kc.token!);
            } catch (e) {
                yield keycloakPromise(kc.login());
            }

            yield put(Action.Command.authenticate({token: kc.token}));

            if (authErrors > 0) {
                yield put(NotifyAction.Command.info('Authorization established', 'You will be able to work ahead.'));
            }

            authErrors = 0;
        } catch (e) {
            if(authErrors === 0) {
                yield put(NotifyAction.Command.error('Authorization error', 'You will be logged out shortly if connection can not be restored.'));
            }
            authErrors++;

            if (authErrors > 2) {
                // TODO maybe not logged out
                yield keycloakPromise(kc.login());
            }
        }
    }
}
