import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";

export const makeFindInformation = (possibleInformation: string[], expSuggests: string[]) => {

  const information: JSONSchemaWithMarkdown = {
    type: "string",
    enum: possibleInformation,
    markdownDescription: `Data Reference of the information`,
  }

  return {
    then_find_information: {
      type: "object",
      additionalProperties: false,
      required: ["find"],
      properties: {
        find: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find Information**\n\nFind a list of stored information using a Filter. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-information)`,
          required: ["information", "filter"],
          properties: {
            information,
            filter: {$ref: "#/definitions/find_filter_types"},
            skip: {type: "integer", markdownDescription: `Select a subset of the result set skipping the given number of results`,},
            limit: {type: "integer", markdownDescription: `Select a subset of the result set limited by the given number`,},
            orderBy: {$ref: "#/definitions/find_order_by"},
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_find_one_information: {
      type: "object",
      additionalProperties: false,
      required: ["findOne"],
      properties: {
        findOne: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find One Information**\n\nFind one information by using a Filter. If filter matches more than one information, the first match is used. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-one-information)`,
          required: ["information", "filter"],
          properties: {
            information,
            filter: {$ref: "#/definitions/find_filter"},
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_find_information_by_id: {
      type: "object",
      additionalProperties: false,
      required: ["findById"],
      properties: {
        findById: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find Information By Id**\n\nFind one information by its id. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-information-by-id)`,
          required: ["information", "id"],
          properties: {
            information,
            id: makeJexlExpression(`Id filter defined as a ${JEXL_WIKI_MARKDOWN}`, expSuggests),
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_find_partial_information: {
      type: "object",
      additionalProperties: false,
      required: ["findPartial"],
      properties: {
        findPartial: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find Partial Information**\n\nFind a list of information by using a Filter and Select a subset of the information. Partial queries also support Lookup of additional information aka. joining related information. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-partial-information)`,
          required: ["information", "select", "filter"],
          properties: {
            information,
            filter: {$ref: "#/definitions/find_filter_types"},
            select: {$ref: "#/definitions/find_partial_select"},
            skip: {type: "integer", markdownDescription: `Select a subset of the result set skipping the given number of results`,},
            limit: {type: "integer", markdownDescription: `Select a subset of the result set limited by the given number`,},
            orderBy: {$ref: "#/definitions/find_order_by"},
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_find_one_partial_information: {
      type: "object",
      additionalProperties: false,
      required: ["findOnePartial"],
      properties: {
        findOnePartial: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find One Partial Information**\n\nFind one information by using a Filter and Select a subset of the information. Partial queries also support Lookup of additional information aka. joining related information. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-one-partial-information)`,
          required: ["information","select", "filter"],
          properties: {
            information,
            select: {$ref: "#/definitions/find_partial_select"},
            filter: {$ref: "#/definitions/find_filter"},
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_find_partial_information_by_id: {
      type: "object",
      additionalProperties: false,
      required: ["findPartialById"],
      properties: {
        findPartialById: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Find Partial Information By Id**\n\nFind one information by id and Select a subset of the information. Partial queries also support Lookup of additional information aka. joining related information. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#find-partial-information-by-id)`,
          required: ["information", "select", "id"],
          properties: {
            information,
            select: {$ref: "#/definitions/find_partial_select"},
            id: makeJexlExpression(`Id filter defined as a ${JEXL_WIKI_MARKDOWN}`, expSuggests),
            variable: {type: "string", minLength: 1, markdownDescription: `Defines the context name where the result is stored.`, default: "information"}
          }
        }
      }
    } as JSONSchemaWithMarkdown,
    then_lookup_user: {
      type: "object",
      additionalProperties: false,
      required: ["lookup"],
      properties: {
        lookup: {
          oneOf: [
            {
              type: "object",
              additionalProperties: false,
              required: ["user"],
              properties: {
                user: {

                }
              }
            },
            {
              type: "object",
              additionalProperties: false,
              required: ["users"],
              properties: {
                users: {

                }
              }
            }
          ]
        }
      }
    } as JSONSchemaWithMarkdown,
  }
}

export const makePartialSelectDefinition = (possibleInformation: string[]) => {
  const information: JSONSchemaWithMarkdown = {
    type: "string",
    enum: possibleInformation,
    markdownDescription: `Data Reference of the information to be looked up.`,
  }

  return {
    find_partial_select: {
      type: "array",
      markdownDescription: `**Partial Select**\n\nDefine the list of properties to be included in the result. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#partial-select)`,
      items: {
        oneOf: [
          {
            type: "string",
            minLength: 1,
          },
          {
            type: "object",
            additionalProperties: false,
            required: ["field", "alias"],
            properties: {
              field: {type: "string", minLength: 1, markdownDescription: `Name of the property in the database.\n\nCan be nested.`,},
              alias: {type: "string", minLength: 1, markdownDescription: `Name of the property in the result set.\n\nCan be nested.`,}
            }
          },
          {
            $ref: "#/definitions/find_partial_select_lookup"
          }
        ]
      }
    } as JSONSchemaWithMarkdown,
    find_partial_select_lookup: {
      type: "object",
      additionalProperties: false,
      markdownDescription: `**Lookup**\n\nLookup additional information and include it in the main Select. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#lookup)`,
      required: ["lookup", "on"],
      properties: {
        lookup: information,
        alias: {type: "string", minLength: 1, markdownDescription: `Lookup name, that can be referenced in further lookups`,},
        using: {type: "string", minLength: 1, markdownDescription: `Defines the lookup to be used as the local basis.`,},
        optional: {type: "boolean", default: false, markdownDescription: `Defines if the lookup is optional.`,},
        on: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `Defines the matching condition for the lookup.`,
          required: ["localKey"],
          properties: {
            localKey: {type: "string", minLength: 1, markdownDescription: `Local property used to lookup foreign information`,},
            foreignKey: {type: "string", minLength: 1, markdownDescription: `Compare local key against this property in the foreign information.\n\n*If not set, localKey is matched against the document id of foreign information.*`,},
            and: {$ref: "#/definitions/find_filter_types"}
          }
        },
        select: {
          markdownDescription: `Defines which properties of the looked up information should be included in the result`,
          oneOf: [
            {
              type: "string",
              minLength: 1,
            },
            {
              type: "object",
              additionalProperties: false,
              required: ["field", "alias"],
              properties: {
                field: {type: "string", minLength: 1, markdownDescription: `Name of the property in the database.\n\nCan be nested.`,},
                alias: {type: "string", minLength: 1, markdownDescription: `Name of the property in the result set.\n\nCan be nested.`,}
              }
            }
          ]
        }
      }
    } as JSONSchemaWithMarkdown,
  }
}
