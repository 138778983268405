import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {useSelector} from "react-redux";
import {Route, RouteComponentProps, withRouter} from 'react-router';
import {NavLink,} from 'react-router-dom';
import {Breadcrumb, Button, Dropdown, Icon, Image, Label, Menu, MenuItem} from 'semantic-ui-react';
import {logout} from "../index";
import BoardTopMenu from "../InspectioBoards/components/BoardTopMenu";
import ShareBoardButton from "../InspectioBoards/components/ShareBoardButton";
import * as Routes from '../routes';
import TopmenuAvatar from "../User/components/TopmenuAvatar";
import BoardCrumb from "./Breadcrumb/BoardCrumb";
import BoardOverviewCrumb from "./Breadcrumb/BoardOverviewCrumb";
import OrganizationOverviewCrumb from "./Breadcrumb/OrganizationOverviewCrumb";
import TeamOverviewCrumb from "./Breadcrumb/TeamOverviewCrumb";
import UpdatesCrumb from "./Breadcrumb/UpdatesCrumb";
import UserProfileCrumb from "./Breadcrumb/UserProfileCrumb";
import {makeGetSidebarSelector} from "./selectors/sidebar";

type TopMenuProps =  WithNamespaces & RouteComponentProps<{uid?: string}>;

const TopMenu = (props: TopMenuProps) => {
    const sidebar = useSelector(makeGetSidebarSelector());
    const isBoardWorkspace = Routes.isInspectioBoardWorkspace(props.location.pathname);

    const paddingLeft = isBoardWorkspace? (sidebar.width + 20) + 'px' : '270px';

    return (<Menu fixed="top" inverted={false} id="topmenu" style={{paddingLeft}} className="touch-only-pan">
        <Route path={Routes.inspectio}>
            <MenuItem>
                <Breadcrumb>
                    <Route exact={true} path={[Routes.inspectio, Routes.inspectioBoardWorkspace, Routes.inspectioEditBoard]} component={BoardOverviewCrumb} />
                    <Route exact={true} path={Routes.userProfile} component={UserProfileCrumb} />
                    <Route exact={true} path={Routes.Updates} component={UpdatesCrumb} />
                    <Route exact={true} path={[Routes.inspectioBoardWorkspace, Routes.inspectioEditBoard]} component={BoardCrumb} />
                    <Route exact={true} path={Routes.teamOverview} component={TeamOverviewCrumb} />
                    <Route exact={true} path={Routes.organizationOverview} component={OrganizationOverviewCrumb} />
                </Breadcrumb>
            </MenuItem>
        </Route>
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={ShareBoardButton} />
        <Menu.Menu position='right' id="topmenuUsers" />
        <TopmenuAvatar />
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={BoardTopMenu} />
    </Menu>);
};

export default withNamespaces()(withRouter(TopMenu));
