import {parse, stringify} from "comment-json";
import {JSONSchema7} from "json-schema";
import {eq, isEqual} from "lodash";
import {ElementsTree} from "../../../model/ElementsTree";
import {NodeType} from "../../../model/Graph";
import {isJsonSchema} from "../json-schema/is-json-schema";
import {isJsonSchemaArray} from "../json-schema/is-json-schema-array";
import {isJsonSchemaObject} from "../json-schema/is-json-schema-object";
import {isJsonSchemaPrimitive} from "../json-schema/is-json-schema-primitive";
import {isJsonSchemaRef} from "../json-schema/is-json-schema-ref";
import {isJsonSchemaString} from "../json-schema/is-json-schema-string";
import {getSchemaDefinitionId} from "../vo/get-schema-definition-id";
import {getSchemaRefName} from "../vo/get-schema-ref-name";
import {getVoMetadata} from "../vo/get-vo-metadata";
import {
  isList,
  isObject,
  isPrimitive,
  isRef,
  isShorthand,
  isString,
  Shorthand,
  ShorthandObject,
  splitPropertyRef
} from "./shorthand";


export class Schema {

  public static fromString(schemaStr: string): Schema {
    if (isShorthand(schemaStr)) {
      return new Schema(schemaStr);
    }

    try {
      const parsedSchema = parse(schemaStr);
      return new Schema(parsedSchema);
    } catch (e) {
      console.warn("[CodyWizard] Schema.fromString was not able to parse JSON string", schemaStr, e);
      return new Schema({});

    }
  }

  private shorthand: Shorthand | null = null;
  private jsonSchema: JSONSchema7 | null = null;

  constructor(schema: Shorthand | JSONSchema7) {
    if(isShorthand(schema)) {
      this.shorthand = schema;
      return;
    }

    if(isJsonSchema(schema)) {
      this.jsonSchema = schema;
      return;
    }

    console.warn("[CodyWizard] Schema received invalid schema. It's neither Shorthand nor JSONSchema7 and therefor ignored!", schema);
  }

  public toShorthand(): Shorthand {
    if(this.shorthand) {
      return this.shorthand;
    }

    console.warn("[CodyWizard] Schema conversion from JSON Schema to shorthand is not implemented yet", this.jsonSchema);

    return {};
  }

  public isShorthand(): boolean {
    return isShorthand(this.toShorthandIfPossible());
  }

  public isEmpty(): boolean {
    const s = this.toShorthandIfPossible();

    if(typeof s === "string") {
      return s === '';
    }

    return Object.keys(s).length === 0;
  }

  public toShorthandIfPossible(): Shorthand | JSONSchema7 {
    if(this.shorthand) {
      return this.shorthand;
    }

    if(this.jsonSchema) {
      console.warn("[CodyWizard] Schema conversion from JSON Schema to shorthand is not implemented yet", this.jsonSchema);
      return this.jsonSchema;
    }

    return {};
  }

  public isString(format?: string): boolean {
    if(this.shorthand) {
      return isString(this.shorthand, format);
    }

    if(this.jsonSchema) {
      return isJsonSchemaString(this.jsonSchema, format);
    }

    return false;
  }

  public isRef(): boolean {
    if(this.shorthand) {
      return isRef(this.shorthand);
    }

    if(this.jsonSchema) {
      return isJsonSchemaRef(this.jsonSchema);
    }

    return false;
  }

  public getRef<NSV>(notSetValue?: NSV): string | NSV;
  public getRef<NSV>(notSetValue: NSV): string | NSV {
    if(!this.isRef()) {
      return notSetValue;
    }

    if(this.shorthand) {
      return typeof this.shorthand === "string" ? this.shorthand : this.shorthand.$ref as string;
    }

    if(this.jsonSchema) {
      return this.jsonSchema.$ref as string;
    }

    return notSetValue;
  }

  public resolveRef(elementsTree: ElementsTree): Schema {
    const emptySchema = new Schema({});

    if(!this.isRef()) {
      return emptySchema;
    }

    let ref = emptySchema;

    if(this.isShorthand()) {
      elementsTree.getFilteredElementsByType(NodeType.document).forEach(doc => {
        const voMeta = getVoMetadata(doc);
        const [refWithoutProp, prop] = splitPropertyRef(this.getRef());
        if(getSchemaRefName(doc, voMeta) === refWithoutProp) {
          ref = voMeta.schema || emptySchema;
        }
      })

      return ref;
    }

    if(!this.jsonSchema) {
      elementsTree.getFilteredElementsByType(NodeType.document).forEach(doc => {
        const voMeta = getVoMetadata(doc);
        if(getSchemaDefinitionId(doc, voMeta) === this.getRef()) {
          ref = voMeta.schema || emptySchema;
        }
      })

      return ref;
    }

    return emptySchema;
  }

  public isPrimitive(): boolean {
    if(this.shorthand) {
      return isPrimitive(this.shorthand);
    }

    if(this.jsonSchema) {
      return isJsonSchemaPrimitive(this.jsonSchema);
    }

    return false;
  }

  public isList(): boolean {
    if(this.shorthand) {
      return isList(this.shorthand);
    }

    if(this.jsonSchema) {
      return isJsonSchemaArray(this.jsonSchema);
    }

    return false;
  }

  public getListItemsSchema<NSV>(notSetValue?: NSV): Schema | NSV;
  public getListItemsSchema<NSV>(notSetValue: NSV): Schema | NSV {
    if(this.shorthand && isList(this.shorthand)) {
      return new Schema((this.shorthand as ShorthandObject).$items);
    }

    if(this.jsonSchema && isJsonSchemaArray(this.jsonSchema)) {
      return new Schema(this.jsonSchema.items as JSONSchema7);
    }

    return notSetValue;
  }

  public isObject(): boolean {
    if(this.shorthand) {
      return isObject(this.shorthand);
    }

    if(this.jsonSchema) {
      return isJsonSchemaObject(this.jsonSchema);
    }

    return false;
  }

  public getObjectProperties(): string[] {
    if(!this.isObject()) {
      return [];
    }

    if(this.shorthand && isObject(this.shorthand)) {
      return Object.keys(this.shorthand).map(prop => prop.replace('?', ''));
    }

    if(this.jsonSchema && isJsonSchemaObject(this.jsonSchema)) {
      return Object.keys(this.jsonSchema.properties || {});
    }

    return [];
  }

  public getObjectPropertySchema<NSV>(propName: string, notSetValue?: NSV): Schema | NSV;
  public getObjectPropertySchema<NSV>(propName: string, notSetValue: NSV): Schema | NSV {
    if(!this.isObject()) {
      return notSetValue;
    }

    if(this.shorthand && isObject(this.shorthand) && this.shorthand[propName]) {
      return new Schema(this.shorthand[propName]);
    }

    if(this.shorthand && isObject(this.shorthand) && this.shorthand[propName + '?']) {
      return new Schema(this.shorthand[propName + '?']);
    }

    if(this.jsonSchema && isJsonSchemaObject(this.jsonSchema)) {
      const props = this.jsonSchema.properties || {};

      if(props[propName]) {
        return new Schema(props[propName] as JSONSchema7);
      }
    }

    return notSetValue;
  }

  public setObjectProperty(propName: string, propSchema: Schema, required = true): void {
    if(!this.isObject()) {
      return;
    }

    if(this.shorthand) {
      if(!required) {
        propName += '?';
      }

      // @ts-ignore
      this.shorthand[propName] = propSchema.toShorthandIfPossible();
    }

    if(this.jsonSchema) {
      // @ts-ignore
      this.jsonSchema.properties[propName] = propSchema.toJsonSchema();

      if(!required) {
        return;
      }

      if(this.jsonSchema.required) {
        this.jsonSchema.required.push(propName);
      } else {
        this.jsonSchema.required = [propName];
      }
    }
  }

  public isRequired(propName: string): boolean {
    if(this.isEmpty() || !this.isObject()) {
      return false;
    }

    if(this.shorthand) {
      if(isObject(this.shorthand) && this.shorthand[propName]) {
        return true;
      }

      // propName + ? || propName not set
      return false;
    }

    if(this.jsonSchema) {
      if(isJsonSchemaObject(this.jsonSchema) && this.jsonSchema.required && this.jsonSchema.required.includes(propName)) {
        return true;
      }
    }

    return false;
  }

  public toJsonSchema(): JSONSchema7 {
    if(this.jsonSchema) {
      return this.jsonSchema;
    }

    console.warn("[CodyWizard] Schema conversion from shorthand to JSONSchema is not implemented yet", this.shorthand);

    return {};
  }

  public toString(): string {
    return stringify(this.toShorthandIfPossible(), null, 2);
  }

  public toJSON(): Shorthand | JSONSchema7 {
    return this.toShorthandIfPossible();
  }

  public equals(otherSchema: Schema): boolean {
    return isEqual(this.toJSON(), otherSchema.toJSON());
  }
}
