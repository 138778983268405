import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import { pure } from 'recompose';
import {Dropdown, Icon, Label, MenuItem, Popup} from "semantic-ui-react";
import * as Routes from "../../routes";
import {
    toggleBoardHistory,
    toggleCodyConsole,
    toggleElementDetails, togglePlayshotModal, toggleTimerModal, toggleVideoSession,
    triggerExportBoard,
    triggerImportBoard
} from "../actions/commands";
import {BoardId} from "../model/Board";
import {makeBoardByIdSelector} from "../selectors/board";
import TopmenuLiteMode from "../../User/components/TopmenuLiteMode";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {makeUserSettingSelector, makeUserSettingsLoadedSelector} from "../../User/selectors/userSettings";
import {useEffect, useState} from "react";
import {upsertUserSetting} from "../../User/actions/commands";

export interface BoardTopMenuRouteMatchParams {
    uid: BoardId;
}

type BoardTopMenuProps =  WithNamespaces & RouteComponentProps<BoardTopMenuRouteMatchParams>;

const BoardTopMenu = pure((props: BoardTopMenuProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const board = useSelector(makeBoardByIdSelector(props.match.params.uid));
    const settingsLoaded = useSelector(makeUserSettingsLoadedSelector());
    const showHint = useSelector(makeUserSettingSelector('show_timer_hint', false));
    const [popupOpen, setPopupOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(settingsLoaded  && showHint) {
            setPopupOpen(true);
        }
    }, [settingsLoaded, showHint])

    const handleHintClick = () => {
        dispatch(upsertUserSetting('show_timer_hint', false));
        setPopupOpen(false);
    }

    const isAdmin = organization ? organization.isAdmin(currentUser.uid) || board && board.isCreator(currentUser.uid) : board && board.owner === currentUser.uid;

    return <>
        <TopmenuLiteMode />
        <Popup
            open={popupOpen}
            trigger={<MenuItem icon="hourglass half" onClick={() => dispatch(toggleTimerModal(props.match.params.uid, true))} />}
            openOnTriggerClick={false}
            openOnTriggerFocus={false}
            openOnTriggerMouseEnter={false}
            position="bottom left"
            content={
                <>
                    <p>{props.t('insp.board.timer.hint')}</p>
                    <p><span style={{"color": "#9cc8f5", "cursor": "pointer", float: "right"}} onClick={handleHintClick}>{props.t('insp.hint.got_it')}</span></p>
                </>
            }
        />
        <Dropdown item={true} icon="ellipsis vertical">
            <Dropdown.Menu direction="left">
                <Dropdown.Item icon="history" text="Activity" onClick={() => dispatch(toggleBoardHistory(props.match.params.uid, true))} />
                <Dropdown.Item
                    icon={'clipboard outline'}
                    text={props.t('insp.top_menu.element_details')}
                    onClick={() => dispatch(toggleElementDetails(props.match.params.uid, true, false))}
                />
                <Dropdown.Item icon="code" text="Cody" onClick={() => dispatch(toggleCodyConsole(props.match.params.uid, true)) } />
                <Dropdown.Item icon="play" text="Cody Play" onClick={() => dispatch(togglePlayshotModal(props.match.params.uid, true))} />
                {board && (board.organization? isAdmin : !board.shared) && <Dropdown.Item icon="cog" text="Settings" to={Routes.compileInspectioBoardEdit(props.match.params.uid)} as={NavLink}/>}
                <Dropdown.Item icon="upload" text="Export Board" onClick={() => dispatch(triggerExportBoard(props.match.params.uid))} />
                <Dropdown.Item icon="question" text="FAQs" as="a" href="https://wiki.prooph-board.com" target="_blank" />
            </Dropdown.Menu>
        </Dropdown>
    </>});

export default withNamespaces()(withRouter(BoardTopMenu));
