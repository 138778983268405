import {
  getAllNodesFromCtx,
  repositionFeature,
  suggestNewFeatureName
} from "../../../../components/CodyEngineWizard/Step/Feature";
import {suggestPolicyName} from "../../../../components/CodyEngineWizard/Step/Policy";
import {
  API_TO_MODULE_MARGIN,
  DEFAULT_CARD_HEIGHT,
  DEFAULT_CONNECT_MARGIN,
  SLICE_LANE_HEIGHT, SLICE_LANE_MARGIN
} from "../../../../components/CodyEngineWizard/WizardModal";
import {createTreeFromGraph} from "../../../../model/ElementsTree";
import {Graph, makeNodeFromJs, NextToPosition, Node, NodeType} from "../../../../model/Graph";
import {getPolicyMetadata} from "../../policy/get-policy-metadata";
import {ImmutablePolicyContext, policyContextFromNode} from "../policy-context";
import {stringify} from "comment-json";

export const suggestFeature = (ctx: ImmutablePolicyContext, graph: Graph): void => {
  const featureName = suggestNewFeatureName(ctx);

  let newFeature = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.feature,
    name: featureName,
  });

  let currentContainerParent: Node | null = null;
  const allNodes = getAllNodesFromCtx(ctx);
  allNodes.forEach(node => {
    const parent = node.getParent();
    if(parent && parent.getType() === NodeType.boundedContext) {
      currentContainerParent = parent;
    }
  })

  if(currentContainerParent) {
    // Ensure all nodes have the same parent
    allNodes.forEach(node => {
      const parent = node.getParent();

      if(parent && parent.getId() !== currentContainerParent!.getId()) {
        graph.switchParent(node, parent);
      }
    })

    newFeature = newFeature.changeParent(currentContainerParent);
  }

  newFeature = repositionFeature(newFeature, ctx, graph);

  graph.addNode(newFeature);

  getAllNodesFromCtx(ctx).forEach(child => graph.switchParent(child, newFeature));

  graph.selectNode(newFeature);
}

export const suggestPolicy = (ctx: ImmutablePolicyContext, graph: Graph): void => {
  const policyName = suggestPolicyName(ctx);

  const tree = createTreeFromGraph(graph);

  const similarElements = tree.getSimilarElements(makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.policy,
    name: policyName,
  }));

  const metadata = similarElements.count() > 0
    ? getPolicyMetadata(similarElements.first())
    : {
    rules: []
  }

  const newPolicy = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.policy,
    name: policyName,
    metadata: stringify(metadata, null, 2),
  });

  if(!ctx.event) {
    alert("[CodyWizard] Unable to create policy. An event card is missing to connect the policy card to.");
    return;
  }

  if(graph.isEventModelingEnabled()) {
    const eventToUIMargin = ctx.sliceApiLabel
      ? ctx.event.getGeometry().y - ctx.sliceApiLabel.getGeometry().y - DEFAULT_CARD_HEIGHT + SLICE_LANE_HEIGHT
      : API_TO_MODULE_MARGIN;

    graph.addNodeNextToAnother(newPolicy, ctx.event, NextToPosition.aboveRight, {x: DEFAULT_CONNECT_MARGIN, y: eventToUIMargin});
  } else {
    graph.addNodeNextToAnother(newPolicy, ctx.event, NextToPosition.right, DEFAULT_CONNECT_MARGIN);
  }
  graph.connectNodes(ctx.event, newPolicy, undefined, undefined, true);

  repositionFeatureIfNeeded(newPolicy, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newPolicy);
  }, 200);
}

export const suggestPolicyEvent = (ctx: ImmutablePolicyContext, graph: Graph): void => {
  const eventName = '';

  const newEvent = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.event,
    name: eventName,
  });

  if(!ctx.policy) {
    alert("[CodyWizard] Unable to create event. A policy card is missing to connect the event card to.");
    return;
  }

  if(graph.isEventModelingEnabled()) {
    const uiToApiMarign = ctx.sliceApiLabel
      ? ctx.sliceApiLabel.getGeometry().y - ctx.policy.getGeometry().y
      : SLICE_LANE_MARGIN;

    graph.addNodeNextToAnother(newEvent, ctx.policy, NextToPosition.belowLeft, {x: DEFAULT_CONNECT_MARGIN, y: uiToApiMarign + SLICE_LANE_MARGIN});
  } else {
    graph.addNodeNextToAnother(newEvent, ctx.policy, NextToPosition.left, DEFAULT_CONNECT_MARGIN);
  }
  graph.connectNodes(newEvent, ctx.policy, undefined, undefined, true);

  repositionFeatureIfNeeded(newEvent, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newEvent);
  }, 200);
}

const repositionFeatureIfNeeded = (newNode: Node, ctx: ImmutablePolicyContext, graph: Graph): void => {
  if(ctx.feature) {
    const repositionedFeature = repositionFeature(
      ctx.feature,
      policyContextFromNode(graph.getNode(newNode.getId())!, ctx.board),
      graph
    )

    graph.resizeNode(repositionedFeature, repositionedFeature.getSize());
  }
}
