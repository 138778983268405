import {Node} from "../../../model/Graph";
import {Rule} from "../rule-engine/configuration";
import {RulesDependenciesMetadata} from "../rule-engine/types";

export interface PolicyMetadata {
  rules?: Rule[];
  dependencies?: RulesDependenciesMetadata;
}

export const getPolicyMetadata = (policy: Node): PolicyMetadata => {
  const metadata = policy.getParsedMetadata().toJS() as Record<string, any>

  return metadata;
}
