import {JSONSchemaWithMarkdown} from "../Suggestion/markdown-description";
import {makeDefinitions} from "./rule-engine-definitions";
import {makeAssignVariable} from "./rule-engine/assign-variable";
import {makeExecuteRules} from "./rule-engine/execute-rules";
import {makeFindFiltersDefinition, makeResolveFiltersDefinition} from "./rule-engine/filters";
import {makeFindInformation, makePartialSelectDefinition} from "./rule-engine/find-information-rules";
import {makeForEach} from "./rule-engine/foreach";
import {makeLogMessage} from "./rule-engine/log-message";
import {makeRuleThenPropMapping} from "./rule-engine/prop-mapping";
import {makeThenThrowError} from "./rule-engine/throw-error";

export const makeResolveSchema = (possibleProperties: string[], expressionSuggests: string[], possibleRefs: string[]): string => {
  const resolve: JSONSchemaWithMarkdown = {
    type: "object",
    additionalProperties: false,
    properties: {
      rules: {
        type: "array",
        markdownDescription: `Execute rules **before** the main **where** query is performed or provide the query resolver result via **information** context variable. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
        items: {
          $ref: "#/definitions/rule_definition"
        }
      },
      where: {
        $ref: "#/definitions/resolve_where_rule_definition",
        markdownDescription: `Main query resolver rule. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
      },
      orderBy: {
        markdownDescription: `Order the main query result. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
        oneOf: [
          {$ref: "#/definitions/resolve_order_by"},
          {type: "array", items: {$ref: "#/definitions/resolve_order_by"}}
        ]
      }
    },
    definitions: {
      resolve_where_rule_definition: {
        oneOf: [
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["always"],
                markdownDescription: `This rule is always executed.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              },
              then: {
                $ref: "#/definitions/resolve_filter",
                markdownDescription: `Defines the resolve filter. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              }
            },
            required: ["rule", "then"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"],
                markdownDescription: `This rule is executed **if** condition is met.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              },
              then: {
                $ref: "#/definitions/resolve_filter",
                markdownDescription: `Defines the resolve filter. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              },
              if: {$ref: "#/definitions/resolve_filter_expr"},
              else: {
                $ref: "#/definitions/resolve_filter_else",
                markdownDescription: `Alternative resolve filter, that is used if condition is not met. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              }
            },
            required: ["rule", "then", "if"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"]
              },
              then: {
                $ref: "#/definitions/resolve_filter",
                markdownDescription: `Defines the resolve filter. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              },
              if_not: {
                $ref: "#/definitions/resolve_filter_expr",
                markdownDescription: `This rule is executed **if** condition is **not** met.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              },
              else: {
                $ref: "#/definitions/resolve_filter_else",
                markdownDescription: `Alternative resolve filter, that is used if condition is met. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
              }
            },
            required: ["rule", "then", "if_not"],
            additionalProperties: false
          }
        ]
      },
      ...makeRuleThenPropMapping(),
      ...makeResolveFiltersDefinition(possibleProperties, expressionSuggests),
      ...makeFindFiltersDefinition(undefined, expressionSuggests),
      ...makePartialSelectDefinition(possibleRefs),
      resolve_filter_else: {
        oneOf: [
          {$ref: "#/definitions/resolve_filter"},
          {
            type: "object",
            additionalProperties: false,
            required: ["execute"],
            properties: {
              execute: {
                type: "object",
                additionalProperties: false,
                required: ["rules"],
                markdownDescription: `Execute a rule instead of performing a resolve query.`,
                properties: {
                  rules: {
                    type: "array",
                    items: {$ref: "#/definitions/rule_definition"},
                    minItems: 1,
                    maxItems: 1,
                    markdownDescription: `You can only run one rule e.g. to throw an error or log a message.\n\nIf you need more flexibility use the top level **rules** definition instead. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#resolver-rules)`,
                  }
                }
              }
            }
          }
        ]
      },
    }
  }  as JSONSchemaWithMarkdown;

  resolve.definitions = {...resolve.definitions, ...makeDefinitions(
    makeThenThrowError(),
    makeAssignVariable(),
    makeForEach(),
    makeExecuteRules(),
    makeLogMessage(),
    makeFindInformation(possibleRefs, expressionSuggests)
  )}

  return JSON.stringify(resolve);
}
