import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";

export const makeLogMessage = (): { then_log_msg: JSONSchemaWithMarkdown } => {

  const msgDesc = `Provide log message via ${JEXL_WIKI_MARKDOWN}`;

  return {
    then_log_msg: {
      type: "object",
      additionalProperties: false,
      required: ["log"],
      properties: {
        log: {
          type: "object",
          additionalProperties: false,
          markdownDescription: `**Log Message**\n\nLog a message to console to provide debugging information. \n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#log-msg)`,
          properties: {
            msg: {
              oneOf: [
                makeJexlExpression(msgDesc),
                {
                  type: "array",
                  items: makeJexlExpression(msgDesc)
                }
              ]
            }
          }
        }
      }
    }
  }
}
