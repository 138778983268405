import {List} from "immutable";
import * as React from 'react';
import {WithNamespaces, withNamespaces} from "react-i18next";
import {Button, Divider, Icon, List as SemanticList, Popup} from "semantic-ui-react";
import * as Routes from "../../../routes";
import {BoardId} from "../../model/Board";
import {Node, NodeType} from "../../model/Graph";
import CellIcon from "../CellIcon";
import {Link} from "react-router-dom";
import {useState} from "react";
import {RANGE_ELEMENT_CONNECTIONS} from "../MxGraphBoard/clickRange";
import MaxLengthLabel from "../MaxLengthLabel";
import {useDispatch} from "react-redux";
import {setSideBySideCell} from "../../../Layout/actions/commands";

interface OwnProps {
    boardId: BoardId,
    sources: List<Node>,
    targets: List<Node>,
}

type ElementConnectionsProps = OwnProps & WithNamespaces;

const ElementConnections = (props: ElementConnectionsProps) => {
    const dispatch = useDispatch();
    const [clicks, setClicks] = useState(RANGE_ELEMENT_CONNECTIONS);
    const [showMore, setShowMore] = useState(false);
    const [showConnections, setShowConnections] = useState(true);

    const numOfSources = props.sources.count();
    const numOfTargets = props.targets.count();
    const total = numOfSources + numOfTargets;

    let visibleSources = props.sources.filter(s => !(s.getType() === NodeType.icon && s.getName() === ''));
    let visibleTargets = props.targets.filter(t => !(t.getType() === NodeType.icon && t.getName() === ''));

    if(total > 4 && !showMore) {
        if(numOfSources > 4 && numOfTargets < 2) {
            visibleSources = visibleSources.take(4 - numOfTargets);
        } else if(numOfTargets > 4 && numOfSources < 2) {
            visibleTargets = visibleTargets.take(4 - numOfSources);
        } else {
            visibleSources = visibleSources.take(2);
            visibleTargets = visibleTargets.take(2);
        }
    }

    const handleClick = () => {
        setShowMore(!showMore);
    }

    const handleConnectionClick = (e: React.MouseEvent, node: Node) => {
        if(e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();

            dispatch(setSideBySideCell(node.getId()));
            return;
        }

        setClicks(clicks + 1);
    }

    const links = visibleSources.map(source => {
        return <SemanticList.Item key={source.getId()}
                                  style={{borderRadius:'0', color:'#424242'}}
                                  title="Source"
                                  as={ Link }
                                  to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${source.getId()}&select=1&clicks=${clicks}`}}
                                  onClick={e => handleConnectionClick(e, source)}  >
            <SemanticList.Icon name="arrow left" />
            <SemanticList.Content><CellIcon cellType={source.getType()} /><MaxLengthLabel label={source.getName()} maxLength={30} /></SemanticList.Content>
        </SemanticList.Item>;
    }).toArray();

    visibleTargets.forEach(target => {
        links.push(<SemanticList.Item key={target.getId()}
                                      style={{borderRadius:'0', color:'#424242'}}
                                      title="Target"
                                      as={ Link }
                                      to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${target.getId()}&select=1&clicks=${clicks}`}}
                                      onClick={e => handleConnectionClick(e, target)}  >
            <SemanticList.Icon name="arrow right" />
            <SemanticList.Content><CellIcon cellType={target.getType()} /><MaxLengthLabel label={target.getName()} maxLength={30} /></SemanticList.Content>
        </SemanticList.Item>);
    })

    return <>
        <Divider horizontal={true} onClick={() => setShowConnections(!showConnections)}
                 style={showConnections ? {cursor: 'pointer'} : {textDecoration: 'line-through', cursor: 'pointer'}}
        >Connections&nbsp;&nbsp;<Popup trigger={<Icon name="question circle outline" />}
            >
                <p>{props.t('insp.cody_wizard.connections.help1')}</p>
                <p>{props.t('insp.cody_wizard.connections.help2')}</p>
                <p>{props.t('insp.cody_wizard.connections.help3')}</p>
                <p>{props.t('insp.cody_wizard.connections.help4')}</p>
                <p>{props.t('insp.cody_wizard.connections.help5')}</p>
            </Popup></Divider>
        {showConnections && <>
            <SemanticList>
                {links}
            </SemanticList>
            {total > 4 && <Button basic={true}
                                  compact={true}
                                  icon={'arrow alternate circle ' + (showMore ? 'up' : 'down')}
                                  className="noborder"
                                  content={showMore ? props.t('app.showLess') : props.t('app.showAll')}
                                  style={{textAlign: 'left', paddingLeft: '30px'}}
                                  onClick={handleClick}
            />  }
        </>}
    </>;
};

export default withNamespaces()(ElementConnections);
