import {Record} from "immutable";
import {ApplicationState} from "../../reducer";

export class EditorState extends Record({expanded: false, double: false}) {

}

const getEditorStateByName = (state: Record<ApplicationState>, editor: string): EditorState => state.getIn(['inspectioSidebar', 'sidebar', 'editors', editor]) || new EditorState();

export const makeEditorStateByNameSelector = (editor: string): (state: Record<ApplicationState>) => EditorState => (state: Record<ApplicationState>) => getEditorStateByName(state, editor);
