import {Node, NodeType} from "../../../model/Graph";
import {findParentByType} from "./find-parent-by-type";
import {names} from "./names";

export const detectService = (node: Node): string | null => {
  const meta = node.getParsedMetadata().toJS() as Record<string, any>;

  if(meta.service && typeof meta.service === 'string') {
    return names(meta.service).className;
  }

  const feature = findParentByType(node, NodeType.feature);

  if(feature) {
    const featureMeta = feature.getParsedMetadata().toJS() as Record<string, any>;

    if(featureMeta.service) {
      return names(featureMeta.service).className;
    }
  }

  const bc = findParentByType(node, NodeType.boundedContext);

  if(bc) {
    const bcMeta = bc.getParsedMetadata().toJS() as Record<string, any>;
    if(bcMeta.service) {
      return names(bcMeta.service).className;
    }
  }

  return null;
}
