import {JSONSchemaWithMarkdown} from "../../Suggestion/markdown-description";
import {Definitions} from "../rule-engine-definitions";
import {JEXL_WIKI_MARKDOWN, makeJexlExpression} from "./jexl-expressions";

export const withSuggestionPropMapping = (definitions: Definitions, possibleProps: string[], fullMergeSuggestions: string[], mappingSuggestions: string[]): Definitions => {
  return {
    ...definitions,
    ...makeRuleThenPropMapping(possibleProps, fullMergeSuggestions, mappingSuggestions),
  }
}
export const makeRuleThenPropMapping = (possibleProps?: string[], fullMergeSuggestions?: string[], mappingSuggestions?: string[]): {
  rule_then_prop_mapping: JSONSchemaWithMarkdown
} => {


  let suggestions: string[] | undefined = [...(fullMergeSuggestions || []), ...(mappingSuggestions || [])];

  if(suggestions.length === 0) {
    suggestions = undefined;
  }

  const mergeSchema: JSONSchemaWithMarkdown = {
    oneOf: [
      makeJexlExpression(`Provide data to be merged into source object.\n\nType: ${JEXL_WIKI_MARKDOWN}`, suggestions),
      {
        type: "array",
        markdownDescription: "List of data to be merged into source object.",
        items: makeJexlExpression(`Provide data to be merged.\n\nType: ${JEXL_WIKI_MARKDOWN}`, suggestions)
      }
    ]
  }

  return {
    rule_then_prop_mapping: {
      markdownDescription: `**Property Mapping**\n\nSet complex data structures using Jexl-flavoured JSON.\n\n[Learn more](https://wiki.prooph-board.com/board_workspace/Rule-Engine.html#property-mapping)`,
      oneOf: [
        makeJexlExpression(`Provide data to be merged into source object.\n\nType: ${JEXL_WIKI_MARKDOWN}`, suggestions),
        {
          type: "object",
          patternProperties: {
            "^\$merge$": mergeSchema,
            "^\$not$": {type: "string", markdownDescription: `Removes the property.`},
            "^[^\$]+$": {
              $ref: "#/definitions/rule_then_prop_mapping"
            }
          }
        },
        {
          type: "array",
          items: {
            $ref: "#/definitions/rule_then_prop_mapping"
          }
        }
      ]
    }
  }
}
