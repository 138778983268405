import {Node} from "../../../model/Graph";
import {Rule} from "../rule-engine/configuration";

export interface AggregateMetadata {
  rules?: Rule[]
}

export const getAggregateMetadata = (aggregate: Node): AggregateMetadata => {
  return aggregate.getParsedMetadata().toJS();
}
